import React, { FC, useContext } from 'react';
import { useQuery } from 'react-query';
import { npBackendApi } from '../../api/npBackend';

type LoginState = {
    isLoggedIn: boolean;
    isLoading: boolean;
    user?: CurrentUser;
};

const LoginContext = React.createContext<LoginState | null>(null);
export const LoginContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const { data, isLoading } = useQuery(['current_user'], getCurrentUser, {
        staleTime: 120000,
    });

    return (
        <LoginContext.Provider value={{ isLoggedIn: !!data, isLoading, user: data || undefined }}>
            {children}
        </LoginContext.Provider>
    );
};

export const useLoginState = (): LoginState => {
    let loginState = useContext(LoginContext);
    if (!loginState) {
        throw new Error('useLoginState needs to be used inside a LoginContext');
    }

    return loginState;
};

export type CurrentUser = {
    id: number;
    admin: boolean;
    email: string;
    fullName?: string;
    profilePicture?: string;
    country?: string;
    postcode?: string;
    preferredFit?: string;
    birthYear?: number;
    enableNotifications: boolean;
};

async function getCurrentUser(): Promise<CurrentUser | null> {
    try {
        let res = await npBackendApi.get<CurrentUser>(`/api/v1/users/me`);
        return res.data;
    } catch (err: any) {
        if (err?.response?.statusCode === 401) {
            return null;
        }
        // We don't want errors in the backend to have a impact on other pages, so don't throw here
        console.error(err);
        return null;
    }
}
