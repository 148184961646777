import { default as React } from 'react';
import { secondaryButton } from '../../styles';
import { useLanguage } from '../../../i18n';
import { useNPContext } from '../../../npContext';

const langTable: { [key: string]: string } = { en: 'English', nb: 'Norwegian' };

const LangSelect: React.FC<{
    className?: string;
}> = ({ className }) => {
    let language = useLanguage();
    let context = useNPContext();
    let setLanguage = context.userPreferences.setLanguage;

    let otherLanguage = language === 'nb' ? 'en' : 'nb';

    return (
        <button
            className={className}
            css={`
                ${secondaryButton};
            `}
            onClick={() => setLanguage(otherLanguage)}
        >
            {langTable[otherLanguage]}
        </button>
    );
};

export default LangSelect;
