// Temporary Popup / Splash for overshoot day. Delete when that is no longer relevant

import qs from 'qs';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import constants from '../constants';
import { useLanguage } from '../i18n';
import Portal from './Portal';
import RichTextSerializer from './richTextSerializer';
import { fontSize, linkReset, space, weight } from './styles';
import { useLockBodyScroll } from '../lib/hooks';

const Title = styled.h1`
    ${fontSize.xxlarge};
    ${weight.regular};
    /* Prevent mobile device to scale font size */
    padding-top: 20px;
    text-size-adjust: none;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    color: #000;
    font-family: 'FK-Screamer', Impact, sans-serif;
    text-transform: uppercase;
    letter-spacing: 1%;
    margin-bottom: 64px;
    margin-top: ${space(2)};
    @media (max-width: ${constants.breakpoints.navigation}) {
        font-size: 100px;
        line-height: 80px;
        letter-spacing: 1%;
    }

    @media (min-height: 600px) {
        margin-top: 0.67em;
    }
`;

const Button = styled.button`
    unset: all;
    border: none;
    ${fontSize.regular};
    ${weight.medium};
    display: block;
    background: #2b2929;
    border-radius: 5px;
    color: white;
    margin-top: ${space(2)};
    line-height: 48px;
    padding: 0 ${space(4)};
`;

const getDoShow = () => {
    try {
        return !localStorage.getItem('overshootDayPopupHasShown');
    } catch {
        return false;
    }
};

const saveHasBeenShown = () => {
    try {
        localStorage.setItem('overshootDayPopupHasShown', 'true');
    } catch {
        // do nothing
    }
};

type Data =
    | {
          _rawTextEn?: any;
          _rawTextNb?: any;
          title?:
              | {
                    nb?: string | null | undefined;
                    en?: string | null | undefined;
                }
              | null
              | undefined;
          buttonText?:
              | {
                    nb?: string | null | undefined;
                    en?: string | null | undefined;
                }
              | null
              | undefined;
          active?: boolean | null | undefined;
      }
    | null
    | undefined;

export const OvershootDayPopup = ({ data }: { data: Data }) => {
    const [doShow, setDoShow] = React.useState(false);
    const [isForced, setIsForced] = React.useState(false);
    useLockBodyScroll(doShow);

    // Because of SSR, we have to check this in a useEffect
    useEffect(() => {
        let query =
            typeof window !== 'undefined'
                ? qs.parse(location.search, { ignoreQueryPrefix: true })
                : {};

        if (query.alwaysShowOvershootDaySplash) {
            setDoShow(true);
            setIsForced(true);
            return;
        }

        if (data?.active && getDoShow()) {
            setDoShow(true);
        }
    }, []);

    const language = useLanguage();
    const body = language === 'en' ? data?._rawTextEn : data?._rawTextNb;

    if (!doShow) {
        return null;
    }

    return (
        <Portal>
            <div
                css={`
                    box-sizing: border-box;

                    *,
                    *:before,
                    *:after {
                        box-sizing: inherit;
                    }
                    position: fixed;
                    inset: 0;
                    background: #cccc52;
                    z-index: 99998;
                    overflow-y: auto;
                `}
            >
                <div
                    css={`
                        width: 100%;
                        max-width: 800px;
                        margin: 0 auto;
                        padding: 0 ${space(4)} ${space(4)};
                        text-align: center;
                    `}
                >
                    <Title>{data?.title?.[language]}</Title>
                    <div>
                        <RichTextSerializer
                            sizes="(min-width: 2625px) calc(2100px - 48px), (min-width: 1550px) calc(80vw - 48px), (min-width: 1240px) calc(1240px - 48px), calc(100vw - 48px)"
                            content={body}
                            productSelectionSizes="(min-width: 2625px) calc((2100px - 48px)/3), (min-width: 1550px) calc((80vw - 48px)/3), (min-width: 1240px) calc((1240px - 48px)/3), (min-width: 1051px) calc((100vw - 48px)/3), 330px"
                            displayOptions={{
                                maxWidth: '100%',
                                maxWidthProductSelection: '100%',
                                textMaxWidth: '100%',
                            }}
                        />
                    </div>
                    <div
                        css={`
                            display: flex;
                            justify-content: center;
                        `}
                    >
                        <Button
                            onClick={() => {
                                // Don't save if the splash is forced by a query param, or else it will
                                // not show on the correct day
                                if (!isForced) {
                                    saveHasBeenShown();
                                }
                                setDoShow(false);
                            }}
                        >
                            {data?.buttonText?.[language]}
                        </Button>
                    </div>
                </div>
            </div>
        </Portal>
    );
};
