import React, { FC } from 'react';

export const CloseIcon: FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            fill="none"
            viewBox="0 0 26 26"
        >
            <path
                fill="#000"
                fillRule="evenodd"
                d="M9.192 7.778L7.778 9.192l3.535 3.536-3.535 3.536 1.414 1.414 3.535-3.536 3.536 3.536 1.414-1.414-3.535-3.536 3.535-3.536-1.414-1.414-3.536 3.536-3.535-3.536z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
};
