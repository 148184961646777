type Translations = { [key: string]: { [key: string]: string } };

const translations: Translations = {
    close: { en: 'Close', nb: 'Lukk' },
    all: { en: 'All', nb: 'Alle' },
    bag: { en: 'Cart', nb: 'Handlekurv' },
    package: { en: 'Package', nb: 'Pakke' },
    readMore: { en: 'Read more', nb: 'Les mer' },
    in: { en: 'in', nb: 'i' },
    is: { en: 'is', nb: 'er' },
    of: { en: 'of', nb: 'av' },
    menu: {
        en: 'menu',
        nb: 'meny',
    },
    discountString: {
        en: 'Gift card/coupon',
        nb: 'Gavekort/tilbudskode',
    },
    discountRegistered: {
        en: 'Discount code is registered',
        nb: 'Rabattkoden er registrert',
    },
    discountError: {
        en: 'Invalid discount code',
        nb: 'Rabattkoden er ikke gyldig',
    },
    and: { en: 'and', nb: 'og' },
    men: { en: 'Man', nb: 'Herre' },
    women: { en: 'Woman', nb: 'Dame' },
    unisex: { en: 'Unisex', nb: 'Unisex' },
    not_applicable: { en: '', nb: '' },
    summary: { en: 'Summary', nb: 'Oppsummering' },
    products: { en: 'Products', nb: 'Produkter' },
    taxes: { en: 'Taxes', nb: 'Mva' },
    taxesDeducted: { en: 'Taxes deducted', nb: 'Mva trukket fra' },
    vat: { en: 'taxes', nb: 'mva' },
    includes: { en: 'includes', nb: 'inkludert' },
    excludes: { en: 'excludes', nb: 'ekskludert' },
    freeShipping: { en: 'On us!', nb: 'Den betaler vi :)' },
    total: { en: 'Total', nb: 'Totalt' },
    incMva: { nb: '(inkl. mva)', en: 'incl. taxes' },
    toCheckout: { en: 'To checkout', nb: 'Gå til kassen' },
    checkout: { en: 'Checkout', nb: 'Kasse' },
    shipping: { en: 'Shipping', nb: 'Frakt' },
    freeShippingCartBanner: {
        en: 'Free shipping on orders over 190 Euro/1900 NOK',
        nb: 'Gratis frakt ved kjøp over 1900 NOK',
    },
    information: { en: 'Information', nb: 'Informasjon' },
    discount: { en: 'Discount', nb: 'Rabatt' },
    email: { en: 'Email', nb: 'E-post' },
    enterEmail: { en: 'Enter email address', nb: 'Skriv inn e-postadresse' },
    phonenumber: { en: 'Phone number', nb: 'Telefonnummer' },
    enterPhoneNumber: {
        en: 'Enter phone number',
        nb: 'Skriv inn ditt telefonnummer',
    },
    firstname: { en: 'First name', nb: 'Fornavn' },
    enterFirstname: { en: 'Enter first name', nb: 'Skriv inn fornavn' },
    lastname: { en: 'Last name', nb: 'Etternavn' },
    enterLastname: { en: 'Enter last name', nb: 'Skriv inn etternavn' },
    adress: { en: 'Street address', nb: 'Gateadresse' },
    adressDescription: {
        en: 'Street address',
        nb: 'Gatenavn og husnummer',
    },
    zipcode: { en: 'Zip code', nb: 'Postnummer' },
    city: { en: 'City', nb: 'Poststed' },
    country: { en: 'Country', nb: 'Land' },
    state: { en: 'State', nb: 'Delstat' },
    chooseState: { en: 'Choose state', nb: 'Velg delstat' },
    continueToPayment: { en: 'Continue to payment', nb: 'Fortsett til betaling' },
    allDetails: { en: 'All details', nb: 'Alle detaljer' },
    details: { en: 'Details', nb: 'Detaljer' },
    notes: { en: 'Notes', nb: 'Opplysninger' },
    hideAllDetails: { en: 'Hide all details', nb: 'Lukk alle detaljer' },
    seeAllDetails: { en: 'See all details', nb: 'Se alle detaljer' },
    sizechart: { en: 'Size chart', nb: 'Størrelsesguide' },
    sustainability: { en: 'Production and sustainability', nb: 'Produksjon og miljø' },
    production: { en: 'Production', nb: 'Produksjon' },
    care: { en: 'Use and care', nb: 'Bruk og vedlikehold' },
    serviceAndReturns: { en: 'Service and returns', nb: 'Service og retur' },
    technical: { en: 'Technical info', nb: 'Teknisk info' },
    material: { en: 'Material', nb: 'Materiale' },
    pricing: { en: 'Pricing', nb: 'Prising' },
    pricingAndProduction: { en: 'Pricing and production', nb: 'Prising og produksjon' },
    environmentalFootprint: { en: 'Environment and footprint', nb: 'Miljø og fotavtrykk' },
    chooseFitAndColor: {
        en: 'Choose fit and color',
        nb: 'Velg passform og farge',
    },
    chooseFit: {
        en: 'Choose fit',
        nb: 'Velg passform',
    },
    chooseFitFirst: {
        en: 'Choose fit first',
        nb: 'Velg passform først',
    },
    chooseSize: {
        en: 'Choose size',
        nb: 'Velg størrelse',
    },
    chooseColor: {
        en: 'Choose color',
        nb: 'Velg farge',
    },
    chooseColorAndSize: {
        en: 'Choose size and color',
        nb: 'Velg farge og størrelse',
    },
    size: {
        en: 'size',
        nb: 'størrelse',
    },
    fit: {
        en: 'fit',
        nb: 'passform',
    },
    notSelected: {
        en: 'not selected',
        nb: 'ikke valgt',
    },
    reserveThisProduct: {
        en: 'Reserve this product',
        nb: 'Reserver dette produktet',
    },
    reserveThisProductShort: {
        en: 'Reserve',
        nb: 'Reserver',
    },
    joinDiscussion: {
        en: 'Give feedback',
        nb: 'Gi innspill',
    },
    addToBag: {
        en: 'Add to cart',
        nb: 'Legg i handlekurv',
    },
    addToBagShort: {
        en: 'Add to cart',
        nb: 'Legg til',
    },
    addedToBag: {
        en: 'Added to cart',
        nb: 'Lagt i handlekurven',
    },
    added: {
        en: 'Added',
        nb: 'Lagt til',
    },
    reserved: {
        en: 'Reserved',
        nb: 'Reservert',
    },
    daysLeft: {
        en: 'days left',
        nb: 'dager igjen',
    },
    dayLeft: {
        en: 'day left',
        nb: 'dag igjen',
    },
    ordered: {
        en: 'Ordered',
        nb: 'Kjøpt',
    },
    addAnotherOne: {
        en: 'I’d like one more of this item',
        nb: 'Jeg vil ha en til av den samme',
    },
    productInfoBox: {
        en: 'For every purchase on this website we donate money to tree planting projects in Africa. This way, we win back the CO2 emissions our production results in. Read more under Environment.',
        nb: 'For hvert kjøp på våre nettsider planter vi trær i Afrika. Da vinner vi tilbake CO2-en som slippes ut ved produksjon. Les mer under Miljø',
    },
    weNeedThisInformation: {
        en: 'We need this information to send you a confirmation email and other information about your order',
        nb: 'Vi trenger denne informasjonen for å sende deg bekreftelsesmail og informasjon om forsendelsen',
    },
    cookieMessage1: {
        en: 'We use',
        nb: 'Vi bruker',
    },
    cookieMessage2: {
        nb: 'og annen lignende teknologi for å gi deg en så god opplevelse som mulig. Vi håper det er OK!',
        en: "and other similar technologies to improve your experience. We hope that's OK!",
    },
    cookieMessageLinkText: {
        en: 'Read more',
        nb: 'Les mer',
    },
    cookieMessageLinkUrl: {
        en: '/en/content/terms-and-conditions',
        nb: '/nb/content/vilkar-og-kjopsbetingelser',
    },
    joinNewsLetterHeader: {
        en: 'Stay updated on all our crazy ideas',
        nb: 'Ikke gå glipp av våre bajasstreker',
    },
    joinNewsLetterSubtitle: {
        en: 'Sign up for our newsletter',
        nb: 'Meld deg på nyhetsbrevet vårt',
    },
    subscribe: {
        en: 'Sign up',
        nb: 'Meld på',
    },
    notifyMe: {
        en: 'Notify me',
        nb: 'Varsle meg',
    },
    newsletterSuccess: {
        en: "Thanks for signing up! We promise you won't regret it 😉",
        nb: 'Takk for at du meldte deg på! Du kommer ikke til å angre ;)',
    },
    lowStock: {
        en: 'Hey, it looks like we only have a couple of this item left',
        nb: 'Hei, det ser ut som vi bare har igjen et par av denne på lager',
    },
    soldOut: {
        en: 'This item is sold out, and we’re not sure when it will be back in stock.', // Follow this product for updates',
        nb: 'Dette produktet er utsolgt og vi vet ikke sikkert når produktet er tilbake på lager.', // Følg produktet for oppdateringer',
    },
    soldOutPreorder: {
        nb: 'Ikke på lager ennå! Forhåndsbestill i dag for å sikre deg et av de {} produktene som er igjen i neste leveranse. Forventet levering {}.',
        en: 'Temporarily sold out! Preorder today to be sure to get one of the {} products that are left in the next production batch. Expected delivery {}',
    },
    soldOutPreorderNoDate: {
        nb: 'Vi har ikke flere av denne på lager, men du kan forhåndsbestille den nå',
        en: 'This item is currently out of stock, but you can preorder it now',
    },
    productSignUp: {
        en: 'Notify me when this product is in stock',
        nb: 'Gi meg beskjed når produktet er på lager',
    },
    signUpCrossSell: {
        en: 'Notify me when this product is in stock',
        nb: 'Gi meg beskjed når produktet er på lager',
    },
    noMore: {
        en: 'Just',
        nb: 'Kun',
    },
    leftInStock: {
        en: 'left',
        nb: 'igjen',
    },
    signUpConfirmation: {
        en: 'Sign up below for a newsletter a little out of the ordinary  💌',
        nb: 'Meld deg på for et nyhetsbrev litt utenom det vanlige 💌',
    },
    joinNewsLetterConfirmation: {
        en: 'Join our newsletter',
        nb: 'Meld deg på nyhetsbrevet vårt',
    },
    yourOrder: {
        en: 'Your order number',
        nb: 'Ditt ordrenummer',
    },
    plantingTrees: {
        en: 'Thanks to this purchase, you have helped us and the Norwegian Developement fund plant even more trees 🌳 ',
        nb: 'Du har nå bidratt til at vi, sammen med Utviklingsfondet, kan plante enda flere trær 🌳',
    },
    shippingAddress: {
        en: 'Shipping address',
        nb: 'Leveringsadresse',
    },
    addShippingAddress: {
        en: 'Deliver to a different address',
        nb: 'Skal vi sende pakken til en annen adresse?',
    },
    iAgree: {
        en: 'I agree to the',
        nb: 'Jeg godtar',
    },
    termsAndConditions: {
        en: 'terms and conditions',
        nb: 'vilkår og kjøpsbetingelser',
    },
    agreeTermsFirst: {
        en: 'Agree to terms and conditions',
        nb: 'Godta vilkår og kjøpsbetingelser',
    },
    termsUrl: {
        en: '/en/content/terms-and-conditions',
        nb: '/nb/content/vilkar-og-kjopsbetingelser',
    },
    reviews: {
        en: 'Reviews',
        nb: 'Omtaler',
    },
    reviewsLowerCase: {
        en: 'reviews',
        nb: 'omtaler',
    },
    reviewsIntro: {
        nb: 'Dette produktet har fått i gjennomsnittet {} stjerner av {} personer. {} personer har skrevet en omtale',
        en: 'This product has received on average {} stars from {} customers. {} customers have written a review',
    },
    preorder: {
        en: 'Preorder',
        nb: 'Forhåndskjøp',
    },
    preorderThisItem: {
        en: 'Preorder this item',
        nb: 'Forhåndskjøp',
    },
    expectedDelivery: {
        en: 'Expected delivery',
        nb: 'Forventet levering',
    },
    immediateDelivery: {
        en: 'Shipped next business day',
        nb: 'Sendes neste arbeidsdag',
    },
    checkoutPartialAvailable: {
        en: 'Part of your order is not in stock (yet). See expected delivery date below.',
        nb: 'Deler av bestilingen er ikke på lager (enda). Se forventet leveringsdato nedenfor.',
    },
    product: {
        en: 'Product',
        nb: 'Produkt',
    },
    environment: {
        en: 'Environment',
        nb: 'Miljø',
    },
    confirmationThankyou: {
        en: 'Thank you for your purchase at Northern Playground. We will send you an email when your package is on its way. While you wait, get out and play!',
        nb: 'Takk for at du handler hos Northern Playground. Vi gir deg beskjed på epost så snart produktene dine er på vei. Mens du venter, kom deg ut og lek!',
    },
    confirmationPartnerTitle: {
        en: 'We have partnered with the Norwegian Development Fund to reduce our greenhouse gasses.',
        nb: 'Vi samarbeider med Utviklingsfondet for å redusere våre Co2-utslipp.',
    },
    confirmationPartnerBody: {
        en: 'The Norwegian Development Fund is a pioneer within focus on development and envrionment, and was therefore a natural choice for us when picking an organization to support. With every sale on our web shop, we donate money to their tree planting project in Africa. That means that every time you shop with us, you are also contributing to planting trees that can absorb Co2.',
        nb: 'Utviklingsfondet er en pioner innen satsning på utvikling og miljø, og var derfor en naturlig partner for oss da vi skulle velge en organisasjon å støtte. For hvert salg vi har via egen nettside, gir vi en pengesum til deres treplantningsprosjekt i Afrika. Det vil si at for hvert produkt du kjøper hos oss, bidrar du til å plante trær som kan ta opp Co2.',
    },
    confirmationPartnerTLDR: {
        en: 'In other words: A self-imposed environmental tax.',
        nb: 'Med andre ord: en selvpålagt miljøskatt.',
    },
    confirmationFooterButton: {
        nb: 'Les mer om hva vi gjør',
        en: 'Read more about what we do',
    },
    checkoutPreorderNote: {
        en: "This order contains products that aren't in stock (yet). See the expected delivery date below.",
        nb: 'Denne bestillingen inneholder varer som ikke er på lager (ennå). Se forventet leveringsdato nedenfor.',
    },
    checkoutCrowdfundingNote: {
        nb: 'Denne bestillingen inneholder varer som vi fortsatt vurderer om vi skal produsere. Se mer informasjon nedenfor.',
        en: 'This order contains products that we are still evaluating whether or not to make. See below for more information.',
    },
    checkoutPreorderCrowdfundingNote: {
        nb: 'Denne bestillingen inneholder varer som vi fortsatt vurderer om vi skal produsere. I tillegg inneholder bestillingen din også varer som ikke er på lager enda. Se mer informasjon nedenfor.',
        en: "This order contains products that we are still evaluating whether or not to make. This order also contains products that aren't in stock yet. See below for more information.",
    },
    checkoutCrowdfundingProductNote1: {
        en: 'You will receive an email ',
        nb: 'Du får en e-post ',
    },
    checkoutCrowdfundingProductNote2: {
        en: 'with the status.',
        nb: 'med ny status.',
    },
    sold: {
        en: 'sold',
        nb: 'solgt',
    },
    previous: {
        en: 'Previous',
        nb: 'Forrige',
    },
    process: {
        en: 'Process',
        nb: 'Prosess',
    },
    next: {
        en: 'Next',
        nb: 'Neste',
    },
    generalError: {
        nb: 'Oops, det skjedde en feil',
        en: 'Oops, something went wrong',
    },
    paymentError: {
        nb: 'Oops, det skjedde noe feil med betalingen',
        en: 'Oops, something went wrong during payment.',
    },
    'the payment was cancelled': {
        en: 'The payment was cancelled',
        nb: 'Betalingen ble kansellert',
    },
    'there was an error processing your payment': {
        en: 'There was an error processing your payment',
        nb: 'Det skjedde en feil under betalingen',
    },
    'the payment was refused': {
        en: 'The payment was refused. Please check that all details are correct.',
        nb: 'Betalingen gikk ikke igjennom. Sjekk om du har skrevet alle detaljene riktig.',
    },
    'This product needs': {
        en: 'This product needs ',
        nb: 'Dette produktet trenger ',
    },
    'more reservations': {
        en: 'more reservations',
        nb: 'reservasjoner til',
    },
    'You will get an update on': {
        en: "Charged only if production starts. We'll send an update",
        nb: 'Du belastes bare hvis den produseres. Oppdatering sendes',
    },
    crowdfundingEnough: {
        nb: 'Dette produktet har nok reservasjoner. Du vil bli belastet når det produseres. Vi sender en oppdatering ',
        en: 'This product has enough reservations. You will be charged when production starts. We’ll send an update on ',
    },
    tryAgain: {
        en: 'Try Again',
        nb: 'Prøv igjen',
    },
    lifetimeService: {
        en: 'Lifetime service',
        nb: 'Livstidsservice',
    },
    forReservation: {
        en: 'now',
        nb: 'nå',
    },
    whenAvailable: {
        en: 'regular price',
        nb: 'ordinær pris',
    },
    willBeChargedWhenProductionStarts: {
        en: 'Charged if production starts',
        nb: 'Belastes hvis den produseres',
    },
    cartCrowdfunding: {
        en: 'Reserved',
        nb: 'Reserveres',
    },
    discussion: {
        en: 'Discussion',
        nb: 'Diskusjon',
    },
    discussionIntro: {
        en: 'Join the discussion',
        nb: 'Bli med i diskusjonen',
    },
    discussionHelpUsImprove: {
        en: 'Write your comment...',
        nb: 'Skriv din kommentar...',
    },
    'Share your thoughts on this product': {
        en: 'Share your thoughts on this product',
        nb: 'Del dine innspill på dette produktet',
    },
    'Any feedback on this article? Share your thoughts here.': {
        en: 'Any feedback on this article? Share your thoughts here.',
        nb: 'Har du noen kommentarer til denne artikkelen? Del dine innspill her.',
    },
    discussionMoreReplies: {
        en: 'more replies',
        nb: 'tidligere svar',
    },
    'Write a reply...': {
        en: 'Write a reply...',
        nb: 'Skriv et svar...',
    },
    loading: {
        en: 'Loading...',
        nb: 'Laster...',
    },
    publish: {
        en: 'Publish',
        nb: 'Publiser',
    },
    emailRequired: {
        en: 'Email required',
        nb: 'E-post påkrevd',
    },
    phoneRequired: {
        en: 'Phone number required',
        nb: 'Telefonnummer påkrevd',
    },
    passwordRequired: {
        en: 'Password required',
        nb: 'Passord påkrevd',
    },
    repeatDoesNotMatch: {
        en: 'Passwords do not match',
        nb: 'Passordene må være like',
    },
    passwordLengthError: {
        en: 'Your password needs at least 8 characters',
        nb: 'Passordet ditt må ha minst 8 tegn',
    },
    nameRequired: {
        en: 'Name required',
        nb: 'Navn påkrevd',
    },
    birthYearMustBeANumber: {
        en: 'Birth year must be a number',
        nb: 'Fødselsår må være et tall',
    },
    birthYearMustBeAValidBirthYear: {
        en: 'Birth year must be a valid year',
        nb: 'Fødselsår må være et gyldig årstall',
    },
    logInHeader: {
        en: 'Log in | NP community',
        nb: 'Logg inn | NP community',
    },
    registerHeader: {
        en: 'Join our community',
        nb: 'Bli med i vår community',
    },
    loginIntro: {
        en: 'To take part in activities on our website, you must log in or create a user. As a member of our community, you will be able to design products with us, develop your own product ideas and test prototypes.',
        nb: 'For å gi dine innspill må du logge inn eller lage bruker. Ved å være medlem i vår community får du blant annet mulighet til å designe produkter sammen med oss, utvikle egne produktidéer og teste prototyper.',
    },
    registerIntro: {
        en: 'Hello stranger! We need a little info to create your account.',
        nb: 'Hei der! Vi trenger litt informasjon for å lage en bruker til deg.',
    },
    registerInfoIntro: {
        en: 'Nice to have you! We would love to get to know you a little better.',
        nb: 'Stas å ha deg med! Enda mer stas om vi kan få bli litt bedre kjent med deg.',
    },
    registerInfoHeader: {
        en: 'Thank you!',
        nb: 'Tusen takk!',
    },
    register: {
        en: 'Create account',
        nb: 'Lag bruker',
    },
    logOut: {
        en: 'Log out',
        nb: 'Logg ut',
    },
    logIn: {
        en: 'Log in',
        nb: 'Logg inn',
    },
    emailLabel: {
        en: 'Email',
        nb: 'E-post',
    },
    emailPlaceholder: {
        en: 'Enter your email...',
        nb: 'Skriv inn e-post...',
    },
    passwordLabel: {
        en: 'Password',
        nb: 'Passord',
    },
    passwordPlaceholder: {
        en: 'Enter your password...',
        nb: 'Skriv inn passord...',
    },
    newPasswordPlaceholder: {
        en: 'Your password must be at least 8 characters...',
        nb: 'Passordet ditt må ha minst 8 tegn...',
    },
    repeatPasswordLabel: {
        en: 'Repeat password',
        nb: 'Gjenta passord',
    },
    repeatPasswordPlaceholder: {
        en: 'Repeat password...',
        nb: 'Gjenta passord...',
    },
    changePassword: {
        en: 'Change password',
        nb: 'Bytt passord',
    },
    oldPassword: {
        en: 'Old password',
        nb: 'Gammelt passord',
    },
    newPassword: {
        en: 'New password',
        nb: 'Nytt passord',
    },
    repeatNewPassword: {
        en: 'Repeat new password',
        nb: 'Gjenta nytt passord',
    },
    passwordChangeSuccess: {
        en: 'Your password has been updated!',
        nb: 'Ditt passord har blitt oppdatert!',
    },
    passwordChangeWrongOldPassword: {
        en: 'Wrong old password. Try again!',
        nb: 'Feil gammelt passord. Prøv igjen!',
    },
    nameLabel: {
        en: "What's your name?",
        nb: 'Hva heter du?',
    },
    namePlaceholder: {
        en: 'Name...',
        nb: 'Navn...',
    },
    newsletterLabel: {
        en: 'Sign up our newsletter for knowledge about sustainability, unfiltered "behind the seams," maintenance tips, courses and events, and of course, occasional product updates',
        nb: 'Meld meg på vårt nyhetsbrev for kunnskap om bærekraft, usminket "behind the seams", vedlikeholdstips, kurs og arrangementer, bra ting vi gjør, ting vi driter oss ut på, info om "play more-"turer, og selvsagt produktnyheter av og til',
    },
    communityNewsletterLabel: {
        en: 'Add me to the community and keep me in the loop',
        nb: 'Ja takk til å være med i community og få info fra oss',
    },
    preferredFitLabel: {
        en: 'What fit do you prefer?',
        nb: 'Hvilken passform foretrekker du?',
    },
    preferredFitMen: {
        en: 'Men',
        nb: 'Herre',
    },
    preferredFitWomen: {
        en: 'Women',
        nb: 'Dame',
    },
    preferredFitBoth: {
        en: 'Both',
        nb: 'Begge',
    },
    countryLabel: {
        en: 'Country',
        nb: 'Land',
    },
    countryPlaceholder: {
        en: 'Enter country...',
        nb: 'Skriv inn land...',
    },
    birthYearLabel: {
        en: 'What is your birthyear?',
        nb: 'Hvilket år er du født?',
    },
    birthYearPlaceholder: {
        en: 'Enter birthyear...',
        nb: 'Skriv inn fødselsår...',
    },
    postCodeLabel: {
        en: 'Zip code',
        nb: 'Postnummer',
    },
    postCodePlaceholder: {
        en: 'Enter post code...',
        nb: 'Skriv inn postnummer...',
    },
    profilePictureLabel: {
        en: 'Profile picture?',
        nb: 'Profilbilde?',
    },
    upload: {
        en: 'Upload',
        nb: 'Last opp',
    },
    change: {
        en: 'Change',
        nb: 'Endre',
    },
    save: {
        en: 'Save',
        nb: 'Lagre',
    },
    'Your password needs at least 8 characters': {
        en: 'Your password must be at least 8 characters',
        nb: 'Passordet ditt må ha minst 8 tegn',
    },
    'Your password can not be more than 256 characters': {
        en: 'Your password can not be more than 256 characters',
        nb: 'Passordet ditt kan ikke ha mer enn 256 tegn',
    },
    'A user already exists with this email': {
        en: 'A user already exists with this email',
        nb: 'Det finnes allerede en bruker med denne e-postadressen',
    },
    'Incorrect email or password': {
        en: 'Incorrect email or password',
        nb: 'Feil e-post eller passord',
    },
    forgotPassword: {
        en: 'Forgot Password?',
        nb: 'Glemt passord?',
    },
    forgotPasswordIntro: {
        en: "Enter your email, and we'll send you a code to reset your password.",
        nb: 'Skriv inn din e-post, så sender vi deg en kode for å endre passordet ditt.',
    },
    forgotPasswordSubmit: {
        en: 'Send email',
        nb: 'Send e-post',
    },
    forgotPasswordCodeHeader: {
        nb: 'Skriv inn koden',
        en: 'Enter code',
    },
    forgotPasswordCodeIntro: {
        nb: 'Skriv inn koden du mottok på e-post',
        en: 'Enter the code you received in the email',
    },
    forgotPasswordCodeLabel: {
        nb: 'kode',
        en: 'code',
    },
    forgotPasswordCodePlaceholder: {
        nb: '000000',
        en: '000000',
    },
    forgotPasswordCodeSubmit: {
        nb: 'Neste',
        en: 'Next',
    },
    forgotPasswordNewPasswordHeader: {
        nb: 'Nytt passord',
        en: 'New password',
    },
    forgotPasswordNewPasswordIntro: {
        nb: 'Velg deg et nytt passord',
        en: 'Choose a new password',
    },
    forgotPasswordNewPasswordSubmit: {
        nb: 'Endre passord',
        en: 'Change password',
    },
    codeRequired: {
        nb: 'Koden må være 6 tegn',
        en: 'Code must be 6 characters',
    },
    'Incorrect code': {
        en: 'Incorrect code',
        nb: 'Feil kode',
    },
    myAccount: {
        en: 'My Account',
        nb: 'Min konto',
    },
    pinned: {
        en: 'Pinned',
        nb: 'Framhevet',
    },
    joinTheDiscussion: {
        en: 'Join the discussion',
        nb: 'Se og gi innspill',
    },
    White: {
        en: 'White',
        nb: 'Hvit',
    },
    Black: {
        en: 'Black',
        nb: 'Svart',
    },
    Blue: {
        en: 'Blue',
        nb: 'Blå',
    },
    crowdfundingFinishedNotEnough: {
        en: 'This campaign is over. The product did not receive enough reservations to go into production.',
        nb: 'Denne kampanjen er nå avsluttet. Produktet har ikke fått nok reservasjoner til at vi produserer det.',
    },
    crowdfundingFinishedSuccess: {
        en: "Woohoo! This product received enough reservations to proceed to production. To everyone who chipped in: We'll keep you posted!",
        nb: 'Hurra! Produktet har fått nok reservasjoner til at vi produserer det. Til alle som har reservert: Vi holder dere oppdatert!',
    },
    ordinaryProducts: {
        en: 'Ordinary products',
        nb: 'Ordinære produkter',
    },
    reservedProducts: {
        en: 'Reserverd products',
        nb: 'Reserverte produkter',
    },
    chargedNow: {
        nb: 'Betales nå',
        en: 'Charged now',
    },
    errorLoadingCrowdfundingStatus: {
        nb: 'Det skjedde en feil mens vi lastet status',
        en: 'An error occured while loading the status',
    },
    cartIsEmpty: {
        nb: 'Handlekurven din er tom',
        en: 'Your cart is empty',
    },
    emptyCart: {
        nb: 'Du har ikke lagt til noen produkter i handlekurven ennå. Fyll den opp!',
        en: 'There are currently no products in your cart. Fill it up! ',
    },
    allOurProducts: {
        nb: 'Se alle våre produkter',
        en: 'See all products',
    },
    store: {
        nb: 'Butikk',
        en: 'Store',
    },
    weekdays: {
        nb: 'Ukedager',
        en: 'Weekdays',
    },
    footerCopyright2: {
        nb: '2023 All rights reserved',
        en: '2023 All rights reserved',
    },
    paymentsAreHandled: {
        nb: 'Betaling håndteres av ',
        en: 'Payments are handled by ',
    },
    errorPageHeader: {
        en: 'Out of play!',
        nb: 'Ute av spill!',
    },
    errorPageContent: {
        en: 'Our bad. That was a foul ball. Feel free to run back.',
        nb: 'Det var sikkert ikke hit du hadde tenkt deg? Vår feil!',
    },
    errorPageBackToFrontpage: {
        en: 'To the front page',
        nb: 'Gå til forsiden',
    },
    errorPageAllProducts: {
        en: 'See all products',
        nb: 'Se alle produkter',
    },
    pitchIdeaHeader: {
        en: 'Pitch an idea',
        nb: 'Send din idé',
    },
    pitchIdeaIntro: {
        en: "Cool to hear that you have an idea you want to bring to life. It's even cooler to hear that you want to develop it with us.",
        nb: 'Kult å høre at du har en idé du vil dele med oss! La oss se om vi sammen kan gjøre den til en realitet.',
    },
    pitchIdeaLabel: {
        en: "What's your idea?",
        nb: 'Hva er din idé?',
    },
    pitchIdeaPlaceholder: {
        en: 'Describe your idea...',
        nb: 'Beskriv idéen din...',
    },
    pitchIdeaThanksHeader: {
        en: 'Thank you!',
        nb: 'Tusen takk!',
    },
    pitchIdeaThanks: {
        en: 'We can’t wait to explore your idea. The ideas we receive are reviewed regularly, and we will get in touch with you once we read yours! Usually within a week.',
        nb: 'Vi gleder oss til å utforske din idé! Vi leser gjennom idéene som kommer inn så fort som mulig og tar kontakt med deg på e-post i løpet av en ukes tid.',
    },
    pitchIdeaContentRequired: {
        en: 'Write your idea here',
        nb: 'Skriv idéen din her',
    },
    pitchIdeaSubmit: {
        en: 'Send',
        nb: 'Send',
    },
    back: {
        en: 'Back',
        nb: 'Tilbake',
    },
    seeAll: {
        nb: 'Se alle...',
        en: 'See all...',
    },
    'Send me updates when someone replies to my post': {
        nb: 'Send meg varsler når andre kommenterer på mine innlegg',
        en: 'Send me updates when someone replies to my post',
    },
    'like this': {
        nb: 'Lik dette',
        en: 'Like this,',
    },
    Like: {
        nb: 'Liker',
        en: 'Like',
    },
    testGarmentsLeft: {
        nb: 'Plagg igjen',
        en: 'Products left',
    },
    orderTestProduct: {
        nb: 'Bestill testprodukt',
        en: 'Order products for testing',
    },
    orderTestProductShort: {
        nb: 'Bestill',
        en: 'Order',
    },
    testPhaseFinished: {
        nb: 'Testplaggene er utsolgt. Følg med på nyhetsbrev for flere testrunder',
        en: 'Sold out. Subscribe to our newsletter to stay updated on the next round of testing',
    },
    page: {
        nb: 'side',
        en: 'page',
    },
    settings: {
        nb: 'Innstillinger',
        en: 'Settings',
    },
    privacyPolicy: {
        nb: 'personvernerklæringen.',
        en: 'privacy policy.',
    },
    privacySettings: {
        nb: 'Innstillinger for personvern',
        en: 'Privacy settings',
    },
    privacySettingsInfoText: {
        nb: 'Vi har stor respekt for personvern, og du kan derfor tilpasse personvernsinstillingene slik du ønsker her. Les mer om de ulike kategoriene under og still inn slik du foretrekker. Husk at blokkering av visse cookies vil påvirke din opplevelse og relevenasen av våre tjenester. Hvis du vil vite mer om cookies kan du sjekke ut',
        en: 'We respect your privacy, and you can therefor tweak your privacy settings to your liking here. Read more about the different categories below and choose your preferred preferences. Remember, blocking certain cookies will affect your experience and the relevance of our services. If you want to learn more about cookies and why we use them, check out our privacy policy.',
    },
    saveSettings: {
        nb: 'Lagre innstillinger',
        en: 'Save settings',
    },
    necessary: {
        nb: 'NØDVENDIGE',
        en: 'NECESSARY',
    },
    neccessaryText: {
        nb: `Dette er cookies som er nødvendige for nettsidens grunnleggende funksjonalitet, og er derfor alltid aktivert. Eksempelvis tillater dette at vi husker hva du har lagt i handlekurven eller at du er logget inn i Community.`,
        en: `These are cookies that are required to ensure the website's basic functionality, and are therefor always active. For example, they allow us to remember what you have put in your bag, or that you are logged in to our Community.
        `,
    },
    alwaysActive: {
        nb: 'Alltid aktiv',
        en: 'Always active',
    },
    marketing: {
        nb: 'MARKEDSFØRING',
        en: 'MARKETING',
    },
    analytics: {
        nb: 'Analyse',
        en: 'Analytics',
    },
    mAndAText: {
        nb: `Vi bruker cookies og lignende teknologi (fra tredjepart) til å nå frem til deg med relevant innhold i sosiale medier og til å optimalisere nettsiden for å gi deg en god brukeropplevelse. Noen ganger innebærer dette prosessering av din personlig data, og for mer informasjon om dette kan du sjekke ut`,
        en: `We use cookies and other similar technologies (from third parties) to present you with relevant content in social media, and to optimize our website and give you an awesome experience. Sometimes, this involves processing your personal data, and for more information on that you can check out our`,
    },
    mAndAText2: {
        nb: `Dersom du ikke aksepterer dette kan det hende du får mer irrelevant innhold fra oss.`,
        en: `If you do not accept this, you may experience irrelevant content from us.`,
    },
    allowAll: {
        nb: 'Tillatt alle',
        en: 'Allow all',
    },
    colorFilter: {
        nb: 'Farger',
        en: 'Colors',
    },
    underAddToCartButtonText: {
        nb: 'Gratis frakt på bestillinger over 1200 NOK. Alltid gratis retur',
        en: 'Free shipping on orders over 120 EUR. Always free returns.',
    },
    functions: {
        nb: 'Funksjoner',
        en: 'Functions',
    },
    '#d35c3d': {
        en: 'Rust',
        nb: 'Rustrød',
    },
    '#000000': {
        en: 'Black',
        nb: 'Svart',
    },
    '#4c6373': {
        en: 'Bee',
        nb: 'Bee',
    },
    '#464646': {
        en: 'Dark grey',
        nb: 'Mørkegrå',
    },
    '#0165a3': {
        en: 'Blue',
        nb: 'Blå',
    },
    '#86614C': {
        en: 'Brown',
        nb: 'Brun',
    },
    '#790135': {
        en: 'Burgundy',
        nb: 'Burgunder',
    },
    '#0165a3#efecdc': {
        en: 'Blue/white stripes',
        nb: 'Blå & hvite striper',
    },
    '#36445a': {
        en: 'Dark denim blue',
        nb: 'Mørk denim blå',
    },
    '#2a2a2a': {
        en: 'Charcoal',
        nb: 'Kull',
    },
    '#c5867f': {
        en: 'Coral clay',
        nb: 'Lys Korall',
    },
    '#485a46': {
        en: 'Dark green',
        nb: 'Mørkegrønn',
    },
    '#334945': {
        en: 'Fjord green',
        nb: 'Fjordgrønn',
    },
    '#4b4b50': {
        en: 'Stone grey',
        nb: 'Steingrå',
    },
    '#79011a': {
        en: 'Dark red',
        nb: 'Mørk rød',
    },
    '#6A6E51': {
        en: 'Forest green',
        nb: 'Skogsgrønn',
    },
    '#567c6c': {
        en: 'Green',
        nb: 'Grønn',
    },
    '#7d7d7d': {
        en: 'Grey',
        nb: 'Grå',
    },
    '#243B7B': {
        en: 'Indigo blue',
        nb: 'Indigoblå',
    },
    '#ca8282': {
        en: 'Light rust',
        nb: 'Light rust',
    },
    '#b68f52': {
        en: 'Mustard',
        nb: 'Sennepsgul',
    },
    '#223f9c': {
        en: 'Petrol blue',
        nb: 'Petrol blue',
    },
    '#b41404': {
        en: 'Red',
        nb: 'Red',
    },
    '#a32c01#efecdc': {
        en: 'Red/white stripes',
        nb: 'Røde og hvite striper',
    },
    '#b4c1cc': {
        en: 'Snowflake',
        nb: 'Snøfnugg',
    },
    '#efecdc': {
        en: 'White',
        nb: 'Hvit',
    },
    '#d5978c': {
        en: 'Wild salmon',
        nb: 'Villaks',
    },
    search: {
        en: 'Search',
        nb: 'Søk',
    },
    length: {
        en: 'Length',
        nb: 'Lengde',
    },
    articles: {
        en: 'Articles',
        nb: 'Artikler',
    },
    youtubePricacyPolicy: {
        en: 'By playing the video you accept Youtube&rsquo;s use of cookies.',
        nb: 'Ved å spille av videoen godtar du YouTube&rsquo;s bruk av informasjonskapsler.',
    },
    viewAll: {
        en: 'View all',
        nb: 'Se alle',
    },
    breadcrumbs: {
        en: 'Breadcrumbs',
        nb: 'Brødsmuler',
    },
    home: {
        en: 'Home',
        nb: 'Hjem',
    },
    content: {
        en: 'Content',
        nb: 'Innhold',
    },
};

const translate = function (language: string | null | undefined, key: string): string {
    if (language === null || typeof language === 'undefined') {
        return key;
    }

    if (translations[key] && translations[key][language] !== undefined) {
        return translations[key][language];
    } else {
        return key;
    }
};

export default translate;
