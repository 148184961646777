import React, { FC } from 'react';
import { useLockBodyScroll } from '../lib/hooks';
import { CloseIcon } from '../svg/CloseIcon';
import { zIndex, space, fontSize, weight, buttonReset } from './styles';

export const Modal: FC<{ close: () => void; children: React.ReactNode }> = ({
    close,
    children,
}) => {
    useLockBodyScroll();

    return (
        <div
            css={`
                box-sizing: border-box;
                *,
                *:before,
                *:after {
                    box-sizing: inherit;
                }
                position: fixed;
                background: rgba(0, 0, 0, 0.3);
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: ${zIndex.modal};
            `}
            onClick={event => {
                if (event.target === event.currentTarget) {
                    close();
                }
            }}
        >
            <div
                css={`
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    max-height: 100vh;
                    overflow-y: auto;
                    background: #fff;
                    padding: ${space(3)} ${space(3)} ${space(15)};

                    h1 {
                        ${fontSize.large};
                        ${weight.regular};
                    }

                    @media (min-width: 800px) and (min-height: 700px) {
                        top: initial;
                        border-radius: 10px 10px 0 0;
                    }

                    @media (min-height: 700px) {
                        padding-top: ${space(8)};
                    }
                `}
            >
                <div
                    css={`
                        max-width: 1500px;
                        margin: 0 auto;
                        position: relative;

                        h1 {
                            margin: 0 ${space(8)} ${space(2)} 0;
                        }
                    `}
                >
                    {children}
                    <button
                        css={`
                            ${buttonReset};
                            position: absolute;
                            right: ${space(3)};
                            top: ${space(2)};
                        `}
                        onClick={() => close()}
                    >
                        <CloseIcon />
                    </button>
                </div>
            </div>
        </div>
    );
};
