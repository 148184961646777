import { Language } from '../interfaces';

type Props = {
    language: Language;
    path?: string | null;
    categoryPath?: string | null;
};

export const getCommunityPagePath = ({ language, path, categoryPath }: Props) => {
    if (!path) {
        return `/${language}/community`;
    }
    const category = categoryPath ? `/${categoryPath}` : '';
    if (language === 'en') {
        return `/${language}${category}/${path}`;
    }
    return `/${language}${category}/${path}`;
};
