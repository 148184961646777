import React, { FC } from 'react';
import styled from 'styled-components';

export const ExternalEmbed: FC<{
    url?: string | null | undefined;
    autoplay?: boolean | null | undefined;
    loop?: boolean | null | undefined;
}> = ({ url, autoplay, loop }) => {
    if (!url) {
        return null;
    }

    if (url.startsWith('https://vimeo.com/')) {
        return <VimeoEmbed url={url} autoplay={autoplay ?? false} loop={loop ?? false} />;
    }

    return <div>Invalid url: {url}</div>;
};

const VimeoEmbed: FC<{ url: string; autoplay: boolean; loop: boolean }> = ({
    url,
    autoplay,
    loop,
}) => {
    const match = /https:\/\/vimeo.com\/(\d*)/.exec(url);

    const videoId = match?.[1];

    const autoplayParam = autoplay ? '&autoplay=1' : '';
    const loopParam = loop ? '&loop=1' : '';

    return (
        <div>
            {match && (
                <IframeVideoContainer>
                    <iframe
                        src={`https://player.vimeo.com/video/${videoId}?h=da8fa8f977${autoplayParam}&autopause=0${loopParam}&title=0&byline=0&portrait=0`}
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen
                        css={`
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                        `}
                    ></iframe>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                </IframeVideoContainer>
            )}
        </div>
    );
};

export const IframeVideoContainer = styled.div`
    --video--width: 1920;
    --video--height: 1080;
    position: relative;
    padding-bottom: calc(var(--video--height) / var(--video--width) * 100%);
    overflow: hidden;
    max-width: 100%;
    background: black;
    & iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;
