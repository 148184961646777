import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import constants from '../../constants';
import Navigation from './navigation/navigation';
import NewNavigation from './navigation/NewNavigation';
import { weight, fontSize, space, zIndex } from '../styles';
import { useOnScreen } from '../../lib/hooks';
import { useLocalizedString } from '../../i18n';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { Maybe, LocaleString } from '../../../graphql-types';
import { animated, config, useSpring } from 'react-spring';
import { desktopMedia } from '../../templates/productPage/constants';
import { Link as InternalLink } from 'gatsby';

const HeaderDiv = styled.div<{
    image?: Maybe<SanityImageSource>;
    imageMobile?: Maybe<SanityImageSource>;
}>`
    box-sizing: border-box;

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }
    height: 100%;
    // height: calc(35vh + 100px); // strange behaviour
    @media ${desktopMedia} {
        // height: calc(50vh + 48px); // strange behaviour
    }
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
`;

const VerticalCenter = styled.div`
    width: 100%;
    max-width: 800px;
    text-align: center;
    padding: 0 ${space(3)};

    margin-top: auto;
    margin-bottom: auto;
`;

const Title = styled.h1`
    ${fontSize.xxlarge};
    ${weight.regular};
    text-size-adjust: none;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    color: #000;
    font-family: 'FK-Screamer', Impact, sans-serif;
    text-transform: uppercase;
    letter-spacing: 1%;
    @media (max-width: ${constants.breakpoints.navigation}) {
        font-size: 100px;
        line-height: 80px;
        letter-spacing: 1%;
    }
`;

// From wikipedia: In web design, a hero image is a large web banner image, prominently placed on a web
// page, generally in the front and center.
// This is the large image on the top, used on frontpage and confirmation-page (and maybe more)
export type HeroData = {
    image?: Maybe<SanityImageSource>;
    imageMobile?: Maybe<SanityImageSource>;
    textShort?: Maybe<LocaleString>;
};

export const Header: React.FC<{
    hero?: HeroData;
    enableBackButton: boolean;
    language: string;
}> = ({ hero, enableBackButton, language }) => {
    if (hero) {
        return (
            <HeaderWithHero hero={hero} enableBackButton={enableBackButton} language={language} />
        );
    }

    return <NewNavigation />;
};

const HeaderWithHero: React.FC<{
    hero: HeroData;
    enableBackButton: boolean;
    language: string;
}> = ({ hero, enableBackButton, language = 'nb' }) => {
    const heroRef = useRef<HTMLDivElement | null>(null);
    const [heroIsOnScreen] = useOnScreen(heroRef);
    const style = useSpring({
        top: heroIsOnScreen ?? true ? `-${space(12.5)}` : '0px',
        config: config.default,
    });

    // Splits text from headerText on newline
    const heroText = useLocalizedString(hero.textShort).split('\\n');

    return (
        <>
            <HeaderDiv ref={heroRef} image={hero.image} imageMobile={hero.imageMobile}>
                <NewNavigation />

                <VerticalCenter>
                    <Title>
                        {heroText.map((val, i) => {
                            if (i > 0) {
                                return (
                                    <span
                                        key={i}
                                        css={css`
                                            display: block;
                                        `}
                                    >
                                        {val}
                                    </span>
                                );
                            } else {
                                return val;
                            }
                        })}
                    </Title>
                    <div
                        css={`
                            box-sizing: border-box;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            margin-top: -40px;
                            margin-bottom: 20px;
                            @media ${desktopMedia} {
                                margin-top: clamp(-40px, 13vw, -80px);
                                padding-bottom: 70px;
                            }
                        `}
                    >
                        <InternalLink to={`/${language}/shop/women`}>
                            <span
                                tabIndex={0}
                                aria-label="Women"
                                css={`
                                    box-sizing: border-box;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    padding: 12.5px 20px;
                                    justify-content: center;

                                    width: 80px;
                                    height: 50px;

                                    background: #ffffff;
                                    border: 1px solid #000000;
                                    border-radius: 3px;

                                    margin: 5px;
                                    color: black;

                                    &:hover {
                                        background: black;
                                        color: white;
                                        cursor: pointer;
                                        transition: ease 0.4s;
                                    }
                                `}
                            >
                                {language === 'nb' ? 'Dame' : 'Women'}
                            </span>
                        </InternalLink>
                        <InternalLink to={`/${language}/shop/men`}>
                            <span
                                tabIndex={0}
                                aria-label="Men"
                                css={`
                                    box-sizing: border-box;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    padding: 12.5px 20px;
                                    justify-content: center;

                                    width: 80px;
                                    height: 50px;

                                    background: #ffffff;
                                    border: 1px solid #000000;
                                    border-radius: 3px;

                                    margin: 5px;
                                    color: black;

                                    &:hover {
                                        background: black;
                                        color: white;
                                        cursor: pointer;
                                        transition: ease 0.4s;
                                    }
                                `}
                            >
                                {language === 'nb' ? 'Herre' : 'Men'}
                            </span>
                        </InternalLink>
                    </div>
                </VerticalCenter>
            </HeaderDiv>
            {/* @ts-ignore */}
            <animated.div
                style={style}
                css={`
                    position: fixed;
                    z-index: ${zIndex.globalNavigation};
                    left: 0;
                    right: 0;
                `}
            >
                <Navigation
                    position="static"
                    enableBackButton={enableBackButton}
                    alwaysShowBorder={true}
                />
            </animated.div>
        </>
    );
};
