const constants = {
    colors: {
        callToAction: { tag: 'rgba(255,255,255,0.6)' },
        messageBar: { background: '#C5D9C3', text: 'black' },
        button: {
            active: { text: 'white', border: 'black', background: 'black' },
            disabled: { text: '#737373', border: '#D1D1D1', background: 'white' },
        },
        pallet: {
            testPhase: '#358535',
            crowdfunding: '#B13D0E',
            crowdsourcing: '#0D6490',
            secondary: '#737373',
            darkGreen: '#1F3033',
            lightGreen: '#C5D9C3',
            normalGreen: '#73A74B',
            beige: '#F3F1EE',
            error: '#D80134',
            disabled: '#D1D1D1',
            black: '#000000',
            grey: '#737373',
            lightGrey: '#f4f4f4',
            white: '#FFFFFF',
        },
    },
    breakpoints: {
        mobile: '600px',
        miniTablet: '800px',
        navigation: '950px',
    },
};

export default constants;
