import { default as React, FC, useRef } from 'react';
import { css } from 'styled-components';
import { Link } from 'gatsby';
import MenuCart from './menuCart';
import { I18n, useLanguage } from '../../../i18n';
import { fontSize, linkReset, space, weight, zIndex } from '../../styles';
import { HamburgerMenu } from '../../navigation/HamburgerMenu';
import { useLoginState } from '../../auth/LoginContext';
import { VisuallyHidden } from '@reach/visually-hidden';
import { useDefaultProfilePicture } from '../../../lib/useDefaultProfilePicture';
import { BackOrShopPageButton } from '../../BackOrShopPageButton';
import { useIsSticky } from '../../../lib/hooks';

const MenuLogo: React.FC<{ enableBackButton: boolean }> = ({ enableBackButton }) => {
    let language = useLanguage();

    if (enableBackButton) {
        return (
            <div
                css={`
                    display: flex;
                    align-items: center;
                `}
            >
                <BackOrShopPageButton />
                <Link
                    to={`/${language}/shop`}
                    css={`
                        color: var(--hover-color, #2b2929);
                        display: flex;
                        align-items: center;
                    `}
                >
                    <img src="/img/rock.png" style={{ width: '32px' }} />
                </Link>
            </div>
        );
    }

    return (
        <Link
            to={`/${language}`}
            css={`
                color: var(--hover-color, #2b2929);
                display: block;
            `}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '4px',
                }}
            >
                <img src="/img/rock.png" style={{ width: '32px' }} />
            </div>
        </Link>
    );
};

const Navigation: FC<{
    position: 'sticky' | 'absolute' | 'fixed' | 'static';
    alwaysShowBorder?: boolean;
    inverted?: boolean;
    enableBackButton: boolean;
}> = ({ position, inverted = false, enableBackButton, alwaysShowBorder = false }) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const isSticky = useIsSticky(containerRef);
    const lang = useLanguage();
    const loginState = useLoginState();
    const defaultProfilePicture = useDefaultProfilePicture(loginState.user?.id || 0);
    const profilePicture =
        loginState.user &&
        (loginState.user.profilePicture
            ? `${process.env.GATSBY_IMAGE_HOST}${loginState.user.profilePicture}?w=50&h=50&fit=crop`
            : defaultProfilePicture);
    return (
        <nav
            ref={containerRef}
            css={`
                box-sizing: border-box;

                *,
                *:before,
                *:after {
                    box-sizing: inherit;
                }

                ${inverted
                    ? css`
                          --primary-color: #fff;
                          --hover-color: #fff;
                      `
                    : css`
                          --primary-color: #737373;
                          --hover-color: #2b2929;
                      `}

                display: flex;
                justify-content: space-between;
                align-items: center;
                position: ${position};
                top: -1px;
                z-index: ${zIndex.globalNavigation};
                width: 100%;
                height: ${space(6)};
                padding: 0 ${space(2)};
                /* Compensate for top: -1px by adding 1 */
                padding-top: 1px;

                ${((isSticky && position === 'sticky') || alwaysShowBorder) &&
                css`
                    border-top: 2px solid #fff;
                    border-bottom: 2px solid #f2f2f2;
                `}

                ${!inverted && `background: white`};
            `}
        >
            <MenuLogo enableBackButton={enableBackButton} />
            <div
                css={`
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                `}
            >
                {profilePicture && (
                    <Link
                        to={`/${lang}/account`}
                        css={`
                            ${linkReset};
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <img
                            src={profilePicture}
                            css={`
                                width: ${space(3.5)};
                                height: ${space(3.5)};
                                border-radius: 5px;
                            `}
                        />
                        <VisuallyHidden>
                            <I18n>myAccount</I18n>
                        </VisuallyHidden>
                    </Link>
                )}
                <Link
                    to={`/${lang}/shop`}
                    css={`
                        margin-left: ${space(1)};
                        ${fontSize.regular};
                        ${weight.regular};
                        ${linkReset};
                    `}
                >
                    <I18n allCapital={true}>products</I18n>
                </Link>
                <HamburgerMenu />
                <MenuCart />
            </div>
        </nav>
    );
};

export default Navigation;
