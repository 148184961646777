import React, { FC } from 'react';

export const Hamburger: FC = () => {
    return (
        <svg
            aria-hidden
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="14"
            fill="none"
            viewBox="0 0 16 12"
        >
            <path stroke="currentcolor" strokeWidth="1.5" d="M0 1.25L16 1.25"></path>
            <path stroke="currentcolor" strokeWidth="1.5" d="M0 6.25L16 6.25"></path>
            <path stroke="currentcolor" strokeWidth="1.5" d="M0 11.25L16 11.25"></path>
        </svg>
    );
};
