import React, { FC, useContext } from 'react';

type ShoppingCartContext = {
    toggleOpen: () => void;
};
const ShoppingCartContext = React.createContext<ShoppingCartContext | null>(null);
export const ShoppingCartProvider: FC<{ value: ShoppingCartContext; children: React.ReactNode }> =
    ({ value, children }) => {
        return (
            <ShoppingCartContext.Provider value={value}>{children}</ShoppingCartContext.Provider>
        );
    };

export const useShoppingCart = (): ShoppingCartContext => {
    let context = useContext(ShoppingCartContext);
    if (!context) {
        throw new Error('useShoppingCart can only be called from inside a ShoppingCartProvider');
    }
    return context;
};
