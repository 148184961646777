import React from 'react';
import { useNPContext } from '../../../npContext';
import styled, { css } from 'styled-components';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { ICartItem } from '../../../interfaces';
import translate from '../../../translations';
import constants from '../../../constants';
import { secondaryButton, space, linkReset } from '../../styles';
import { I18n, useLanguage } from '../../../i18n';
import { MinusIcon } from '../../../svg/MinusIcon';
import { PlusIcon } from '../../../svg/PlusIcon';
import { useCentraItemVariant } from '../../../api/centra';
import { usePreorderStockForCentraVariant } from '../../../api/np-backend/preorderStock';
import { CartItemDataQuery } from '../../../../graphql-types';

const commonQuantityAdjustors = css`
    ${secondaryButton};
    width: 32px;
    height: 32px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${space(1)};
`;

const QuantityIndicator = styled.div`
    ${commonQuantityAdjustors};
    cursor: default;
`;

const QuantityButton = styled.button`
    ${commonQuantityAdjustors};
    transition: background 100ms ease-out;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: #f2f2f2;
    }
`;

const sendAddEvent = (cartItem: ICartItem, language: string) => {
    window.dataLayer &&
        window.dataLayer.push({
            event: 'addToCart',
            ecomm_prodid: [cartItem.productId],
            ecomm_pagetype: 'cart',
            ecomm_totalvalue: cartItem.priceNumber,
            ecommerce: {
                currencyCode: cartItem.currency,
                add: {
                    products: [
                        {
                            name: cartItem.productName,
                            id: cartItem.productId,
                            price: cartItem.priceNumber,
                            brand: 'Northern Playground',
                            category: cartItem.collectionName,
                            variant: `${translate(language, cartItem.sex)} ${
                                cartItem.size
                            } ${translate(language, 'in')} ${cartItem.color}`,
                            quantity: 1,
                        },
                    ],
                },
            },
        });
};

const sendRemoveEvent = (cartItem: ICartItem, language: string) => {
    window.dataLayer &&
        window.dataLayer.push({
            event: 'removeFromCart',
            ecomm_prodid: [cartItem.productId],
            ecomm_pagetype: 'cart',
            ecomm_totalvalue: cartItem.priceNumber,
            ecommerce: {
                currencyCode: cartItem.currency,
                remove: {
                    products: [
                        {
                            name: cartItem.productName,
                            id: cartItem.productId,
                            price: cartItem.priceNumber,
                            brand: 'Northern Playground',
                            category: cartItem.collectionName,
                            variant: `${translate(language, cartItem.sex)} ${
                                cartItem.size
                            } ${translate(language, 'in')} ${cartItem.color}`,
                            quantity: 1,
                        },
                    ],
                },
            },
        });
};

export function useStockForCart(
    itemId: string,
    sanityProduct?: CartItemDataQuery['allProduct']['nodes'][0]
): number | null {
    const liveVariant = useCentraItemVariant(itemId);
    const preorderStock = usePreorderStockForCentraVariant(itemId);

    if (sanityProduct?.productState === 'crowdFunding') {
        // Always assume there is stock for crowdFunding
        return 100;
    }

    if (liveVariant.error) {
        throw liveVariant.error;
    }

    if (liveVariant.isLoading || preorderStock.isLoading || !liveVariant.data) {
        return null;
    }

    if (liveVariant.data?.stock === 'infinite') {
        if (preorderStock.error) {
            // If we don't get an answer from the backend, fallback to assuming there is stock
            return 1000;
        }
        return preorderStock.data.stock;
    }

    return liveVariant.data?.stock;
}

const CartItem: React.FC<{
    cartItem: ICartItem;
    // "Alternate view", the one used on shopping page
    alternate: boolean;
}> = ({ cartItem, alternate }) => {
    let language = useLanguage();
    const context = useNPContext();
    const image1x = cartItem.media.mini && cartItem.media.mini[0];
    const image2x = cartItem.media.thumb && cartItem.media.thumb[0];
    const image3x = cartItem.media.standard && cartItem.media.standard[0];

    const products = useStaticQuery<CartItemDataQuery>(graphql`
        query CartItemData {
            allProduct {
                nodes {
                    centraId
                    productState
                    slug {
                        nb
                        en
                    }
                }
            }
        }
    `);

    let sanityProduct = products.allProduct.nodes.filter(
        (el: { centraId: string }) => el.centraId === cartItem.productId
    )[0];

    const stock = useStockForCart(cartItem.itemId, sanityProduct);

    let slug = sanityProduct?.slug?.[language];

    return (
        <Link
            to={`/${language}/shop/${slug}/?sex=${translate(language, cartItem.sex)}&size=${
                cartItem.size
            }&color=${
                cartItem.colorCode2
                    ? `${cartItem.colorCode.replace('#', 'C')}${cartItem.colorCode2.replace(
                          '#',
                          'C'
                      )}`
                    : cartItem.colorCode.replace('#', '')
            }`}
            css={`
                ${linkReset};
                margin-top: ${space(3)};
                display: grid;
                grid-template-columns: auto auto 1fr;
                grid-gap: 0 ${space(2)};
                grid-template-areas: ${alternate
                    ? `
                    'image title price'
                    'image data data'
                    `
                    : `
                    'image title title'
                    'image data buttons'
                    'image price buttons'
                    'image error error'
                    `};
            `}
        >
            <img
                css={`
                    ${alternate
                        ? css`
                              width: 40px;
                              height: 50px;
                          `
                        : css`
                              width: 60px;
                              height: 75px;
                          `}
                    object-fit: cover;
                    grid-area: image;
                    border-radius: 3px;
                `}
                srcSet={`${image1x} 1x, ${image2x} 2x, ${image3x} 3x`}
                src={image1x}
                alt={cartItem.productName}
            />

            <div
                css={`
                    white-space: normal;
                    grid-area: title;
                    color: black;
                `}
            >
                <I18n>{cartItem.color}</I18n> {cartItem.productName}
            </div>
            <div
                css={`
                    grid-area: data;
                    color: #737373;
                `}
            >
                {alternate && cartItem.quantity} <I18n>{cartItem.sex}</I18n>{' '}
                <I18n>{cartItem.size}</I18n>
            </div>
            <div
                css={`
                    grid-area: price;
                    color: ${cartItem.productType === 'crowdfunding' ? '#D6D6D6' : '#737373'};
                    text-align: ${alternate ? 'right' : 'left'};
                `}
            >{`${cartItem.priceNumber} ${cartItem.currency}`}</div>
            {!alternate && (
                <div
                    css={`
                        display: flex;
                        grid-area: buttons;
                        justify-self: end;
                        align-self: end;
                    `}
                >
                    <QuantityButton
                        onClick={ev => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            context.centra.decreaseQuantity(cartItem.line);
                            sendRemoveEvent(cartItem, language);
                        }}
                    >
                        <MinusIcon />
                    </QuantityButton>
                    <QuantityIndicator>{cartItem.quantity}</QuantityIndicator>
                    <QuantityButton
                        disabled={!stock || stock <= cartItem.quantity}
                        onClick={ev => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            if (!stock) {
                                console.error('Stock is not loaded');
                                return;
                            }

                            if (stock > cartItem.quantity) {
                                context.centra.increaseQuantity(cartItem.line);
                                sendAddEvent(cartItem, language);
                            }
                        }}
                    >
                        <PlusIcon />
                    </QuantityButton>
                </div>
            )}
            {stock && stock <= cartItem.quantity ? (
                <div
                    css={`
                        grid-area: error;
                        color: ${constants.colors.pallet.error};
                    `}
                >
                    <I18n>noMore</I18n> {stock} <I18n>leftInStock</I18n>
                </div>
            ) : null}
        </Link>
    );
};

export default CartItem;
