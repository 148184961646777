import React, { FC, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import * as Sentry from '@sentry/browser';
import { npBackendApi } from '../../api/npBackend';
import { I18n, useI18n } from '../../i18n';
import { Button, Buttons, Field, Form } from '../auth/Form';
import { useLoginState } from '../auth/LoginContext';
import { LoginOrRegister } from '../auth/LoginOrRegisterModal';
import { Modal } from '../Modal';
import Portal from '../Portal';
import { buttonReset, space } from '../styles';
import { PaperClipIcon } from '../../svg/PaperClipIcon';
import { RemoveImageIcon } from '../../svg/RemoveImageIcon';

export const SubmitIdeaModal: FC<{ close: () => void }> = ({ close }) => {
    return (
        <Portal>
            <SubmitIdeaOrLogin close={close} />
        </Portal>
    );
};

type ModalState = 'loading' | 'loginRegister' | 'sendIdea' | 'thanks';

export const SubmitIdeaOrLogin: FC<{ close: () => void }> = ({ close }) => {
    // State may loook like it could be derived from loginState, but we
    // don't want it to change to 'sendIdea' the second the user us logged
    // in. Instead we want users to be able to finish the dialog (and
    // register user info etc.)
    const [state, setState] = useState<ModalState>('loading');
    const loginState = useLoginState();

    useEffect(() => {
        if (!loginState.isLoading) {
            if (loginState.isLoggedIn) {
                setState('sendIdea');
            } else {
                setState('loginRegister');
            }
        }
    }, [loginState.isLoading, loginState.isLoggedIn]);

    return (
        <Modal close={close}>
            {state === 'loading' ? (
                'Loading...'
            ) : state === 'loginRegister' ? (
                <LoginOrRegister doClose={() => setState('sendIdea')} />
            ) : state === 'sendIdea' ? (
                <SubmitIdea setState={setState} />
            ) : (
                <Thanks close={close} />
            )}
        </Modal>
    );
};

async function addIdeaMutation({ content, images }: { content: string; images: string[] }) {
    await npBackendApi.post(`/api/v1/community/idea`, {
        content,
        images,
    });
}

export const Thanks: FC<{ close: () => void }> = ({ close }) => {
    return (
        <>
            <h1>
                <I18n>pitchIdeaThanksHeader</I18n>
            </h1>
            <div
                css={`
                    margin-bottom: ${space(9)};
                    max-width: 923px;
                `}
            >
                <I18n>pitchIdeaThanks</I18n>
            </div>
        </>
    );
};

export const SubmitIdea: FC<{ setState: (state: ModalState) => void }> = ({ setState }) => {
    const [content, setContent] = useState('');
    const { t } = useI18n();
    const fileUploadRef = useRef<HTMLInputElement>(null);
    const [images, setImages] = useState<string[]>([]);

    const contentError = content.length < 2 && t('pitchIdeaContentRequired');

    const hasErrors = contentError;

    let { mutate, isLoading, error } = useMutation(addIdeaMutation, {
        onError: error => {
            Sentry.captureException(error);
        },
        onSuccess: () => {
            setState('thanks');
        },
    });

    return (
        <>
            <h1>
                <I18n>pitchIdeaHeader</I18n>
            </h1>
            <div
                css={`
                    color: #737373;
                    margin-bottom: ${space(9)};
                    max-width: 923px;
                `}
            >
                <I18n>pitchIdeaIntro</I18n>
            </div>

            <input
                type="file"
                multiple
                ref={fileUploadRef}
                css={`
                    opacity: 0;
                    height: 0px;
                    width: 0px;
                `}
                onChange={ev => {
                    Array.from(ev.target.files || []).forEach(file => {
                        const target = ev.target;
                        if (!file.type.startsWith('image/')) {
                            alert('We only support images');
                            return;
                        }
                        const reader = new FileReader();
                        reader.onload = e => {
                            if (e.target?.result) {
                                let result = e.target.result;
                                setImages(old => [...old, result as string]);
                                target.value = '';
                            }
                        };
                        reader.onerror = err => {
                            console.error(err);
                        };
                        reader.readAsDataURL(file);
                    });
                }}
            />

            <Form
                error={error as Error}
                onSubmit={() => {
                    if (!hasErrors) {
                        void mutate({ content, images });
                    }
                }}
            >
                <Field label={t('pitchIdeaLabel')} error={contentError}>
                    <div
                        css={`
                            border: 2px solid #d6d6d6;
                            border-radius: 3px;
                        `}
                    >
                        <textarea
                            css={`
                                && {
                                    border: none;
                                    height: 125px;

                                    &:focus {
                                        border: none;
                                        outline: none;
                                    }
                                }
                            `}
                            placeholder={t('pitchIdeaPlaceholder')}
                            value={content}
                            onChange={ev => setContent(ev.target.value)}
                        />
                        <div
                            css={`
                                display: flex;
                                flex-wrap: wrap;
                                align-items: flex-start;
                            `}
                        >
                            {images.map((src, idx) => (
                                <div
                                    key={src}
                                    css={`
                                        position: relative;
                                    `}
                                >
                                    <img
                                        src={src}
                                        css={`
                                            display: block;
                                            height: 64px;
                                            margin-left: ${space(2)};
                                            margin-bottom: ${space(2)};
                                            border-radius: 3px;
                                        `}
                                    />
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setImages(prevImages => {
                                                let newImages = [...prevImages];
                                                newImages.splice(idx, 1);
                                                return newImages;
                                            });
                                        }}
                                        css={`
                                            ${buttonReset};
                                            position: absolute;
                                            right: -8px;
                                            top: -8px;
                                        `}
                                    >
                                        <RemoveImageIcon />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </Field>
                <Buttons>
                    <Button
                        type="button"
                        secondary
                        css={`
                            height: 32px;
                            display: flex;
                            align-items: center;
                        `}
                        onClick={ev => {
                            ev.preventDefault();
                            if (fileUploadRef.current) {
                                fileUploadRef.current.click();
                            }
                        }}
                    >
                        <PaperClipIcon />
                    </Button>
                    <Button disabled={isLoading}>
                        {isLoading ? t('loading') : t('pitchIdeaSubmit')}
                    </Button>
                </Buttons>
            </Form>
        </>
    );
};
