import React from 'react';

export function SearchIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="none"
            viewBox="0 0 22 22"
        >
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M12.126 16.096c3.804-1.62 5.581-6.021 3.963-9.837-1.62-3.806-6.02-5.584-9.824-3.965a7.5 7.5 0 00-3.972 9.837 7.499 7.499 0 009.833 3.965zM14.489 14.496l5.813 5.817"
            ></path>
        </svg>
    );
}
