import React from 'react';

export const OpenedLock: React.FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="15"
            fill="none"
            viewBox="0 0 14 15"
        >
            <path
                fill="currentcolor"
                fillRule="evenodd"
                d="M7 3.661v2.71h5.107c.77 0 1.393.607 1.393 1.355v5.42c0 .747-.623 1.354-1.393 1.354H3.75c-.769 0-1.393-.607-1.393-1.355v-5.42c0-.747.624-1.354 1.393-1.354H5.7v-2.71c0-1.047-.873-1.896-1.95-1.896S1.8 2.614 1.8 3.66v1.265H.5V3.66C.5 1.915 1.955.5 3.75.5S7 1.915 7 3.661zm-3.25 9.484v-5.42h8.357v5.42H3.75zm4.874-2.71a.687.687 0 01-.696.678.687.687 0 01-.697-.678c0-.374.312-.677.697-.677.384 0 .696.303.696.677z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
};
