import { createCentraAxiosApi, useCentraApi } from './useCentraApi';
import { ICentraSelectionProduct, ICentraSelectionProductItem } from '../interfaces';
import { mapRemoteData, RemoteData } from './remoteData';
import { useQuery, UseQueryResult } from 'react-query';
import { createGroupMap } from '../lib/utils';
import { AxiosError } from 'axios';
import { useNPContext } from '../npContext';
export { useCentraApi } from './useCentraApi';

export type LiveVariants = ICentraSelectionProduct[];

export function useLiveVariants(variantIds: string[]): RemoteData<LiveVariants> {
    const [result] = useCentraApi<{ products: LiveVariants }>({
        url: 'products',
        data: { products: variantIds },
        method: 'POST',
    });

    if (result.isLoading) {
        return result;
    }

    if (result.error) {
        return result;
    }

    return {
        ...result,
        data: result.data.products,
    };
}

type AllLiveVariants = {
    variantsPerCentraProduct: Record<string, ICentraSelectionProduct[]>;
    variantForCentraItem: Record<string, ItemWithVariant>;
};

export type ItemWithVariant = ICentraSelectionProductItem & {
    variant: ICentraSelectionProduct;
    stock: number | 'infinite';
    sku: string;
    name: string;
    ean: string;
};

async function getAllLiveVariants({ queryKey }: { queryKey: string[] }): Promise<AllLiveVariants> {
    let centraApi = createCentraAxiosApi(queryKey[1]);

    let res = await centraApi.post<{ products: LiveVariants }>('products');
    let variantsPerCentraProduct = createGroupMap(
        res.data.products.map(variant => [variant.centraProduct, variant])
    );
    let variantForCentraItem: Record<string, ItemWithVariant> = {};
    res.data.products.forEach(variant => {
        variant.items.forEach(item => {
            variantForCentraItem[item.item] = { ...item, variant };
        });
    });

    createGroupMap(res.data.products.map(variant => [variant.centraProduct, variant]));

    return { variantsPerCentraProduct, variantForCentraItem };
}

function useAllLiveVariants(): UseQueryResult<AllLiveVariants, AxiosError> {
    let context = useNPContext();
    const apiToken = context.centraApiToken;

    return useQuery(['all_live_variants', apiToken], getAllLiveVariants, {
        staleTime: 10000,
    });
}

export function useVariantsForCentraItem(): RemoteData<Record<string, ItemWithVariant>> {
    let { data: allVariants, error, isLoading } = useAllLiveVariants();

    if (error) {
        return { isLoading: false, error, data: undefined };
    }
    if (isLoading || !allVariants) {
        return { isLoading: true, error: undefined, data: undefined };
    }

    return { isLoading: false, error: undefined, data: allVariants.variantForCentraItem };
}

export function useCentraItemVariant(itemId?: string): RemoteData<ItemWithVariant | null> {
    let { data: allVariants, error, isLoading } = useAllLiveVariants();

    if (!itemId) {
        return { isLoading: false, error: undefined, data: null };
    }

    if (error) {
        return { isLoading: false, error, data: undefined };
    }
    if (isLoading || !allVariants) {
        return { isLoading: true, error: undefined, data: undefined };
    }

    let centraItem = allVariants.variantForCentraItem[itemId];

    if (!centraItem) {
        return {
            isLoading: false,
            error: new Error(`No such product: ${itemId}`),
            data: undefined,
        };
    }

    return { isLoading: false, error: undefined, data: centraItem };
}

export function useProductLiveVariants(
    centraProduct?: string
): RemoteData<ICentraSelectionProduct[]> {
    let { data: allVariants, error, isLoading } = useAllLiveVariants();

    if (!centraProduct) return { data: [], error: undefined, isLoading: false };

    if (error) {
        return { isLoading: false, error, data: undefined };
    }
    if (isLoading || !allVariants) {
        return { isLoading: true, error: undefined, data: undefined };
    }

    let variants = allVariants.variantsPerCentraProduct[centraProduct];

    if (!variants) {
        return { isLoading: false, error: new Error('No such product'), data: undefined };
    }

    return { isLoading: false, error: undefined, data: variants };
}

export function useProductPrice(centraProduct?: string): RemoteData<string | undefined> {
    let liveVariants = useProductLiveVariants(centraProduct);

    return mapRemoteData(liveVariants, variants => {
        let prices = new Set(variants.map(v => v.price?.replace('.00', '')));

        if (prices.size === 1) {
            return Array.from(prices)[0];
        } else {
            return undefined;
        }
    });
}
