import React from 'react';
import { fontSize } from './styles';
import { desktopMedia } from '../templates/productPage/constants';

type TableCell = {
    cells: string[];
    _type: string;
    _key: string;
};

type TableData = {
    rows: TableCell[];
    _type: string;
    _key: string;
};

export interface RenderTableProps {
    tableData: TableData;
}

const TableRenderer: React.FC<RenderTableProps> = ({ tableData }) => {
    const headerRow = tableData.rows[0];
    const bodyRows = tableData.rows.slice(1);

    return (
        <div>
            <table
                css={`
                    border-collapse: collapse;
                    table-layout: auto;
                    width: 100%;

                    font-size: 14px;
                    line-height: 25px;

                    td,
                    th {
                        border: 1px solid #d6d6d6;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        padding-left: 7px;
                        padding-right: 7px;
                        text-align: center;
                    }
                    th {
                        background: #f5f5f5;
                        border-top: none;
                    }
                    td:nth-child(1) {
                        text-align: left;
                        font-size: 16px;
                    }
                    th:nth-child(1) {
                        text-align: left;
                    }

                    @media ${desktopMedia} {
                        font-size: 16px;

                        th,
                        td:nth-child(1) {
                            font-size: 18px;
                        }
                    }
                `}
            >
                <colgroup>
                    <col css={``} />
                </colgroup>
                <thead>
                    <tr>
                        {headerRow.cells.map((cell, cellIndex) => (
                            <th key={cellIndex}>{cell}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {bodyRows.map((row, rowIndex) => (
                        <tr key={row._key || rowIndex + 1}>
                            {row.cells.map((cell, cellIndex) => (
                                <td key={cellIndex}>{cell}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TableRenderer;
