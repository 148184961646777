import Client from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';

export const sanity = Client({
    apiVersion: 'v2021-10-21',
    projectId: 'u9feora4',
    dataset: process.env.GATSBY_SANITY_DATASET || 'production',
    useCdn: true,
});

export const sanityWithCredentials = Client({
    apiVersion: 'v1',
    projectId: 'u9feora4',
    dataset: process.env.GATSBY_SANITY_DATASET || 'production',
    useCdn: false,
    withCredentials: true,
});

const urlBuilderFactory = imageUrlBuilder(sanity);

export function urlFor(image: SanityImageSource): ImageUrlBuilder {
    return urlBuilderFactory.image(image).auto('format');
}

export const srcSet = (sizes: [number, number?][], img: SanityImageSource) => {
    return sizes
        .map(([width, height]) => {
            let image = urlFor(img).width(width);
            if (height) {
                image = image.height(height);
            }
            return `${image.url()} ${width}w`;
        })
        .join(',\n');
};

export const srcSetRatio = (widths: number[], ratio: number, img: SanityImageSource): string => {
    return widths
        .map(width => {
            let height = Math.round(width * ratio);
            let image = urlFor(img).width(width).height(height);
            // return `https://place-hold.it/${width}x${height} ${width}w`;
            return `${image.url()} ${width}w`;
        })
        .join(',\n');
};
