/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { navigate } from 'gatsby';
import { default as React, useEffect, useRef, useState } from 'react';
import constants from '../../constants';
import { ILayoutData, TPageTable } from '../../interfaces';
import ShoppingCart from '../shared/cart/shoppingCart';
import { GlobalStyle, zIndex, buttonReset, space, fontSize } from '../styles';
import Footer from './footer';
import { Header, HeroData } from './headers';
import { useNPContext } from '../../npContext';
import { LanguageProvider } from '../../i18n';
import { ShoppingCartProvider } from './cart/ShoppingCartContext';
import '../../fonts.css';
import { useBasketCount } from './navigation/useBasketCount';
import MenuCart from './navigation/menuCart';
import { useLocation } from '@reach/router';
import { usePreorderStock } from '../../api/np-backend/preorderStock';
import { OvershootDayPopup } from '../OvershootDayPopup';

// ensure that the selected language that a user may have stored
// is used on the current page. We perform a redirect if the current page language
// does not match the stored language set previously by the user
const WithLanguageRedirect: React.FC<{
    pageLanguage: string;
    pageTable: TPageTable;
    savedLanguage: string | null;
    children: React.ReactNode;
}> = ({ pageLanguage, pageTable, savedLanguage, children }) => {
    const location = useLocation();
    const redirectUrl =
        savedLanguage && savedLanguage !== pageLanguage ? pageTable[savedLanguage] : null;

    if (redirectUrl) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigate(`${redirectUrl}${location.hash}`);
    }

    return redirectUrl ? <></> : <React.Fragment>{children}</React.Fragment>;
};

const Layout: React.FC<{
    layoutData: ILayoutData;
    pageTable: TPageTable;
    hero?: HeroData;
    enableBackButton?: boolean;
    disableCart?: boolean;
    disableFooter?: boolean;
    disableAbandonmentPopup?: boolean;
    children: React.ReactNode;
}> = ({
    layoutData: { language, overshootDaySplash },
    pageTable,
    hero,
    children,
    enableBackButton = false,
    disableCart = false,
    disableFooter = false,
    disableAbandonmentPopup = false,
}) => {
    const [openState, setOpen] = useState<boolean>(false);
    const context = useNPContext();

    // Ensure that preorder stock data is loaded as soon as possible
    usePreorderStock();

    const toggleOpen = () => {
        setOpen(!openState);
    };

    return (
        <LanguageProvider value={language}>
            <ShoppingCartProvider value={{ toggleOpen }}>
                <GlobalStyle />
                {!disableAbandonmentPopup && <MouseLeavePopup />}
                <WithLanguageRedirect
                    pageLanguage={language}
                    savedLanguage={context?.state?.userPreferences?.language}
                    pageTable={pageTable}
                >
                    <Header hero={hero} enableBackButton={enableBackButton} language={language} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>
                            <OvershootDayPopup data={overshootDaySplash} />
                            <main style={{ width: '100%' }}>{children}</main>
                            {!disableCart && (
                                <ShoppingCart
                                    language={language}
                                    isOpen={openState}
                                    context={context}
                                />
                            )}
                        </div>
                        {!disableFooter ? <Footer /> : null}
                    </div>
                </WithLanguageRedirect>
            </ShoppingCartProvider>
        </LanguageProvider>
    );
};

export default Layout;

export const MouseLeavePopup: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);
    const productCount = useBasketCount();
    let timeout = useRef<null | NodeJS.Timeout>(null);

    useEffect(() => {
        function onMouseLeave() {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
            timeout.current = setTimeout(() => {
                setIsVisible(true);
            }, 500);
        }

        function onMouseEnter() {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
            setIsVisible(false);
        }

        window.document.addEventListener('mouseleave', onMouseLeave, { passive: true });
        window.document.addEventListener('mouseenter', onMouseEnter, { passive: true });

        return () => {
            window.document.removeEventListener('mouseleave', onMouseLeave);
            window.document.removeEventListener('mouseenter', onMouseEnter);
            if (timeout.current) {
                clearTimeout(timeout.current);
                timeout.current = null;
            }
        };
    });

    if (!isVisible || productCount === 0) {
        return null;
    }

    return (
        <div
            css={`
                box-sizing: border-box;
                width: 100%;
                ${buttonReset};
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 0 ${space(3)};
                z-index: ${zIndex.popup};
                background: ${constants.colors.pallet.error};
                color: #fff;

                /* This is not an important button for people using keyboard
                   so we can do this safely */
                &:focus {
                    outline: 0;
                }

                svg {
                    margin-left: ${space(2)};
                }
            `}
        >
            Glemte du igjen denne?
            <MenuCart />
        </div>
    );
};
