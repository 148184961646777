import React from 'react';

export const CloseHamburger: React.FC = () => {
    return (
        <svg
            aria-hidden
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
        >
            <path stroke="#000" strokeWidth="1.5" d="M1 1l16 16M1 17L17 1"></path>
        </svg>
    );
};
