import {
    useState,
    RefObject,
    useEffect,
    useRef,
    useLayoutEffect,
    Dispatch,
    SetStateAction,
    useCallback,
} from 'react';
import useMeasure_ from 'react-use-measure';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import qs from 'qs';

export function useQueryParam(
    key: string
): [string[] | undefined, Dispatch<SetStateAction<string[] | undefined>>] {
    const hasReadQueryParams = useRef(false);
    const [value, setValue] = useState<string[] | undefined>(undefined);

    useEffect(() => {
        // We need to do this in a useEffect because of Server Side Rendering
        let query =
            typeof window !== 'undefined'
                ? qs.parse(location.search, { ignoreQueryPrefix: true })
                : {};
        let newValue = query[key];
        if (newValue && typeof newValue === 'string') {
            setValue([newValue]);
        } else if (newValue && Array.isArray(newValue)) {
            setValue(newValue as string[]);
        }
        hasReadQueryParams.current = true;
    }, [key]);

    useEffect(() => {
        // Note that we are not using navigate and location from @reach/router (which is gatby's router)
        // instead we are using replaceState and window.location directly. This is because we don't
        // actually want the router to change anything, we just want to change the URL.
        // (the initial page load needs to be without filterse because of SSR)

        // Don't try to update if we have not read the value yet. This means we don't clear the query
        // on each page load
        if (!hasReadQueryParams.current) {
            return;
        }
        let oldQuery = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        let query = {
            ...oldQuery,
            [key]: value,
        };
        if (Object.values(query).filter(Boolean).length === 0) {
            window.history.replaceState({}, '', ``);
        } else {
            window.history.replaceState(
                {},
                '',
                `?${qs.stringify(query, { arrayFormat: 'repeat' })}`
            );
        }
    }, [key, value]);

    return [value, setValue];
}

// If window is undefined (ssr) use a empty class (see implementation of react-use-measure, if
// window is something use window.ResizeObserver (if set) or polyfill)
const ResizeObserver: typeof ResizeObserverPolyfill =
    typeof window === 'undefined'
        ? class ResizeObserver {}
        : (window.ResizeObserver as any) || ResizeObserverPolyfill;

export function useMeasure() {
    return useMeasure_({ polyfill: ResizeObserver });
}

// https://stackoverflow.com/questions/16302483/event-to-detect-when-positionsticky-is-triggered
// To make this work, the element needs `top: -1px` (can be compensating by adding 1 to padding-top)
export function useIsSticky(ref: RefObject<HTMLElement | null>): boolean {
    const [isSticky, setIsSticky] = useState(false);
    // mount
    useEffect(() => {
        if (!window.IntersectionObserver) {
            return;
        }

        if (ref.current) {
            const cachedRef = ref.current;
            const observer = new IntersectionObserver(
                ([e]) => setIsSticky(e.intersectionRatio < 1),
                {
                    threshold: [1],
                }
            );

            observer.observe(cachedRef);

            // unmount
            return function () {
                observer.unobserve(cachedRef);
            };
        }
    }, [ref]);

    return isSticky;
}

// https://usehooks.com/useOnScreen/
export function useOnScreen(
    ref: RefObject<HTMLElement | undefined>,
    rootMargin = '0px',
    threshold = 0
): [boolean | null, number | null] {
    // State and setter for storing whether element is visible

    const [isIntersecting, setIntersecting] = useState<boolean | null>(null);
    const [direction, setDirection] = useState<number | null>(null);
    const lastY = useRef<number | null>(null);

    useEffect(() => {
        // For browsers that does not support IntersectionObserver, useOnScreen will always return `null`.
        // This is okay, because we only use IntersectionObserver on desktop, and all desktop-browsers
        // support it
        if (!window.IntersectionObserver) {
            return;
        }

        const observer = new window.IntersectionObserver(
            ([entry]) => {
                if (lastY.current) {
                    if (lastY.current > entry.boundingClientRect.y) {
                        setDirection(1);
                    } else {
                        setDirection(-1);
                    }
                }
                lastY.current = entry.boundingClientRect.y;
                // Update our state when observer callback fires
                setIntersecting(entry.isIntersecting);
            },

            {
                rootMargin,
                threshold,
            }
        );

        let currentRef = ref.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [rootMargin, threshold]); // Empty array ensures that effect is only run on mount and unmount

    return [isIntersecting, direction];
}

// https://codesandbox.io/embed/lp8-1n9z7v9
// Returns the "previous" value for a prop
// Had to add "defaultValue" for this to work with SSR (gatsby build)
export function usePrevious<T>(value: T, defaultValue: T): T | undefined {
    const ref = useRef<T>(defaultValue);
    useEffect(() => void (ref.current = value), [value]);
    return ref.current;
}

// https://usehooks.com/useMedia/
export function useMedia<T>(queries: string[], values: T[], defaultValue: T): T {
    // Array containing a media query list for each query
    const mediaQueryLists =
        typeof window !== 'undefined' ? queries.map(q => window.matchMedia(q)) : [];

    // Function that gets value based on matching media query
    const getValue = () => {
        // Get index of first media query that matches
        const index = mediaQueryLists.findIndex(mql => mql.matches);
        // Return related value or defaultValue if none
        return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
    };

    // State and setter for matched value
    const [value, setValue] = useState(getValue);

    useEffect(() => {
        // Event listener callback
        // Note: By defining getValue outside of useEffect we ensure that it has ...
        // ... current values of hook args (as this hook callback is created once on mount).
        const handler = () => setValue(getValue);
        // Set a listener for each media query with above handler as callback.
        mediaQueryLists.forEach(mql => mql.addListener(handler));
        // Remove listeners on cleanup
        return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
    }, []); // Empty array ensures effect is only run on mount and unmount

    return value;
}

// https://usehooks.com/useLockBodyScroll/
export function useLockBodyScroll(enable = true): void {
    useLayoutEffect(() => {
        if (enable) {
            // Get original body overflow
            const originalStyle = window.getComputedStyle(document.body).overflow;

            // Prevent scrolling on mount
            document.body.style.overflow = 'hidden';

            // Re-enable scrolling when component unmounts
            return () => {
                document.body.style.overflow = originalStyle;
            };
        }
    }, [enable]); // Empty array ensures effect is only run on mount and unmount
}

export function useIsScrollingUp(): [boolean, () => void] {
    const [isScrollingUp, setIsScrollingUp] = useState(false);

    const extremePosition = useRef(0);
    const paused = useRef(false);

    const onScroll = useCallback(() => {
        if (paused.current) {
            return;
        }

        if (!isScrollingUp) {
            if (extremePosition.current - window.scrollY > 100) {
                setIsScrollingUp(true);
                extremePosition.current = window.scrollY;
            }
            if (window.scrollY > extremePosition.current) {
                extremePosition.current = window.scrollY;
            }
        } else {
            if (window.scrollY < 100) {
                setIsScrollingUp(false);
            } else if (window.scrollY - extremePosition.current > 100) {
                setIsScrollingUp(false);
            }
            if (window.scrollY < extremePosition.current) {
                extremePosition.current = window.scrollY;
            }
        }
    }, [isScrollingUp]);

    useEffect(() => {
        window.document.addEventListener('scroll', onScroll, { passive: true });

        return () => {
            window.document.removeEventListener('scroll', onScroll);
        };
    }, [onScroll]);

    const pause = () => {
        setIsScrollingUp(false);
        extremePosition.current = 0;
        // Don't make the auto-scrolling (window.scroll(...)) set isScrollingUp
        paused.current = true;
        setTimeout(() => {
            paused.current = false;
        }, 1000);
    };

    return [isScrollingUp, pause];
}
