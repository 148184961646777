import React, { AnchorHTMLAttributes } from 'react';
import { Link } from 'gatsby';

const ExternalLink: React.FC<
    { address?: string; text: string; openInNewTab: boolean } & AnchorHTMLAttributes<
        HTMLAnchorElement
    >
> = ({ address, text, openInNewTab, ...props }) => {
    // To discern between path or external link,
    // which will be opened in new tab

    if (!address) {
        address = '';
    }

    const hasHttp = address.indexOf('http://') === 0;
    const hasHttps = address.indexOf('https://') === 0;
    const isMailto = address.indexOf('mailto:') === 0;
    const hasNPHostname = !isMailto && address.indexOf('northernplayground.no') !== -1;
    // String.prototype.indexOf for ie11 compatibility,
    // String.prototype.startsWith not supported

    const isURL = hasHttp || hasHttps || isMailto;
    const isExternalAddress = isURL && !hasNPHostname;

    const target: '_blank' | undefined = openInNewTab ? '_blank' : undefined;

    return isExternalAddress ? (
        <a target={target} rel="noopener" href={address} {...props}>
            {text}
        </a>
    ) : (
        <Link to={address.replace(/https?:\/\/(www.)?northernplayground.no/, '')} {...props}>
            {text}
        </Link>
    );
};

export default ExternalLink;
