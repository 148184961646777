import React from 'react';

export const Cart: React.FC = () => {
    return (
        <svg
            width="18"
            height="24"
            viewBox="0 0 16 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.75 7.75H15.25V18C15.25 19.7949 13.7949 21.25 12 21.25H4C2.20507 21.25 0.75 19.7949 0.75 18V7.75Z"
                stroke="currentcolor"
                strokeWidth="1.5"
            />
            <path
                d="M5 13V4.49263C5 3.75318 5.2731 3.03977 5.76687 2.48934V2.48934C6.95905 1.16039 9.04095 1.16039 10.2331 2.48934V2.48934C10.7269 3.03977 11 3.75318 11 4.49263V13"
                stroke="currentcolor"
                strokeWidth="1.5"
            />
        </svg>
    );
};
