import React, { FC, useContext } from 'react';
import { css } from 'styled-components';
import constants from '../../constants';
import { I18n } from '../../i18n';
import { filledButton, fontSize, fontStyle, secondaryButton, space } from '../styles';

const FormContext = React.createContext<{
    submitAttempted: boolean;
} | null>(null);

export const Form: FC<{ onSubmit: () => void; error?: Error | false; children: React.ReactNode }> =
    ({ children, onSubmit, error }) => {
        const [submitAttempted, setSubmitAttempted] = React.useState(false);

        return (
            <FormContext.Provider value={{ submitAttempted }}>
                <form
                    onSubmit={ev => {
                        ev.preventDefault();
                        setSubmitAttempted(true);
                        onSubmit();
                    }}
                    method="post"
                    css={`
                        max-width: ${space(78)};
                        margin: 0 auto;
                        display: flex;
                        flex-direction: column;
                        align-items: stretch;
                    `}
                >
                    {error && (
                        <div
                            role="alert"
                            css={`
                                padding: ${space(1)} ${space(2)};
                                background: ${constants.colors.pallet.error};
                                color: white;
                                border-radius: 5px;
                                max-width: 605px;
                                margin: ${space(2)} 0;
                            `}
                        >
                            <I18n>{error.message || error.toString()}</I18n>
                        </div>
                    )}
                    {children}
                </form>
            </FormContext.Provider>
        );
    };

export const Field: FC<{ label: string; error?: string | false; children: React.ReactNode }> = ({
    label,
    error,
    children,
}) => {
    let context = useContext(FormContext);

    if (!context) {
        throw new Error('Field needs to be inside a Form');
    }
    let { submitAttempted } = context;
    let showError = error && submitAttempted;

    return (
        <label
            css={`
                display: block;
                ${fontStyle.label};
                margin-bottom: ${space(4)};

                input,
                textarea {
                    display: block;
                    width: 100%;
                    border: 2px solid #d6d6d6;
                    border-radius: 3px;
                    padding: ${space(1)} ${space(2)};
                    ${fontSize.regular};

                    ${showError &&
                    css`
                        border-color: ${constants.colors.pallet.error};
                    `}
                }
            `}
        >
            {label}
            {children}
            {showError && (
                <div
                    role="alert"
                    css={`
                        color: ${constants.colors.pallet.error};
                    `}
                >
                    {error}
                </div>
            )}
        </label>
    );
};

export const CheckBox: FC<{
    label: string;
    value: boolean;
    onChange: (checked: boolean) => void;
}> = ({ label, value, onChange }) => {
    return (
        <label
            css={`
                margin-bottom: ${space(4)};
            `}
        >
            <input
                type="checkbox"
                checked={value}
                onChange={ev => onChange(ev.target.checked)}
                css={`
                    margin-right: ${space(1)};
                `}
            />
            {label}
        </label>
    );
};

const RadioButtonsContext = React.createContext<{
    name: string;
    value: string;
    onChange: (value: string) => void;
} | null>(null);

export const RadioButtons: FC<{
    label: string;
    name: string;
    value: string;
    onChange: (value: string) => void;
    children: React.ReactNode;
}> = ({ label, children, name, onChange, value }) => {
    return (
        <RadioButtonsContext.Provider value={{ name, onChange, value }}>
            <div>
                <div
                    css={`
                        margin-bottom: ${space(1)};
                    `}
                >
                    {label}
                </div>
                <div
                    css={`
                        display: flex;
                        margin-bottom: ${space(4)};
                    `}
                >
                    {children}
                </div>
            </div>
        </RadioButtonsContext.Provider>
    );
};

export const RadioButton: FC<{
    label: string;
    value: string;
}> = ({ label, value }) => {
    let context = React.useContext(RadioButtonsContext);
    if (!context) {
        throw new Error('RadioButton needs to be inside a RadioButtons');
    }
    let checked = context.value === value;

    return (
        <label
            css={`
                display: block;
                ${checked ? filledButton : secondaryButton};
                margin-right: ${space(2)};
            `}
        >
            {label}
            <input
                css={`
                    /* Hide the input, we then style the label. Note that we still want the checkbox,
                    this is good accessability */
                    appearance: none;
                    opacity: 0;
                    position: absolute;
                    left: 9999px;
                `}
                type="radio"
                name={context.name}
                checked={checked}
                onChange={() => context!.onChange(value)}
            />
        </label>
    );
};

export const Buttons: FC<{
    className?: string;
    align?: 'end' | 'start';
    children: React.ReactNode;
}> = ({ children, className, align = 'end' }) => {
    return (
        <div
            className={className}
            css={`
                align-self: ${align};
                margin-bottom: ${space(2)};
                display: flex;
            `}
        >
            {children}
        </div>
    );
};

export const Button: FC<{ secondary?: boolean } & React.ButtonHTMLAttributes<HTMLButtonElement>> =
    ({ secondary, ...props }) => {
        return (
            <button
                {...props}
                css={`
                    ${secondary ? secondaryButton : filledButton};
                    margin-right: ${space(1)};
                `}
            />
        );
    };
