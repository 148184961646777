import React from 'react';
import styled from 'styled-components';
import constants from '../../constants';
import { I18n } from '../../i18n';
import { ICentraCheckoutSelection, ICentraSelectionTotals } from '../../interfaces';
import { space, weight } from '../styles';
import { LabeledProduct } from './cart/hooks';

const PriceDiv = styled.div`
    display: flex;
    justify-content: space-between;
    color: #737373;
`;

function getTaxDeductedPrice(selection: ICentraCheckoutSelection): string {
    return `${Math.round(selection.totals.taxDeductedAsNumber || 0)} ${selection.currency}`;
}

export function getTotalPrice(selection: ICentraCheckoutSelection): string {
    return Math.round(selection.totals.grandTotalPriceAsNumber) + ' ' + selection!.currency;
}
export function getDiscount(selection: ICentraCheckoutSelection): string {
    return Math.round(selection.totals.totalDiscountPriceAsNumber || 0) + ' ' + selection.currency;
}
export function getShipping(selection: ICentraCheckoutSelection): string {
    return selection.totals.shippingPriceAsNumber + ' ' + selection.currency;
}

export function calculateOrderPrices(
    labeledProducts: LabeledProduct[],
    totals?: ICentraSelectionTotals
): {
    regularProducts: number;
    discount: number;
    crowdfundingProducts: number;
    shipping: number;
    total: number;
    payNow: number;
} | null {
    if (!totals) {
        return null;
    }

    // This is a simplified version of the calculation done in the backend. This is only for
    // display purposes, it's not used to calculate what the user is actually is paying (that
    // calculation is done in the backend)

    // Calculate the total item price of the regular order (e.g. without crowdfunding)
    let regularProducts = labeledProducts
        .filter(item => item.productType !== 'crowdfunding')
        .map(item => item.product.priceEachAsNumber * item.product.quantity)
        .reduce((a, b) => a + b, 0);
    let crowdfundingProducts = labeledProducts
        .filter(item => item.productType === 'crowdfunding')
        .map(item => item.product.priceEachAsNumber * item.product.quantity)
        .reduce((a, b) => a + b, 0);

    // Calculate the tax percent removed from the total priced (used to deduct the correct
    // tax for international orders)
    const taxDeductedAsNumber =
        typeof totals.taxDeductedAsNumber === 'number' ? totals.taxDeductedAsNumber : 0;
    const itemPriceWithoutTax = totals.itemsTotalPriceAsNumber + taxDeductedAsNumber;
    const deductTax = totals.itemsTotalPriceAsNumber / itemPriceWithoutTax;

    const regularShipping = regularProducts > 0 ? totals.shippingPriceAsNumber : 0;

    const discount = totals.totalDiscountPriceAsNumber || 0;

    return {
        regularProducts,
        discount,
        crowdfundingProducts,
        payNow: Math.round(
            Math.max(
                regularShipping +
                    regularProducts / deductTax +
                    // Remove the discount for the whole order, because that is what we do on the backend
                    discount,
                0
            )
        ),
        shipping: totals.shippingPriceAsNumber,
        total: Math.round(totals?.grandTotalPriceAsNumber || 0),
    };
}

const Subtotal: React.FC<{
    labeledProducts: LabeledProduct[];
    selection: ICentraCheckoutSelection | undefined;
}> = ({ selection, labeledProducts }) => {
    const prices = calculateOrderPrices(labeledProducts, selection?.totals);
    if (!prices || !selection) {
        return <div />;
    }
    const { regularProducts, discount, crowdfundingProducts, payNow, shipping, total } = prices;
    const currency = selection?.currency || '';

    return (
        <div
            css={`
                border: 2px solid #f2f2f2;
                border-radius: 3px;
                padding: ${space(1)} ${space(2)};

                hr {
                    border: none;
                    border-top: 2px solid #f2f2f2;
                    margin: ${space(2)} -${space(2)};
                }
            `}
        >
            {regularProducts > 0 && (
                <PriceDiv>
                    <span>
                        {crowdfundingProducts > 0 ? (
                            <I18n>ordinaryProducts</I18n>
                        ) : (
                            <I18n>products</I18n>
                        )}
                    </span>
                    <span>
                        {regularProducts} {currency}
                    </span>
                </PriceDiv>
            )}
            {discount !== 0 ? (
                <PriceDiv style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>
                        <I18n>discount</I18n>
                    </span>
                    <span>
                        {discount} {currency}
                    </span>
                </PriceDiv>
            ) : null}
            {crowdfundingProducts !== 0 && (
                <PriceDiv
                    css={`
                        color: ${constants.colors.pallet.crowdfunding};
                    `}
                >
                    <span>
                        <I18n>reservedProducts</I18n>
                    </span>
                    <span>
                        {crowdfundingProducts} {currency}
                    </span>
                </PriceDiv>
            )}
            {selection.totals.taxDeducted ? (
                <PriceDiv style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>
                        <I18n>taxesDeducted</I18n>
                    </span>
                    <span>{getTaxDeductedPrice(selection)}</span>
                </PriceDiv>
            ) : null}

            <PriceDiv
                css={`
                    color: ${shipping === 0 ? '#D6D6D6' : '#737373'};
                `}
            >
                <span>
                    <I18n>shipping</I18n>
                </span>
                <span>
                    {shipping === 0 ? <I18n>freeShipping</I18n> : `${shipping} ${currency}`}
                </span>
            </PriceDiv>
            <hr />
            <PriceDiv>
                <span>
                    <span
                        css={`
                            ${crowdfundingProducts === 0 ? `color: black; ${weight.medium}` : ''}
                        `}
                    >
                        <I18n>total</I18n>
                    </span>{' '}
                    {!selection.totals.taxDeducted && (
                        <span>
                            <I18n>incMva</I18n>
                        </span>
                    )}
                </span>
                <span>
                    {total} {currency}
                </span>
            </PriceDiv>
            {crowdfundingProducts > 0 && (
                <PriceDiv
                    css={`
                        color: black;
                        ${weight.medium};
                    `}
                >
                    <span>
                        <I18n>chargedNow</I18n>
                    </span>
                    <span>
                        {payNow} {currency}
                    </span>
                </PriceDiv>
            )}
        </div>
    );
};

export default Subtotal;
