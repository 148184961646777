import { default as React, FC, useState, useEffect, useRef } from 'react';
import { css } from 'styled-components';
import { Link, graphql, useStaticQuery } from 'gatsby';
import MenuCart from './menuCart';
import { I18n, useLanguage } from '../../../i18n';
import { linkReset, space, zIndex } from '../../styles';
import { useLoginState } from '../../auth/LoginContext';
import { VisuallyHidden } from '@reach/visually-hidden';
import { useDefaultProfilePicture } from '../../../lib/useDefaultProfilePicture';
import { NewNavigationQuery, SanityNavbarItem } from '../../../../graphql-types';
import { NewHamburgerMenu } from '../../navigation/NewHamburgerMenu';
import { desktopMedia } from '../../../templates/shopPage/constants';
import { Smiley } from '../../../svg/Smiley';
import { SearchIcon } from '../../../svg/SearchIcon';
import { getCommunityPagePath } from '../../../helpers/get-community-path';

const MenuLogo: React.FC<{ className?: string }> = ({ className }) => {
    let language = useLanguage();
    return (
        <Link
            to={`/${language}`}
            className={className}
            css={`
                color: var(--hover-color, #2b2929);
                display: block;
            `}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '4px',
                }}
            >
                <img src="/img/rock.png" style={{ width: '32px' }} />
            </div>
        </Link>
    );
};

const ProfilePicture: React.FC<{ profilePicture: string | undefined; className?: string }> = ({
    profilePicture,
    className,
}) => {
    let language = useLanguage();

    return (
        <Link
            to={`/${language}/account`}
            css={`
                ${linkReset};
                display: flex;
                align-items: center;
                margin-left: 24px;
            `}
            className={className}
        >
            <img
                src={profilePicture}
                css={`
                    width: ${space(3.5)};
                    height: ${space(3.5)};
                    border-radius: 5px;
                `}
            />
            <VisuallyHidden>
                <I18n>myAccount</I18n>
            </VisuallyHidden>
        </Link>
    );
};

export const RouteLink: FC<{
    to: string;
    disabled: boolean;
    children: React.ReactNode;
}> = ({ children, to, disabled }) => {
    // This ensures that any internal link (intended for Gatsby)
    // will start with exactly one slash, and that anything else is external.
    //https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#relative-links
    const internal = /^\/(?!\/)/.test(to);

    if (!internal) {
        return (
            <a
                href={to}
                css={`
                    ${linkReset}
                    ${disabled &&
                    css`
                        color: #d6d6d6;
                        cursor: default;
                    `}

                    text-underline-offset: 8px;
                    ${!disabled &&
                    css`
                        &:hover {
                            text-decoration: underline;
                            }
                        }
                    `}
                `}
            >
                {children}
            </a>
        );
    }
    return (
        <Link
            to={to}
            activeClassName={'active'}
            activeStyle={{ textDecoration: `underline` }}
            partiallyActive={false}
            aria-current={disabled ? 'location' : 'page'}
            css={`
                ${linkReset}

                ${disabled &&
                css`
                    color: #d6d6d6;
                    cursor: default;
                `}

                text-underline-offset: 8px;
                ${!disabled &&
                css`
                    &:hover {
                        text-decoration: underline;
                    }
                `}
            `}
        >
            {children}
        </Link>
    );
};

const navbarHeight = space(12.5);

type DesktopNavbarProps = {
    items?: SanityNavbarItem[] | null;
};

const DesktopNavbar = ({ items }: DesktopNavbarProps) => {
    const [activeItem, setActiveItem] = useState<number | null>(null);
    const dialogRef = useRef<HTMLDialogElement>(null);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const lang = useLanguage();
    const { isLoggedIn } = useLoginState();

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (dialogRef.current) {
                if (event.key === 'Escape' && dialogRef.current.open) {
                    dialogRef.current.close();
                    setActiveItem(null);
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleMouseEnter = (index: number) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current); // Clear the timeout when mouse enters a new item
        }
        setActiveItem(index);
    };

    const handleMouseLeave = () => {
        // Wait before closing the dialog to allow the user to move the mouse to the dialog
        timeoutRef.current = setTimeout(() => {
            if (dialogRef.current && dialogRef.current.open) {
                dialogRef.current.close();
                setActiveItem(null);
            }
        }, 250);
    };

    return (
        <ul
            onMouseLeave={handleMouseLeave}
            css={`
                display: none;
                @media ${desktopMedia} {
                    display: flex;
                    flex-grow: 1;
                    flex-direction: row;
                    justify-content: space-between;
                    justify-items: stretch;
                    align-items: center;
                    max-width: 50vw;
                    height: 100%;
                }

                // reset ul style
                list-style-type: none;
                margin-block-start: 0px;
                margin-block-end: 0px;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                padding-inline-start: 0px;
            `}
        >
            {items?.map((item, index: number) => {
                return (
                    <li
                        key={index}
                        onMouseEnter={() => handleMouseEnter(index)}
                        css={`
                            text-underline-offset: 8px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100%;
                            flex-grow: 1;
                            cursor: default;
                            ${css`
                                &:hover {
                                    text-decoration: underline;
                                }
                            `}
                            order: ${item.priority || 0}
                        `}
                    >
                        {item.title?.[lang]}

                        {activeItem === index && (
                            <>
                                <div
                                    css={`
                                        position: fixed;
                                        top: 30vh;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                        background-color: rgba(0, 0, 0, 0.2);
                                    `}
                                    onMouseEnter={handleMouseLeave}
                                ></div>
                                <dialog
                                    open
                                    ref={dialogRef}
                                    onMouseEnter={() => {
                                        if (timeoutRef.current) {
                                            clearTimeout(timeoutRef.current); // Clear the timeout when mouse enters the dialog
                                        }
                                    }}
                                    css={`
                                        position: absolute;
                                        top: ${navbarHeight};
                                        min-height: 30vh;
                                        width: 100vw;
                                        max-width: 100vw;

                                        display: grid;
                                        grid-template-columns: repeat(5, minmax(0, 1fr));

                                        flex-direction: row;
                                        justify-content: center;
                                        align-items: stretch;

                                        border: none;
                                    `}
                                >
                                    <span></span>
                                    {item?.groups?.map(group => (
                                        <ul
                                            key={group?.title?.[lang]}
                                            css={`
                                                // reset ul style
                                                list-style-type: none;
                                                margin-block-start: 0px;
                                                margin-block-end: 0px;
                                                margin-inline-start: 0px;
                                                margin-inline-end: 0px;
                                                padding-inline-start: 0px;
                                            `}
                                        >
                                            <li
                                                css={`
                                                    font-weight: 600;
                                                    margin-bottom: 10px;
                                                `}
                                            >
                                                {group?.groupRoute?.[
                                                    lang === 'en' ? 'linkEn' : 'linkNb'
                                                ] ? (
                                                    <RouteLink
                                                        to={
                                                            group.groupRoute[
                                                                lang === 'en' ? 'linkEn' : 'linkNb'
                                                            ] || ''
                                                        }
                                                        disabled={false}
                                                    >
                                                        {group?.groupRoute?.title?.[lang] ||
                                                            group?.title?.[lang] ||
                                                            ''}
                                                    </RouteLink>
                                                ) : (
                                                    group?.title?.[lang] || ''
                                                )}
                                            </li>
                                            {group?.routes
                                                ?.filter(route => {
                                                    if (
                                                        isLoggedIn &&
                                                        route?.showTo == 'logged_out'
                                                    ) {
                                                        return false;
                                                    }
                                                    if (
                                                        !isLoggedIn &&
                                                        route?.showTo == 'logged_in'
                                                    ) {
                                                        return false;
                                                    }
                                                    return true;
                                                })
                                                .map((route, i) => (
                                                    <li
                                                        key={i}
                                                        css={`
                                                            font-style: normal;
                                                            font-weight: 500;
                                                            font-size: 19px;
                                                            line-height: 180%;
                                                            cursor: pointer;
                                                        `}
                                                    >
                                                        {route?.[
                                                            lang === 'en' ? 'linkEn' : 'linkNb'
                                                        ] && (
                                                            <RouteLink
                                                                to={
                                                                    route[
                                                                        lang === 'en'
                                                                            ? 'linkEn'
                                                                            : 'linkNb'
                                                                    ] || ''
                                                                }
                                                                disabled={false}
                                                            >
                                                                {route?.title?.[lang]}
                                                            </RouteLink>
                                                        )}
                                                    </li>
                                                ))}
                                        </ul>
                                    ))}
                                    <span></span>
                                </dialog>
                            </>
                        )}
                    </li>
                );
            })}
        </ul>
    );
};

const NewNavigation: FC = () => {
    const loginState = useLoginState();
    const lang = useLanguage();
    const defaultProfilePicture = useDefaultProfilePicture(loginState.user?.id || 0);
    const profilePicture =
        loginState.user &&
        (loginState.user.profilePicture
            ? `${process.env.GATSBY_IMAGE_HOST}${loginState.user.profilePicture}?w=50&h=50&fit=crop`
            : defaultProfilePicture);

    const data = useStaticQuery<NewNavigationQuery>(graphql`
        query NewNavigation {
            allSanityNavbarItem {
                nodes {
                    groups {
                        title {
                            nb
                            en
                        }
                        routes {
                            title {
                                en
                                nb
                            }
                            linkEn
                            linkNb
                            showTo
                        }
                        groupRoute {
                            linkEn
                            linkNb
                            title {
                                en
                                nb
                            }
                        }
                    }
                    title {
                        en
                        nb
                    }
                    priority
                }
            }
            allSanityCommunityPage {
                nodes {
                    id
                    title
                    useNewPage
                    language
                    page {
                        ... on SanityCategoryPage {
                            _id
                            title
                            slug {
                                current
                            }
                            language
                        }
                        ... on SanityPage {
                            _id
                            _type
                            slug {
                                current
                            }
                            language

                            categoryPage {
                                ... on SanityCategoryPage {
                                    _id
                                    title
                                    slug {
                                        current
                                    }
                                    language
                                }
                            }
                        }
                    }
                    loggedPage {
                        ... on SanityCategoryPage {
                            _id
                            title
                            slug {
                                current
                            }
                            language
                        }
                        ... on SanityPage {
                            _id
                            _type
                            slug {
                                current
                            }
                            language
                            categoryPage {
                                ... on SanityCategoryPage {
                                    _id
                                    title
                                    slug {
                                        current
                                    }
                                    language
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
    const items = data.allSanityNavbarItem?.nodes;

    const communityPage = data.allSanityCommunityPage?.nodes?.find(
        node => node.language === lang && node.useNewPage
    )?.page;

    const communityPath = getCommunityPagePath({
        language: lang,
        path: communityPage?.slug?.current,
        categoryPath:
            communityPage && 'categoryPage' in communityPage
                ? communityPage.categoryPage?.slug?.current
                : undefined,
    });

    return (
        <nav
            css={`
                box-sizing: border-box;

                *,
                *:before,
                *:after {
                    box-sizing: inherit;
                }

                display: flex;
                justify-content: space-between;
                align-items: center;
                position: sticky;
                top: -1px;
                z-index: ${zIndex.navBar};
                width: 100%;
                height: 60px;
                @media ${desktopMedia} {
                    height: ${navbarHeight};
                }
                padding: 0px 24px;
                /* Compensate for top: -1px by adding 1 */
                padding-top: 1px;

                background: white;
            `}
        >
            <MenuLogo
                css={`
                    order: 2;
                    @media ${desktopMedia} {
                        order: 0;
                    }
                `}
            />

            <NewHamburgerMenu
                css={`
                    order: 1;
                    display: block;
                    /* compensate for only 1 item on left side */
                    padding-right: 40px;
                    @media ${desktopMedia} {
                        order: 0;
                        display: none;
                    }
                `}
            />

            <DesktopNavbar items={items as unknown as any} />
            <span
                css={`
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    order: 3;
                `}
            >
                <Link
                    to={`/${lang}/search`}
                    css={`
                        display: flex;
                        align-items: center;
                    `}
                >
                    <SearchIcon />
                    <VisuallyHidden>Search</VisuallyHidden>
                </Link>
                {loginState.isLoggedIn ? (
                    <ProfilePicture profilePicture={profilePicture} />
                ) : (
                    <Link
                        to={communityPath}
                        css={`
                            ${linkReset};
                            display: flex;
                            align-items: center;
                            margin-left: 24px;
                        `}
                    >
                        <Smiley />
                        <VisuallyHidden>
                            <I18n>Community</I18n>
                        </VisuallyHidden>
                    </Link>
                )}
                <MenuCart
                    css={`
                        order: 4;
                        padding: 2px 1px 5px 4px;
                        margin-left: 24px;
                    `}
                />
            </span>
        </nav>
    );
};

export default NewNavigation;
