import axios from 'axios';

import * as Sentry from '@sentry/browser';
import { createUseDataApi } from './useDataApi';

export const npBackendApi = axios.create({
    baseURL: process.env.GATSBY_NORTHERN_PLAYGROUND_BACKEND,
    withCredentials: true,
});

npBackendApi.interceptors.response.use(
    response => response,
    error => {
        Sentry.captureException(error);
        return Promise.reject(error);
    }
);

export const useNpDataApi = createUseDataApi(npBackendApi);
