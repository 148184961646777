import React, { FC } from 'react';
import { I18n, LanguageProvider, useLanguage } from '../i18n';
import { Language } from '../interfaces';
import translate from '../translations';
import SEO from './seo';
import Layout from './shared/layout';
import { filledButton, font, GlobalStyle, linkReset, secondaryButton, space } from './styles';

// This one assumes it is inside the providers from provider.tsx
export const ErrorOrNotFoundPage: FC<{ reset?: () => void }> = ({ reset }) => {
    const language = useLanguage();

    return (
        <Layout layoutData={{ language }} pageTable={{}}>
            <SEO title={translate(language, 'errorPageHeader')} lang={language} />
            <ErrorOrNotFoundPageInner reset={reset} />
        </Layout>
    );
};

// This one does not assume any provider, but it does not have header and footer
export const BasicErrorOrNotFoundPage: FC<{ reset?: () => void }> = ({ reset }) => {
    const language = (navigator.languages
        ? window.navigator.languages.indexOf('no') !== -1
            ? 'nb'
            : 'en'
        : navigator.language && navigator.language.startsWith('en')
        ? 'en'
        : 'nb') as Language;

    return (
        <LanguageProvider value={language}>
            <GlobalStyle />
            <ErrorOrNotFoundPageInner reset={reset} />
        </LanguageProvider>
    );
};

export const ErrorOrNotFoundPageInner: FC<{ reset?: () => void }> = ({ reset }) => {
    let language = useLanguage();
    return (
        <div
            css={`
                max-width: 800px;
                margin: ${space(10)} auto ${space(30)};
                text-align: center;

                h1 {
                    margin: 0 0 ${space(3)};
                    ${font.serif};
                }

                div {
                    margin-bottom: ${space(4)};
                }

                a {
                    display: block;
                    margin-bottom: ${space(2)};
                }
            `}
        >
            <h1>
                <I18n>errorPageHeader</I18n>
            </h1>
            <div>
                <I18n>errorPageContent</I18n>
            </div>
            <div
                css={`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                `}
            >
                {reset && (
                    <button
                        css={`
                            ${filledButton};
                            margin-bottom: ${space(2)};
                        `}
                        onClick={reset}
                    >
                        <I18n>tryAgain</I18n>
                    </button>
                )}
                <a
                    /* Use a href to force a refresh in case that will clear up the error */
                    href={`/${language}/`}
                    css={`
                        ${linkReset};
                        ${filledButton};
                    `}
                >
                    <I18n>errorPageBackToFrontpage</I18n>
                </a>
                <a
                    /* Use a href to force a refresh in case that will clear up the error */
                    href={`/${language}/shop`}
                    css={`
                        ${linkReset};
                        ${secondaryButton};
                    `}
                >
                    <I18n>errorPageAllProducts</I18n>
                </a>
            </div>
        </div>
    );
};
