import React from 'react';

export default function NorwegianFlag() {
    return (
        <svg
            width="29"
            height="19"
            viewBox="0 0 29 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="29" height="19" fill="#AA3E3E" />
            <rect x="4" width="7" height="19" fill="white" />
            <rect y="13" width="7" height="29" transform="rotate(-90 0 13)" fill="white" />
            <rect x="6" width="3" height="19" fill="#385E96" />
            <rect y="11" width="3" height="29" transform="rotate(-90 0 11)" fill="#385E96" />
        </svg>
    );
}
