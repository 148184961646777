import React, { FC } from 'react';

export const RemoveImageIcon: FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <rect width="16" height="16" fill="#2B2929" rx="8"></rect>
            <path
                fill="#fff"
                d="M10.603 11.733l.832-.832L8.82 8.286l2.627-2.627-.832-.833-2.627 2.628-2.615-2.616-.832.832 2.615 2.616-2.627 2.627.832.832 2.627-2.627 2.615 2.615z"
            ></path>
        </svg>
    );
};
