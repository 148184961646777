import { GenderId } from '../gender';
import { SelectedImage } from '../templates/productPage/select-images';
import { Image } from '../lib/firstImage';

export function getImagesForGender(images: Image[], gender: GenderId): SelectedImage[] | null {
    let gImages = (images ?? []).filter(Boolean);

    let filteredImages = gImages.filter(
        image => image.__typename === 'SanityProductImage' && gender === image.gender
    );
    if (filteredImages.length == 0) return gImages;

    return filteredImages;
}
