import React from 'react';

export const PlusIcon: React.FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            fill="none"
            viewBox="0 0 10 10"
        >
            <path
                fill="currentcolor"
                d="M9.18 5.238v-1.26H5.22V0H3.96v3.978H0v1.26h3.96v3.978h1.26V5.238h3.96z"
            ></path>
        </svg>
    );
};
