import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { useStaticQuery, graphql } from 'gatsby';
import * as sanity from '../sanity-image';

export function useDefaultProfilePicture(
    userId: number,
    {
        width = 50,
        height = 50,
    }: {
        width?: number;
        height?: number;
    } = {}
): string {
    const defaultImages = useStaticQuery(graphql`
        query DefaultProfilePicturesQuery {
            allSanityDefaultProfilePicture {
                nodes {
                    image: _rawImage
                }
            }
        }
    `);

    let sheeps = defaultImages.allSanityDefaultProfilePicture.nodes.map(
        (node: { image: SanityImageSource }) => node.image
    );

    return (
        sanity
            .urlFor(sheeps[userId % sheeps.length])
            .width(width)
            .height(height)
            .url() || ''
    );
}
