import React from 'react';

export const BackArrowIcon: React.FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="13"
            fill="none"
            viewBox="0 0 16 13"
        >
            <path
                fill="#2B2929"
                d="M6.783 12.831L7.9 11.713 3.676 7.51h12.11V5.93H3.676L7.9 1.726 6.783.608.67 6.72l6.112 6.111z"
            ></path>
        </svg>
    );
};
