import { default as React } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import { lighten, normalize } from 'polished';
import constants from '../constants';

export const space = (size: number): string => `${size * 8}px`;

export const Spacer = styled.div<{ s: number }>`
    width: ${props => space(props.s)};
    height: ${props => space(props.s)};
`;

export const zIndex = {
    pageElement: 4,
    globalNavigation: 10,
    pageNavigation: 11,
    cartOverlay: 12,
    navBar: 13,
    cart: 13,
    popup: 20,
    modal: 30,
    sidebar: 31,
};

// (This is deprecated)
// In the design-grid (not the same as a css-grid) used in figma, each column is 80px wide (10*8),
// and the space between them is 24px
export const gridWidth = (size: number): string => `${size * 80 + (size - 1) * 24}px`;

export const font = {
    serif: css`
        font-family: Carrara, Georgia, serif;
        font-weight: 300;
    `,
};

export const fontSize = {
    small: css`
        font-size: 13px;
        line-height: 20px;
    `,
    regular: css`
        font-size: 19px;
        line-height: 25px;
        letter-spacing: 1%;
    `,
    medium: css`
        font-size: 21px;
        line-height: 32px;
    `,
    headinglarge: css`
        font-size: 40px;
        line-height: 50px;
        letter-spacing: 1%;
    `,
    large: css`
        font-size: 70px;
        line-height: 60px;
        letter-spacing: 1%;
    `,
    xlarge: css`
        font-size: 70px;
        line-height: 60px;
        letter-spacing: 1%;
    `,
    xxlarge: css`
        font-size: clamp(100px, 15vw, 200px);
        line-height: clamp(80px, 13vw, 180px);
        letter-spacing: 1%;
    `,
};

export const weight = {
    light: css`
        font-weight: 400;
    `,
    regular: css`
        font-weight: 400;
    `,
    medium: css`
        font-weight: 500;
    `,
    semiBold: css`
        font-weight: 600;
    `,
};

export const fontStyle = {
    label: css`
        font-size: 12px;
        ${weight.regular};
        letter-spacing: 5%;
        line-height: 24px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.03em;
    `,
};

export const GlobalStyle = createGlobalStyle`
    ${normalize()};

    body {
        font-family: "acumin-pro-condensed", FranklinGothic, Helvetica, Arial, sans-serif;
        font-weight: 500;
        ${fontSize.regular};
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    strong {
        font-weight: 700 !important;
    }

    button {
        cursor: pointer;
        font: inherit;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    button:disabled {
        cursor: default;
    }

    /* suppress focus ring on form controls for mouse users */
    [data-whatintent='mouse'] *:focus {
        outline: none;
    }

    h2 {
        ${fontSize.headinglarge};
        ${weight.regular};
    }
    h3 {
        ${weight.medium};
        ${fontSize.regular};
    }

    strong {
        ${weight.medium};
    }

    a {
        cursor: pointer;
        text-decoration: none;
        cursor: pointer;
        font: inherit;
        ${weight.medium};
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
            monospace;
    }

    fieldset {
        padding: 0;
        margin: 0;
        border: none;
    }

    /* styles.css */
    .aspect-ratio--16x9 {
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        position: relative;
    }
    .aspect-ratio__inner-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
`;

export const imgStyling = css`
    border-radius: 3px;
`;

export const buttonStyling = css`
    background: ${constants.colors.pallet.black};
    border-radius: 5px;
    border: none;
    text-decoration: none;
    width: 100%;
    height: 50px;
    border: 2px solid black;
    font-size: 16px;
    color: white;
    ${weight.medium};
    margin-top: 10px;
    @media (max-width: ${constants.breakpoints.miniTablet}) {
        width: 100%;
    }
    :disabled {
        cursor: default;
        border: 2px solid ${constants.colors.pallet.disabled};
        color: ${constants.colors.pallet.grey};
        background: ${constants.colors.pallet.white};
    }
`;

export const buttonReset = css`
    background: none;
    border: 0;
    padding: 0;
    color: inherit;
`;

export const linkReset = css`
    font-weight: inherit;
    text-decoration: inherit;
    color: inherit;
`;

export const StyledButton = styled.button`
    ${buttonStyling};
`;

export const commonButton = (color?: string) => css`
    font-size: 17px;
    color: ${color || 'black'};
    line-height: 30px;
    cursor: pointer;
    border: 1.5px solid black;
    border-radius: 3px;
    ${weight.regular};
    padding: 0 10px;
    border: 1.5px solid ${color || 'black'};
    background: none;
`;

export const CommonButton = styled.button<{
    color?: string;
}>`
    ${props => commonButton(props.color)}
`;
export const WhiteButtonStyle = styled.div`
    list-style-type: none;
    color: black;
    line-height: 30px;
    cursor: pointer;
    border: 1.5px solid black;
    border-radius: 3px;
    ${weight.regular};
    padding: 0 10px;
    a {
        text-decoration: none;
        color: black;
        padding: 0px;
        ${weight.regular};
    }
`;

const WhiteButtonStyleRotate = styled(WhiteButtonStyle)<{
    randomNumber?: number;
}>`
    transition: transform 200ms ease-in-out;
    & :hover {
        transform: rotate(-${props => props.randomNumber || 0}deg);
        transition: transform 200ms ease-in-out;
    }
`;

export const StyledSelect = styled.select`
    display: block;
    color: #444;
    line-height: 1.3;
    padding-left: 10px;
    width: 100%;
    margin: 0;
    border: 1px solid #aaa;
    border-radius: 0.5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
        linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 17px;
    line-height: 25px;
`;

export const StyledOption = styled.option``;

function getRandomNumber() {
    return Math.floor(Math.random() * 4) + 2;
}

export const WhiteButton: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let degrees = getRandomNumber();
    return <WhiteButtonStyleRotate randomNumber={degrees}>{children}</WhiteButtonStyleRotate>;
};

// https://webaim.org/techniques/css/invisiblecontent/
export const ForScreenReaders = styled.span`
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
`;

export const primaryButton = css`
    ${buttonReset};
    border-radius: 5px;
    padding: 0 ${space(1)};
    line-height: ${space(4)};
    cursor: pointer;
    border: 2px solid #2b2929;

    :disabled {
        background: ${constants.colors.pallet.disabled};
    }
`;

export const secondaryButton = css`
    ${buttonReset};
    border-radius: 5px;
    padding: 0 ${space(1)};
    line-height: ${space(4)};
    cursor: pointer;
    border: 2px solid rgba(0, 0, 0, 0.2);
    transition: background 100ms ease-out;

    &:hover {
        background: #f2f2f2;
    }
    :disabled {
        background: ${constants.colors.pallet.disabled};
    }
`;

export const filledButton = css`
    ${buttonReset};
    border-radius: 5px;
    padding: 0 ${space(1)};
    line-height: ${space(4)};
    background: #2b2929;
    color: ${constants.colors.pallet.white};
    cursor: pointer;
    transition: background 100ms ease-out;

    &:hover {
        background: ${lighten(0.08, '#2b2929')};
    }

    :disabled {
        background: ${constants.colors.pallet.disabled};
    }
`;
