import { isNaN } from "lodash";

export function slugify(s: string): string {
    return s.replace(' ', '-').toLowerCase();
}

export function partition<T>(l: T[], isValid: (e: T) => any | undefined): Array<T[]> {
    const pass: T[] = [];
    const fail: T[] = [];
    l.forEach(e => (isValid(e) ? pass.push(e) : fail.push(e)));
    return [pass, fail];
}

export function defaultDict<T>(defaultInit: { new (): T }): Record<string, T> {
    return new Proxy(
        {},
        {
            get: (target: { [key: string]: T }, key: string) => {
                return key in target ? target[key] : (target[key] = new defaultInit());
            },
        }
    );
}

export function getUrlParams(params: string[]): { [key: string]: string | null } {
    const url: Location = window.location; // http://google.com?id=test
    const urlObject = new URL(url.href);
    const result: { [key: string]: string | null } = {};
    params.forEach(param => {
        const id = urlObject.searchParams.get(param);
        result[param] = id;
    });
    return result;
}

export function setUrlParams(
    params: { [key: string]: string | null },
    keepLipscore?: boolean
): void {
    const lipscoreParams = getUrlParams([
        'lipscore_received',
        'lipscore_token',
        'ls_origin',
        'ls_pre_rate_pi',
        'ls_pre_rate_v',
        'ls_type',
    ]);

    const allParams = keepLipscore ? { ...params, ...lipscoreParams } : params;

    const queryString = Object.entries(allParams)
        .filter(([k, v]) => v !== null)
        .map(([k, v]) => [encodeURI(k), encodeURI(v as string)].join('='))
        .join('&');

    const prefixedQueryString = queryString ? `?${queryString}` : queryString;

    const urlObject = new URL(window.location.href);
    const newUrl = `${urlObject.origin}${urlObject.pathname}${prefixedQueryString}${urlObject.hash}`;
    history.replaceState({}, 'Updated params', newUrl);
}

export function extractPriceNumberAndCurrency(price: string): [string, string] {
    const noBreakSpace = ' ';
    const space = ' ';

    //const [priceNumber, currency] = price.replace(noBreakSpace, space).split(space);
    
    let priceNumber = price.split("").filter((v) => !isNaN(v) && !isNaN(parseFloat(v)))
    let currency = price.split("").filter((v) => !priceNumber.includes(v))
    
    return [priceNumber.join("").trim(), currency.join("").trim()];
}

export function combinePriceNumberAndCurrency(price: string, currency: string): string {
    
    let str = ""

    // Format with space if more than 3 digits
    if (price.length > 3){
        const p = price.split("")
        for (let i = price.length - 1; i >= 0; i--) {
            if (i % 3 === 0) {
                str = `${p[i]} ${str}`
            } else {
                str = `${p[i]}${str}`
            }
        }
    } else {
        str = price
    }

    return `${str} ${currency}`
}

export function createGroupMap<T>(list: Array<[string, T]>): Record<string, T[]> {
    const result: Record<string, T[]> = {};

    list.forEach(([key, value]) => {
        if (!result[key]) {
            result[key] = [];
        }
        result[key].push(value);
    });

    return result;
}

export function createMap<T>(list: Array<[string, T]>): Record<string, T> {
    const result: Record<string, T> = {};
    list.forEach(([key, value]) => (result[key] = value));

    return result;
}
