import React, { FC, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { Maybe, NewProductPageQuery } from '../../graphql-types';
import { npBackendApi } from '../api/npBackend';
import { fontStyle, space } from './styles';
import constants from '../constants';
import { I18n } from '../i18n';
import { useQuery } from 'react-query';
import { OpenedLock } from '../svg/OpenedLock';

type CrowdfundingStatus = {
    orderCount?: number;
};

async function getCrowdfundingStatus(centraId: string): Promise<CrowdfundingStatus> {
    let res = await npBackendApi.get<CrowdfundingStatus>(
        `/api/v1/crowdfunding/product/${centraId}`
    );
    return res.data;
}

export function useCrowdfundingStatus(
    centraId: string,
    { enabled = true }: { enabled?: boolean } = {}
) {
    return useQuery(['crowdfunding_status', centraId], () => getCrowdfundingStatus(centraId), {
        enabled,
        onError: error => {
            Sentry.captureException(error);
        },
    });
}

export type CrowdfundingState =
    | 'loading'
    | 'not-crowdfunding'
    | 'running'
    | 'finished-success'
    | 'finished-not-enough'
    | 'error';

export function useCrowdfundingState(product: {
    centraId: string;
    productState?: Maybe<string>;
    crowdfundingGoal?: Maybe<number>;
    crowdfundingMax?: Maybe<number>;
    crowdfundingDate?: Maybe<string>;
}): CrowdfundingState {
    const isCrowdfunding = product.productState === 'crowdFunding';

    let { data: crowdfundingStatus, isLoading, isError } = useCrowdfundingStatus(product.centraId, {
        enabled: isCrowdfunding,
    });

    if (!isCrowdfunding) {
        return 'not-crowdfunding';
    }

    if (isError) {
        return 'error';
    }

    if (isLoading || !crowdfundingStatus) {
        return 'loading';
    }

    let orderCount = crowdfundingStatus.orderCount || 0;

    if (product.crowdfundingMax && orderCount >= product.crowdfundingMax) {
        return 'finished-success';
    }

    const now = new Date();
    const end = product.crowdfundingDate && new Date(product.crowdfundingDate);
    if (end) {
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
    }

    if (end && now > end) {
        if (!product.crowdfundingGoal) {
            return 'finished-success';
        }

        if (orderCount >= product.crowdfundingGoal) {
            return 'finished-success';
        } else {
            return 'finished-not-enough';
        }
    }

    // TODO: Check if crowdfundingMax is reached

    return 'running';
}

export const CrowdfundingStatusBar: FC<{
    product: NonNullable<NewProductPageQuery['product']>;
}> = ({ product }) => {
    const crowdfundingStatus = useCrowdfundingStatus(product.centraId);

    useEffect(() => {
        if (crowdfundingStatus.error) {
            console.error(crowdfundingStatus.error);
            // We don't want to crash if the backend does not answer. We want to report the error to Sentry
            Sentry.captureException(crowdfundingStatus.error);
        }
    }, [crowdfundingStatus.error]);

    if (crowdfundingStatus.error) {
        return <div />;
    }

    // If data is loading, show an empty bar, but no numbers
    const showNumber = !crowdfundingStatus.isLoading;
    const countSold = crowdfundingStatus.data?.orderCount ?? 0;
    const countMax = product.crowdfundingGoal ?? 1;
    const percent = Math.min((countSold / countMax) * 100, 100);

    const durationDays = getDaysLeft(new Date(), new Date(product.crowdfundingDate));

    return (
        <div
            css={`
                display: grid;
                grid-template-areas:
                    'bar bar'
                    'count .'
                    'days .';
            `}
        >
            <div
                css={`
                    grid-area: bar;
                    display: flex;
                    height: 8px;
                    ${fontStyle.label};
                    border-radius: 3px;
                    background-color: ${constants.colors.pallet.disabled};
                    width: 100%;
                    max-width: 544px;
                    margin-bottom: 8px;
                `}
            >
                <div
                    css={`
                        background-color: ${constants.colors.pallet.crowdfunding};
                        width: ${percent}%;
                        color: white;
                        display: flex;
                        justify-content: flex-end;
                        padding-right: ${space(1)};
                        border-radius: 3px;

                        transition: width 1s ease-in-out;
                    `}
                />
            </div>
            {showNumber && (
                <div
                    css={`
                        grid-area: count;
                        ${fontStyle.label};
                        color: ${constants.colors.pallet.crowdfunding};
                    `}
                >
                    {countSold}/{countMax} <I18n>reserved</I18n>
                </div>
            )}
            <div
                css={`
                    grid-area: days;
                    color: #737373;
                    ${fontStyle.label};
                `}
            >
                {durationDays} <I18n>{durationDays == 1 ? 'dayLeft' : 'daysLeft'}</I18n>
            </div>
        </div>
    );
};

// Exported for tests
export function getDaysLeft(now: Date, crowdfundingDate: Date): number {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);

    crowdfundingDate.setHours(0);
    crowdfundingDate.setMinutes(0);
    crowdfundingDate.setSeconds(0);
    crowdfundingDate.setMilliseconds(0);

    const nowInDays = Math.floor(now.getTime() / 86400000);
    const endDateInDays = Math.floor(crowdfundingDate.getTime() / 86400000);
    return Math.max(0, endDateInDays - nowInDays + 1);
}

type CrowdfundingProduct = {
    centraId: string;
    crowdfundingGoal?: Maybe<number>;
};

export const CrowdfundingImageLabelReserved: FC<{
    product: CrowdfundingProduct;
}> = ({ product }) => {
    let { data: status } = useCrowdfundingStatus(product.centraId);

    if (!status) {
        return null;
    }

    return (
        <div
            css={`
                display: flex;
                align-items: center;
                color: ${constants.colors.pallet.crowdfunding};
                svg {
                    margin-right: ${space(1)};
                }
            `}
        >
            <OpenedLock />
            <div>
                {status.orderCount} <I18n>reserved</I18n>
            </div>
        </div>
    );
};

export const CrowdfundingImageLabelStatus: FC<{
    product: CrowdfundingProduct;
}> = ({ product }) => {
    let { data: status } = useCrowdfundingStatus(product.centraId);

    if (!status) {
        return null;
    }

    return (
        <div
            css={`
                display: flex;
                align-items: center;
                color: ${constants.colors.pallet.crowdfunding};
                svg {
                    margin-right: ${space(1)};
                }
            `}
        >
            <OpenedLock />
            <div>
                {status.orderCount}/{product.crowdfundingGoal}
            </div>
        </div>
    );
};
