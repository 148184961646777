import React, { FC } from 'react';

export const CartFilledWithNumber: FC<{ number: number; className?: string }> = ({
    number,
    className,
}) => {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            fill="none"
            viewBox="0 0 32 32"
        >
            <rect width="32" height="32" fill="#F2F2F2" rx="3"></rect>
            <path
                fill="#000"
                fillRule="evenodd"
                d="M14.579 7.126A2.25 2.25 0 0118.25 8.87V10h-4.5V8.87c0-.677.304-1.317.829-1.744zM12.25 10V8.87a3.75 3.75 0 117.5 0V10H24v12a3 3 0 01-3 3H11a3 3 0 01-3-3V10h4.25z"
                clipRule="evenodd"
            ></path>
            <text
                fill="#fff"
                style={{ whiteSpace: 'pre' }}
                fontFamily="Founders Grotesk Text"
                fontSize="12"
                letterSpacing=".04em"
            >
                <tspan x="16" y="20.74" textAnchor="middle">
                    {number}
                </tspan>
            </text>
        </svg>
    );
};
