import React, { useState, useEffect, FC } from 'react';
import { useNPContext } from '../../../npContext';
import { ICentraCheckoutSelection, CentraSelectionItem } from '../../../interfaces';
import CartItem from './cartItem';
import { I18n, useDateFormatter } from '../../../i18n';
import { space } from '../../styles';
import constants from '../../../constants';
import {
    LabeledProduct,
    getOrderedCrowdfundingProducts,
    getNormalAndPreorderProducts,
} from './hooks';
import { OpenedLock } from '../../../svg/OpenedLock';
import { useCrowdfundingStatus } from '../../CrowdfundingStatus';
import { LiveVariants, useLiveVariants } from '../../../api/centra';
import { PreorderStock, usePreorderStock } from '../../../api/np-backend/preorderStock';

interface ProductVariantItem {
    item: string;
    stock: string | number;
}

const getSelectedVariantIds = (items?: CentraSelectionItem[] | null) => {
    return items?.map(item => item.product.product);
};

const isPreorder = ({
    preorderDeliveryDate,
    stock,
}: {
    preorderDeliveryDate: string;
    stock: string | number;
}): boolean => {
    return !!preorderDeliveryDate && stock === 'infinite';
};

export const ItemGroup: React.FC<{
    items: LabeledProduct[];
    currency: string;
    // "Alternate view", the one used on shopping page
    alternate?: boolean;
}> = ({ items, currency, alternate = false }) => {
    return (
        <div
            css={`
                margin-bottom: ${space(6)};
            `}
        >
            {items.map(labeledItem => {
                const item = labeledItem.product;
                const cartItem = {
                    productType: labeledItem.productType,
                    variantId: item.product.product,
                    productId: item.product.centraProduct,
                    itemId: item.item,
                    line: item.line,
                    quantity: item.quantity,
                    sex: item.product.gender_selection_gender,
                    priceNumber: item.priceEachAsNumber,
                    currency: currency,
                    color: item.product.color_swatch.name,
                    colorCode: item.product.color_swatch.code,
                    colorCode2: item.product.color_swatch.code2,
                    size: item.size,
                    productName: item.product.name,
                    collectionName: item.product.collectionName,
                    media: item.product.media,
                };
                return <CartItem key={item.item} cartItem={cartItem} alternate={alternate} />;
            })}
        </div>
    );
};

function calculateDateCategories({
    items,
    liveVariants,
    preorderStock,
}: {
    items?: CentraSelectionItem[] | null;
    liveVariants: LiveVariants;
    preorderStock: PreorderStock;
}) {
    const variantIdToStock: { [variantId: string]: string | number } = {};
    const itemIsPreorder: { [variantId: string]: boolean } = {};
    const dateGroups: { [date: string]: CentraSelectionItem[] } = {};

    liveVariants.forEach(productVariant => {
        productVariant.items.forEach((productVariantItem: ProductVariantItem) => {
            variantIdToStock[productVariantItem.item] = productVariantItem.stock;
        });
    });

    items!.forEach((item: CentraSelectionItem) => {
        let sizeId = item.item.split('-')[1];

        if (!sizeId) {
            throw new Error('item id is malformed. In calculateDateCategories');
        }

        let preorderDeliveryDate = preorderStock[sizeId]?.deliveryDate;

        if (preorderDeliveryDate) {
            if (
                isPreorder({
                    preorderDeliveryDate,
                    stock: variantIdToStock[item.item],
                })
            ) {
                if (!dateGroups[preorderDeliveryDate]) {
                    dateGroups[preorderDeliveryDate] = [];
                }

                dateGroups[preorderDeliveryDate].push(item);
                itemIsPreorder[item.item] = true;
            }
        }
    });

    /*
     * Create datastructures in form of {preorderDeliveryDate, items}
     * where preorderDeliveryDate is a Date object, and items an
     * array of preorderDeliveryDate related items from selection.
     */
    const noPreorders: { items: CentraSelectionItem[] } = { items: [] };
    items!.forEach((item: CentraSelectionItem) => {
        if (!itemIsPreorder[item.item]) {
            noPreorders.items.push(item);
        }
    });

    const preorders: Array<{
        preorderDeliveryDate: Date;
        items: CentraSelectionItem[];
    }> = [];
    Object.keys(dateGroups).forEach(date => {
        const preorder = {
            preorderDeliveryDate: new Date(date),
            items: dateGroups[date],
        };
        preorders.push(preorder);
    });

    preorders.sort(
        (
            order1: { preorderDeliveryDate: Date; items: CentraSelectionItem[] },
            order2: { preorderDeliveryDate: Date; items: CentraSelectionItem[] }
        ) => order1.preorderDeliveryDate.getTime() - order2.preorderDeliveryDate.getTime()
    );

    if (noPreorders.items.length === 0) {
        return preorders;
    } else {
        return [noPreorders, ...preorders];
    }
}

export const useDateCategories = (
    items?: CentraSelectionItem[] | null
): Array<{ preorderDeliveryDate?: Date; items: CentraSelectionItem[] }> => {
    const liveVariants = useLiveVariants(getSelectedVariantIds(items) || []);
    const preorderStock = usePreorderStock();

    if (liveVariants.error) {
        // TODO: Better error handling
        console.error(liveVariants.error);
        return [];
    }

    if (preorderStock.error) {
        // TODO: Better error handling
        console.error(preorderStock.error);
        return [];
    }

    if (liveVariants.isLoading || preorderStock.isLoading) {
        return [];
    }

    const dateCategories = calculateDateCategories({
        liveVariants: liveVariants.data,
        preorderStock: preorderStock.data,
        items,
    });

    return dateCategories;
};

const CartItems: React.FC<{ labeledProducts: LabeledProduct[] }> = ({ labeledProducts }) => {
    const context = useNPContext();
    const selection: ICentraCheckoutSelection | undefined = context?.state?.centra?.selection;
    const currency = selection?.currency ?? '';

    const normalAndPreorder = getNormalAndPreorderProducts(labeledProducts);

    return (
        <div
            css={`
                padding: 0 ${space(3)};
            `}
        >
            <ItemGroup items={normalAndPreorder} currency={currency} />
            <CrowdfundingProducts labeledProducts={labeledProducts} currency={currency} />
        </div>
    );
};

export const CrowdfundingProducts: React.FC<{
    labeledProducts: LabeledProduct[];
    currency: string;
    alternate?: boolean;
}> = ({ labeledProducts, currency, alternate = false }) => {
    const crowdfundingProducts = getOrderedCrowdfundingProducts(labeledProducts);

    return (
        <>
            {crowdfundingProducts.map(product => (
                <CrowdfundingProduct
                    key={product.product.item}
                    product={product}
                    currency={currency}
                    alternate={alternate}
                />
            ))}
        </>
    );
};

export const CrowdfundingProduct: FC<{
    product: LabeledProduct;
    currency: string;
    alternate: boolean;
}> = ({ product, currency, alternate }) => {
    const dateFormatter = useDateFormatter();
    const { data: status } = useCrowdfundingStatus(product.product.product.centraProduct);
    const reservationRequired =
        status && product.crowdfundingGoal
            ? Math.max(0, product.crowdfundingGoal - (status.orderCount || 0) || 0)
            : '---';

    return (
        <div
            key={product.product.item}
            css={`
                border-left: 2px solid ${constants.colors.pallet.crowdfunding};
                padding-left: ${space(2)};
            `}
        >
            <div
                css={`
                    white-space: normal;
                    color: ${constants.colors.pallet.crowdfunding};
                `}
            >
                {reservationRequired !== 0 ? (
                    <>
                        <OpenedLock /> <I18n>This product needs</I18n> {reservationRequired}{' '}
                        <I18n>more reservations</I18n>. <I18n>You will get an update on</I18n>{' '}
                        {product.date && dateFormatter(product.date)}.
                    </>
                ) : (
                    <>
                        <OpenedLock /> <I18n>crowdfundingEnough</I18n>{' '}
                        {product.date && dateFormatter(product.date)}.
                    </>
                )}
            </div>
            <ItemGroup items={[product]} currency={currency} alternate={alternate} />
        </div>
    );
};

export default CartItems;
