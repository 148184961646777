import React, { FC, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/browser';
import { npBackendApi } from '../../api/npBackend';
import { I18n, useI18n } from '../../i18n';
import { CountriesDatalist } from '../CountriesDatalist';
import Portal from '../Portal';
import { secondaryButton, space } from '../styles';
import { Field, Form, RadioButtons, RadioButton, Button, Buttons, CheckBox } from './Form';
import { Modal } from '../Modal';
import constants from '../../constants';

type ResetPasswordToken = {
    id: string;
    token: string;
};

export type ModalState =
    | { type: 'login' }
    | { type: 'register' }
    | { type: 'register_user_info' }
    | { type: 'forgot_password' }
    | { type: 'forgot_password_code'; token: ResetPasswordToken }
    | { type: 'forgot_password_enter_password'; token: ResetPasswordToken; code: string };

export const LoginOrRegisterModal: FC<{
    onSuccess?: () => void;
    doClose: () => void;
    initialState?: ModalState;
}> = props => {
    return (
        <Portal>
            <Modal close={props.doClose}>
                <LoginOrRegister {...props} />
            </Modal>
        </Portal>
    );
};

export type LoginOrRegisterProps = {
    onSuccess?: () => void;
    doClose: () => void;
    initialState?: ModalState;
};
export const LoginOrRegister: FC<LoginOrRegisterProps> = ({
    doClose,
    onSuccess,
    initialState = { type: 'login' },
}) => {
    let [state, setState] = useState(initialState);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    return state.type === 'login' ? (
        <LoginModal
            {...{
                doClose,
                onSuccess,
                setState,
                email,
                setEmail,
                password,
                setPassword,
            }}
        />
    ) : state.type === 'register' ? (
        <RegisterModal {...{ onSuccess, setState, email, setEmail, password, setPassword }} />
    ) : state.type === 'register_user_info' ? (
        <RegisterUserInfoModal {...{ doClose, onSuccess }} />
    ) : state.type === 'forgot_password' ? (
        <ForgotPassword {...{ setState, email, setEmail }} />
    ) : state.type === 'forgot_password_code' ? (
        <ForgotPasswordCode {...{ setState }} token={state.token} />
    ) : state.type === 'forgot_password_enter_password' ? (
        <ForgotPasswordEnterPassword
            {...{ onSuccess, doClose }}
            token={state.token}
            code={state.code}
        />
    ) : (
        <div>Invalid state</div>
    );
};

export const LoginModal: FC<{
    onSuccess?: () => void;
    doClose: () => void;
    setState: (newState: ModalState) => void;
    email: string;
    setEmail: (email: string) => void;
    password: string;
    setPassword: (email: string) => void;
}> = ({ onSuccess, doClose, setState, email, setEmail, password, setPassword }) => {
    const queryClient = useQueryClient();
    const { t } = useI18n();

    let { mutate, isLoading, error } = useMutation(loginMutation, {
        onError: error => {
            Sentry.captureException(error);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(
                'current_user',
                {
                    refetchInactive: true,
                },
                { throwOnError: true }
            );
            if (onSuccess) {
                onSuccess();
            }
            doClose();
        },
    });

    const emailError = !email && t('emailRequired');
    const passwordError = !password && t('passwordRequired');

    const hasErrors = emailError || passwordError;

    return (
        <>
            <h1>
                <I18n>logInHeader</I18n>
            </h1>
            <div
                css={`
                    color: #737373;
                    margin-bottom: ${space(9)};
                    max-width: 923px;
                `}
            >
                <I18n>loginIntro</I18n>
            </div>
            <Form
                error={error as Error}
                onSubmit={() => {
                    if (!hasErrors) {
                        void mutate({ email, password });
                    }
                }}
            >
                <Field label={t('emailLabel')} error={emailError}>
                    <input
                        placeholder={t('emailLabel')}
                        value={email}
                        type="email"
                        autoComplete="email"
                        onChange={ev => setEmail(ev.target.value)}
                    />
                </Field>
                <Field label={t('passwordLabel')} error={passwordError}>
                    <input
                        placeholder={t('passwordPlaceholder')}
                        type="password"
                        value={password}
                        autoComplete="current-password"
                        onChange={ev => setPassword(ev.target.value)}
                    />
                </Field>
                <Buttons
                    css={`
                        align-self: stretch;
                        /* Failback for mobile without support for grid */
                        display: block;
                        display: grid;
                        grid-template-columns: auto auto;
                        grid-gap: ${space(1)};
                        justify-content: end;
                        justify-items: end;

                        @media (min-width: 500px) {
                            justify-content: stretch;
                            grid-template-columns: 1fr auto auto;
                        }
                    `}
                >
                    <Button
                        type="button"
                        secondary
                        onClick={ev => {
                            ev.preventDefault();
                            setState({ type: 'forgot_password' });
                        }}
                        css={`
                            color: ${constants.colors.pallet.error};
                            grid-column: 1 / span 2;
                            @media (min-width: 500px) {
                                margin-right: auto;
                                grid-column: 1;
                            }
                        `}
                    >
                        <I18n>forgotPassword</I18n>
                    </Button>
                    <Button
                        type="button"
                        secondary
                        onClick={ev => {
                            ev.preventDefault();
                            setState({ type: 'register' });
                        }}
                    >
                        <I18n>register</I18n>
                    </Button>
                    <Button disabled={isLoading}>{isLoading ? t('loading') : t('logIn')}</Button>
                </Buttons>
            </Form>
        </>
    );
};

async function loginMutation({
    email,
    password,
}: {
    email: string;
    password: string;
}): Promise<void> {
    if (email && password) {
        try {
            await npBackendApi.post('/api/v1/login', { email, password });
        } catch (err: any) {
            let maybeMessage = err.response?.data?.message;
            if (maybeMessage) {
                throw new Error(maybeMessage);
            }
            throw err;
        }
    } else {
        throw new Error('Email and password required');
    }
}

export const RegisterModal: FC<{
    onSuccess?: () => void;
    setState: (newState: ModalState) => void;
    email: string;
    setEmail: (email: string) => void;
    password: string;
    setPassword: (email: string) => void;
}> = ({ onSuccess, setState, email, setEmail, password, setPassword }) => {
    const { t } = useI18n();
    const queryClient = useQueryClient();
    const [repeatPassword, setRepeatPassword] = useState('');
    const [fullName, setFullName] = useState('');
    const [subscribeToNewsletter, setSubscribeToNewsletter] = useState(false);
    const [subscribeToCommunity, setSubscribeToCommunity] = useState(false);

    let { mutate, isLoading, error } = useMutation(registerMutation, {
        onError: error => {
            Sentry.captureException(error);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(
                'current_user',
                {
                    refetchInactive: true,
                },
                { throwOnError: true }
            );
            if (onSuccess) {
                onSuccess();
            }
            setState({ type: 'register_user_info' });
        },
    });

    const repeatPasswordError = password !== repeatPassword && t('repeatDoesNotMatch');
    const emailError = email.length < 2 && t('emailRequired');
    const passwordError =
        (!password && t('passwordRequired')) || (password.length < 8 && t('passwordLengthError'));
    const fullNameError = fullName.length < 2 && t('nameRequired');

    const hasErrors = repeatPasswordError || emailError || passwordError || fullNameError;

    return (
        <>
            <h1>
                <I18n>registerHeader</I18n>
            </h1>
            <div
                css={`
                    color: #737373;
                    margin-bottom: ${space(9)};
                    max-width: 923px;
                `}
            >
                <I18n>registerIntro</I18n>
            </div>
            <Form
                error={error as Error}
                onSubmit={() => {
                    if (!hasErrors) {
                        void mutate({
                            email,
                            password,
                            fullName,
                            subscribeToNewsletter,
                            subscribeToCommunity,
                        });
                    }
                }}
            >
                <Field label={t('emailLabel')} error={emailError}>
                    <input
                        placeholder={t('emailPlaceholder')}
                        value={email}
                        type="email"
                        autoComplete="email"
                        onChange={ev => setEmail(ev.target.value)}
                    />
                </Field>
                <Field label={t('passwordLabel')} error={passwordError}>
                    <input
                        placeholder={t('newPasswordPlaceholder')}
                        type="password"
                        value={password}
                        autoComplete="new-password"
                        onChange={ev => setPassword(ev.target.value)}
                    />
                </Field>
                <Field label={t('repeatPasswordLabel')} error={repeatPasswordError}>
                    <input
                        placeholder={t('repeatPasswordPlaceholder')}
                        type="password"
                        value={repeatPassword}
                        autoComplete="new-password"
                        onChange={ev => setRepeatPassword(ev.target.value)}
                    />
                </Field>
                <Field label={t('nameLabel')} error={fullNameError}>
                    <input
                        placeholder={t('namePlaceholder')}
                        value={fullName}
                        autoComplete="name"
                        onChange={ev => setFullName(ev.target.value)}
                    />
                </Field>
                <CheckBox
                    label={t('communityNewsletterLabel')}
                    value={subscribeToCommunity}
                    onChange={setSubscribeToCommunity}
                />
                <CheckBox
                    label={t('newsletterLabel')}
                    value={subscribeToNewsletter}
                    onChange={setSubscribeToNewsletter}
                />
                <Buttons>
                    <Button
                        css={`
                            border-color: #f2f2f2;
                            color: #d6d6d6;
                        `}
                        secondary
                        type="button"
                        onClick={ev => {
                            ev.preventDefault();
                            setState({ type: 'login' });
                        }}
                    >
                        <I18n>logIn</I18n>
                    </Button>
                    <Button disabled={isLoading}>{isLoading ? t('loading') : t('register')}</Button>
                </Buttons>
            </Form>
        </>
    );
};

async function registerMutation({
    email,
    password,
    fullName,
    subscribeToNewsletter,
    subscribeToCommunity,
}: {
    email: string;
    password: string;
    fullName: string;
    subscribeToNewsletter: boolean;
    subscribeToCommunity: boolean;
}): Promise<void> {
    if (email && password) {
        try {
            await npBackendApi.post('/api/v1/auth/register', {
                email,
                password,
                fullName,
                subscribeToNewsletter,
                subscribeToCommunity,
            });
        } catch (err: any) {
            let maybeMessage = err.response?.data?.message;
            if (maybeMessage) {
                throw new Error(maybeMessage);
            }
            throw err;
        }
    } else {
        throw new Error('Email and password required');
    }
}

export const RegisterUserInfoModal: FC<{
    doClose: () => void;
}> = ({ doClose }) => {
    const { t } = useI18n();
    const queryClient = useQueryClient();
    const [country, setCountry] = useState('');
    const [birthYear, setBirthYear] = useState('');
    const [postcode, setPostcode] = useState('');
    const [preferredFit, setPreferredFit] = useState('');
    const [profilePicture, setProfilePicture] = useState<null | string>(null);

    const fileUploadRef = useRef<HTMLInputElement>(null);

    let { mutate, isLoading, error } = useMutation(registerUserInfoMutation, {
        onError: error => {
            Sentry.captureException(error);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries('discussion_posts');
            await queryClient.invalidateQueries(
                'current_user',
                {
                    refetchInactive: true,
                },
                { throwOnError: true }
            );
            doClose();
        },
    });

    const birthYearError =
        birthYear &&
        ((!/^\d+$/.test(birthYear) && t('birthYearMustBeANumber')) ||
            (parseInt(birthYear) < 1000 && t('birthYearMustBeAValidBirthYear')) ||
            (parseInt(birthYear) > 10000 && t('birthYearMustBeAValidBirthYear')));

    const hasErrors = birthYearError;

    return (
        <>
            <h1>
                <I18n>registerInfoHeader</I18n>
            </h1>
            <div
                css={`
                    color: #737373;
                    margin-bottom: ${space(9)};
                    max-width: 923px;
                `}
            >
                <I18n>registerInfoIntro</I18n>
            </div>
            <Form
                error={error as Error}
                onSubmit={() => {
                    if (!hasErrors) {
                        void mutate({
                            country,
                            birthYear: parseInt(birthYear),
                            postcode,
                            preferredFit,
                            profilePicture,
                        });
                    }
                }}
            >
                <input
                    type="file"
                    ref={fileUploadRef}
                    css={`
                        opacity: 0;
                        height: 0px;
                        width: 0px;
                    `}
                    onChange={ev => {
                        if (ev.target.files?.[0]) {
                            let file = ev.target.files?.[0];
                            if (!file.type.startsWith('image/')) {
                                alert('We only support images');
                                return;
                            }
                            const reader = new FileReader();
                            reader.onload = e => {
                                if (e.target?.result) {
                                    let result = e.target.result;
                                    setProfilePicture(result as string);
                                }
                            };
                            reader.onerror = err => {
                                console.error(err);
                            };
                            reader.readAsDataURL(file);
                        }
                    }}
                />
                <div
                    css={`
                        @media (min-width: 650px) {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            grid-column-gap: ${space(4)};
                        }
                    `}
                >
                    <RadioButtons
                        label={t('preferredFitLabel')}
                        name="preferredFit"
                        value={preferredFit}
                        onChange={setPreferredFit}
                    >
                        <RadioButton label={t('preferredFitMen')} value="men" />
                        <RadioButton label={t('preferredFitWomen')} value="women" />
                        <RadioButton label={t('preferredFitBoth')} value="both" />
                    </RadioButtons>
                    <div>
                        <div
                            css={`
                                margin-bottom: ${space(1)};
                            `}
                        >
                            <I18n>profilePictureLabel</I18n>
                        </div>
                        <div
                            css={`
                                display: flex;
                                margin-bottom: ${space(4)};
                            `}
                        >
                            {profilePicture ? (
                                <img
                                    src={profilePicture}
                                    css={`
                                        display: block;
                                        height: 64px;
                                        width: 64px;
                                        object-fit: cover;
                                        border-radius: 3px;
                                    `}
                                />
                            ) : (
                                <button
                                    type="button"
                                    css={`
                                        ${secondaryButton};
                                        display: block;
                                    `}
                                    onClick={() => {
                                        if (fileUploadRef.current) {
                                            fileUploadRef.current.click();
                                        }
                                    }}
                                >
                                    + <I18n>add</I18n>
                                </button>
                            )}
                        </div>
                    </div>
                    <Field label={t('countryLabel')}>
                        <input
                            placeholder={t('countryPlaceholder')}
                            value={country}
                            list="countries_datalist"
                            autoComplete="country"
                            onChange={ev => setCountry(ev.target.value)}
                        />
                    </Field>
                    <CountriesDatalist id="countries_datalist" />
                    <Field label={t('birthYearLabel')} error={birthYearError}>
                        <input
                            placeholder={t('birthYearPlaceholder')}
                            value={birthYear}
                            autoComplete="bday-year"
                            onChange={ev => setBirthYear(ev.target.value)}
                        />
                    </Field>
                    <Field label={t('postCodeLabel')}>
                        <input
                            placeholder={t('postCodePlaceholder')}
                            value={postcode}
                            autoComplete="postal-code"
                            onChange={ev => setPostcode(ev.target.value)}
                        />
                    </Field>
                </div>
                <Buttons>
                    <Button disabled={isLoading}>{isLoading ? t('loading') : t('save')}</Button>
                </Buttons>
            </Form>
        </>
    );
};

async function registerUserInfoMutation({
    country,
    birthYear,
    postcode,
    preferredFit,
    profilePicture,
}: {
    country: string;
    birthYear: number;
    postcode: string;
    preferredFit: string;
    profilePicture: string | null;
}): Promise<void> {
    try {
        await npBackendApi.put('/api/v1/users/me', {
            country,
            birthYear,
            postcode,
            preferredFit,
            profilePicture,
            enableNotifications: true,
        });
    } catch (err: any) {
        let maybeMessage = err.response?.data?.message;
        if (maybeMessage) {
            throw new Error(maybeMessage);
        }
        throw err;
    }
}

async function createPasswordResetToken({ email }: { email: string }): Promise<ResetPasswordToken> {
    try {
        let res = await npBackendApi.post<ResetPasswordToken>('/api/v1/auth/resetPasswordToken', {
            email,
        });
        return res.data;
    } catch (err: any) {
        let maybeMessage = err.response?.data?.message;
        if (maybeMessage) {
            throw new Error(maybeMessage);
        }
        throw err;
    }
}

export const ForgotPassword: FC<{
    setState: (newState: ModalState) => void;
    email: string;
    setEmail: (email: string) => void;
}> = ({ setState, email, setEmail }) => {
    const { t } = useI18n();

    let { mutate, isLoading, error } = useMutation(createPasswordResetToken, {
        onError: error => {
            Sentry.captureException(error);
        },
        onSuccess: token => {
            setState({ type: 'forgot_password_code', token });
        },
    });

    const emailError = !email && t('emailRequired');

    const hasErrors = emailError;

    return (
        <>
            <h1>
                <I18n>forgotPassword</I18n>
            </h1>
            <div
                css={`
                    color: #737373;
                    margin-bottom: ${space(9)};
                    max-width: 923px;
                `}
            >
                <I18n>forgotPasswordIntro</I18n>
            </div>
            <Form
                error={error as Error}
                onSubmit={() => {
                    if (!hasErrors) {
                        void mutate({ email });
                    }
                }}
            >
                <Field label={t('emailLabel')} error={emailError}>
                    <input
                        placeholder={t('emailLabel')}
                        value={email}
                        type="email"
                        autoComplete="email"
                        onChange={ev => setEmail(ev.target.value)}
                    />
                </Field>
                <Buttons>
                    <Button
                        css={`
                            border-color: #f2f2f2;
                            color: #d6d6d6;
                        `}
                        secondary
                        type="button"
                        onClick={ev => {
                            ev.preventDefault();
                            setState({ type: 'login' });
                        }}
                    >
                        <I18n>back</I18n>
                    </Button>
                    <Button disabled={isLoading}>
                        {isLoading ? t('loading') : t('forgotPasswordSubmit')}
                    </Button>
                </Buttons>
            </Form>
        </>
    );
};

async function verifyPasswordResetCode({
    token,
    code,
}: {
    token: ResetPasswordToken;
    code: string;
}): Promise<void> {
    try {
        await npBackendApi.post(`/api/v1/auth/resetPasswordToken/${token.id}/verify`, {
            code,
            token: token.token,
        });
    } catch (err: any) {
        let maybeMessage = err.response?.data?.message;
        if (maybeMessage) {
            throw new Error(maybeMessage);
        }
        throw err;
    }
}

export const ForgotPasswordCode: FC<{
    setState: (newState: ModalState) => void;
    token: ResetPasswordToken;
}> = ({ setState, token }) => {
    const { t } = useI18n();

    const [code, setCode] = useState('');

    let { mutate, isLoading, error } = useMutation(verifyPasswordResetCode, {
        onError: error => {
            Sentry.captureException(error);
        },
        onSuccess: () => {
            setState({ type: 'forgot_password_enter_password', token, code });
        },
    });

    const codeError = (!code || code.length < 6) && t('codeRequired');

    const hasErrors = codeError;

    return (
        <>
            <h1>
                <I18n>forgotPasswordCodeHeader</I18n>
            </h1>
            <div
                css={`
                    color: #737373;
                    margin-bottom: ${space(9)};
                    max-width: 923px;
                `}
            >
                <I18n>forgotPasswordCodeIntro</I18n>
            </div>
            <Form
                error={error as Error}
                onSubmit={() => {
                    if (!hasErrors) {
                        void mutate({ code, token });
                    }
                }}
            >
                <Field label={t('forgotPasswordCodeLabel')} error={codeError}>
                    <input
                        placeholder={t('forgotPasswordCodePlaceholder')}
                        value={code}
                        onChange={ev => setCode(ev.target.value)}
                    />
                </Field>
                <Buttons>
                    <Button
                        secondary
                        type="button"
                        onClick={ev => {
                            ev.preventDefault();
                            setState({ type: 'forgot_password' });
                        }}
                    >
                        <I18n>back</I18n>
                    </Button>
                    <Button disabled={isLoading}>
                        {isLoading ? t('loading') : t('forgotPasswordCodeSubmit')}
                    </Button>
                </Buttons>
            </Form>
        </>
    );
};

async function usePasswordResetCodeToResetPassword({
    token,
    code,
    password,
}: {
    token: ResetPasswordToken;
    code: string;
    password: string;
}): Promise<void> {
    try {
        await npBackendApi.post(`/api/v1/auth/resetPasswordToken/${token.id}/resetPassword`, {
            code,
            token: token.token,
            password,
        });
    } catch (err: any) {
        let maybeMessage = err.response?.data?.message;
        if (maybeMessage) {
            throw new Error(maybeMessage);
        }
        throw err;
    }
}

export const ForgotPasswordEnterPassword: FC<{
    onSuccess?: () => void;
    token: ResetPasswordToken;
    code: string;
    doClose: () => void;
}> = ({ onSuccess, doClose, token, code }) => {
    const { t } = useI18n();
    const queryClient = useQueryClient();
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    let { mutate, isLoading, error } = useMutation(usePasswordResetCodeToResetPassword, {
        onError: error => {
            Sentry.captureException(error);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(
                'current_user',
                {
                    refetchInactive: true,
                },
                { throwOnError: true }
            );
            if (onSuccess) {
                onSuccess();
            }
            doClose();
        },
    });

    const repeatPasswordError = password !== repeatPassword && t('repeatDoesNotMatch');
    const passwordError =
        (!password && t('passwordRequired')) || (password.length < 8 && t('passwordLengthError'));

    const hasErrors = repeatPasswordError || passwordError;

    return (
        <>
            <h1>
                <I18n>forgotPasswordNewPasswordHeader</I18n>
            </h1>
            <div
                css={`
                    color: #737373;
                    margin-bottom: ${space(9)};
                    max-width: 923px;
                `}
            >
                <I18n>forgotPasswordNewPasswordIntro</I18n>
            </div>
            <Form
                error={error as Error}
                onSubmit={() => {
                    if (!hasErrors) {
                        void mutate({ password, token, code });
                    }
                }}
            >
                <Field label={t('passwordLabel')} error={passwordError}>
                    <input
                        placeholder={t('newPasswordPlaceholder')}
                        type="password"
                        value={password}
                        autoComplete="new-password"
                        onChange={ev => setPassword(ev.target.value)}
                    />
                </Field>
                <Field label={t('repeatPasswordLabel')} error={repeatPasswordError}>
                    <input
                        placeholder={t('repeatPasswordPlaceholder')}
                        type="password"
                        value={repeatPassword}
                        autoComplete="new-password"
                        onChange={ev => setRepeatPassword(ev.target.value)}
                    />
                </Field>
                <Buttons>
                    <Button disabled={isLoading}>
                        {isLoading ? t('loading') : t('forgotPasswordNewPasswordSubmit')}
                    </Button>
                </Buttons>
            </Form>
        </>
    );
};
