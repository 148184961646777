import React, { FC, useState } from 'react';
import constants from '../../constants';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { weight, fontSize, space, buttonReset, linkReset } from '../styles';
import LangSelect from './navigation/langSelect';
import { I18n, I18nStr, useI18n, useLanguage, useLocalizedString } from '../../i18n';
import { useNPContext } from '../../npContext';
import subtleDots from '../../images/subtle-dots.png';
import paymentSvg from '../../svg/payment.svg';
import { css } from 'styled-components';
import {
    FooterDataQuery,
    SanityCategoryPage,
    SanityContentPage,
    SanityLandingPage,
    SanityPage,
} from '../../../graphql-types';
import NorwegianFlag from '../../svg/NorwegianFlag';
import { useLoginState } from '../auth/LoginContext';
import { getPathFromInternalPage } from '../../helpers/get-path';

const innerContainer = css`
    width: 100%;
    max-width: 1240px;

    margin: 0 auto;
    padding: 0 ${space(3)};

    @media (min-width: 1550px) {
        max-width: 2100px;
        width: 80%;
    }
`;

const Newsletter: React.FC = () => {
    const language = useLanguage();
    const context = useNPContext();
    const [subscriptionState, setSubscriptionState] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [userEmail, setUserEmail] = useState('');
    const { t } = useI18n();

    return (
        <div
            css={`
                border-top: 2px solid #f2f2f2;
            `}
        >
            <div
                css={`
                    ${innerContainer};
                    padding: ${space(4)} ${space(3)} ${space(6)};
                `}
            >
                <h2
                    css={`
                        margin: 0;
                        ${fontSize.regular};
                        ${weight.medium};
                    `}
                >
                    <I18n>joinNewsLetterHeader</I18n>
                </h2>
                <div
                    css={`
                        margin-bottom: ${space(2)};
                    `}
                >
                    <I18n>joinNewsLetterSubtitle</I18n>
                </div>
                <div>
                    <form
                        onSubmit={event => {
                            event.preventDefault();

                            if (!userEmail) {
                                return;
                            }
                            context.centra.signUpNewsLetter(
                                userEmail,
                                language,
                                () => {
                                    setSubscriptionState(true);
                                    context.userPreferences.setHasSeenSignup();
                                    window.dataLayer &&
                                        window.dataLayer.push({
                                            event: 'newsletter',
                                            eventCategory: 'newsletter',
                                            eventAction: 'signup',
                                            eventLabel: 'home page',
                                        });
                                    setError(null);
                                },
                                (errorCode: number, errors: unknown) => {
                                    console.log('error code: ', errorCode);
                                    console.log('errors', errors);
                                    setError(`Error: ${errorCode}`);
                                    setSubscriptionState(false);
                                }
                            );
                        }}
                    >
                        <div
                            css={`
                                display: flex;
                                max-width: 100%;
                                overflow: hidden;
                                input {
                                    border: 2px solid #dfdfdf;
                                    border-right: none;
                                    padding: 0 ${space(2)};
                                    width: 1px;
                                    flex-grow: 1;
                                    flex-shrink: 1;
                                    background: #fafafa;
                                    border-radius: 2px 0 0 2px;
                                }

                                button {
                                    ${buttonReset};
                                    border: 2px solid #dfdfdf;
                                    padding: 0 ${space(2)};
                                    height: ${space(6)};
                                    background: #fafafa;
                                    white-space: nowrap;
                                    border-radius: 0 2px 2px 0;

                                    &:hover {
                                        background: #f2f2f2;
                                    }

                                    &:active {
                                        background: #2b2929;
                                        color: white;
                                    }
                                }
                            `}
                        >
                            <input
                                type="email"
                                value={userEmail}
                                onChange={ev => setUserEmail(ev.target.value)}
                                placeholder={t('enterEmail')}
                            />

                            <button
                                color={constants.colors.pallet.darkGreen}
                                type="submit"
                                value="Ok"
                            >
                                <I18n>subscribe</I18n>
                            </button>
                        </div>
                    </form>
                    <div>
                        {subscriptionState ? (
                            <div>
                                <I18n>newsletterSuccess</I18n>
                            </div>
                        ) : null}
                        {error ? (
                            <div
                                css={`
                                    color: ${constants.colors.pallet.error};
                                `}
                            >
                                {error}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

const desktopMedia = '(min-width: 600px)';

const Footer: React.FC = () => {
    return (
        <div
            css={`
                box-sizing: border-box;

                *,
                *:before,
                *:after {
                    box-sizing: inherit;
                }
            `}
        >
            <Newsletter />
            <div
                css={`
                    background: url(${subtleDots}) center/40px;

                    border-top: 2px solid #f2f2f2;
                `}
            >
                <div
                    css={`
                        ${innerContainer}
                        padding: ${space(4)} ${space(3)};
                        display: grid;
                        justify-content: space-between;
                        grid-template-columns: 1fr;

                        grid-template-areas:
                            'shop'
                            'menu'
                            'social'
                            'language'
                            'copyright'
                            'logo';

                        grid-gap: ${space(4)};

                        @media ${desktopMedia} {
                            padding: ${space(6)} ${space(3)} ${space(9)};
                            grid-gap: ${space(9)} ${space(3)};
                            grid-template-columns: 1fr 1fr;
                            grid-template-areas:
                                'shop menu'
                                'copyright social'
                                'logo language';
                        }
                    `}
                >
                    <Shop />
                    <Menu />
                    <Copyright />
                    <Social />
                    <div
                        css={`
                            grid-area: logo;
                        `}
                    >
                        <img src="/img/rock.png" style={{ width: '32px' }} />
                    </div>
                    <LangSelect
                        css={`
                            grid-area: language;
                            justify-self: start;
                            @media ${desktopMedia} {
                                justify-self: end;
                            }
                        `}
                    />
                </div>
            </div>
            <a
                href="https://www.adyen.com/"
                css={`
                    ${linkReset};
                    display: block;
                    background: #f1f1f1;
                    border-top: 2px solid #e5e5e5;
                    color: #737373;
                `}
            >
                <div
                    css={`
                        ${innerContainer};
                        padding: ${space(3)} ${space(3)};
                        display: flex;
                        justify-content: space-between;

                        img {
                            display: block;
                            max-width: 100%;
                        }
                    `}
                >
                    <div
                        css={`
                            display: none;
                            @media ${desktopMedia} {
                                display: block;
                            }
                        `}
                    >
                        <I18n>paymentsAreHandled</I18n>{' '}
                        <span
                            css={`
                                text-decoration: underline;
                            `}
                        >
                            Adyen
                        </span>
                    </div>
                    <img src={paymentSvg} alt="vipps visa mastercard paypal klarna" />
                </div>
            </a>
        </div>
    );
};

const Social: FC = () => {
    return (
        <div
            css={`
                grid-area: social;

                @media ${desktopMedia} {
                    text-align: right;
                }

                a {
                    ${linkReset};
                    display: block;
                    color: #2b2929;
                    @media ${desktopMedia} {
                        color: #737373;
                    }

                    &:hover {
                        color: #2b2929;
                    }
                }
            `}
        >
            <a href="https://www.instagram.com/northernplayground/" rel="noopener" target="_blank">
                Instagram
            </a>
            <a href="https://www.facebook.com/NorthernPlayground/" rel="noopener" target="_blank">
                Facebook
            </a>
            <a
                href="https://www.linkedin.com/company/northern-playground-as/"
                rel="noopener"
                target="_blank"
            >
                LinkedIn
            </a>
        </div>
    );
};

const Copyright: FC = () => {
    return (
        <div
            css={`
                grid-area: copyright;
            `}
        >
            <div>
                From <NorwegianFlag /> with care
            </div>
            <div
                css={`
                    color: #737373;
                `}
            >
                <I18n>footerCopyright2</I18n>
            </div>
        </div>
    );
};

const Shop: FC = () => {
    let { sanityFooter } = useFooterData();

    let openingHours = useLocalizedString(sanityFooter?.storeOpeningHours);

    if (!sanityFooter) {
        return null;
    }

    return (
        <div
            css={`
                grid-area: shop;

                h2 {
                    ${fontSize.regular};
                    ${weight.medium};
                    margin: 0;
                }

                .secondary {
                    overflow-wrap: break-word;
                    color: #737373;

                    a {
                        color: inherit;
                        font-weight: inherit;
                    }
                }
            `}
        >
            <h2>
                <I18n>store</I18n>
            </h2>
            <div>
                <I18nStr>{sanityFooter.storeAddress}</I18nStr>
            </div>
            {openingHours.split('\n').map(content => (
                <div key={content}>{content}</div>
            ))}
            <div className="secondary">
                <a href={`mailto:${sanityFooter.contactEmail}`}>{sanityFooter.contactEmail}</a>
            </div>
            <div className="secondary">{sanityFooter.contactPhone}</div>
        </div>
    );
};

const Menu: FC = () => {
    const lang = useLanguage();
    const data = useFooterData();
    const { isLoggedIn } = useLoginState();

    const menuItems = [
        ...(data.sanityMenu?.menuItem || []),
        ...(data.sanityFooter?.extraMenuElements || []),
    ];

    return (
        <div
            css={`
                grid-area: menu;
                @media ${desktopMedia} {
                    text-align: right;
                }

                a {
                    ${linkReset};
                    display: block;
                    color: #2b2929;
                    @media ${desktopMedia} {
                        color: #737373;
                    }

                    &:hover {
                        color: #2b2929;
                    }
                }
            `}
        >
            {menuItems
                .filter(item => {
                    if (isLoggedIn && item?.showTo == 'logged_out') {
                        return false;
                    }
                    if (!isLoggedIn && item?.showTo == 'logged_in') {
                        return false;
                    }
                    return true;
                })
                .map(item => {
                    const page = lang === 'en' ? item?.linkEn : item?.linkNb;

                    const path = page
                        ? getPathFromInternalPage({
                              page: page as unknown as
                                  | SanityContentPage
                                  | SanityLandingPage
                                  | SanityPage
                                  | SanityCategoryPage,
                              language: lang,
                          })
                        : null;

                    return (
                        <Link key={item?._key} to={path ?? ''}>
                            <I18nStr>{item?.title}</I18nStr>
                        </Link>
                    );
                })}
        </div>
    );
};

export default Footer;

function useFooterData() {
    return useStaticQuery<FooterDataQuery>(graphql`
        fragment categoryPage on SanityCategoryPage {
            _id
            _type
            slug {
                current
            }
            title
            language
        }

        fragment page on SanityPage {
            _type
            title
            language
            slug {
                current
            }
            categoryPage {
                ...categoryPage
            }
        }

        fragment MenuItem on SanityMenuItem {
            _key
            linkNb {
                __typename
                ... on SanityPage {
                    ...page
                }
                ... on SanityCategoryPage {
                    ...categoryPage
                }
                ... on SanityContentPage {
                    _type
                    slug {
                        nb {
                            current
                        }
                        en {
                            current
                        }
                    }
                    categoryPage {
                        en {
                            ...categoryPage
                        }
                        nb {
                            ...categoryPage
                        }
                    }
                }
                ... on SanityLandingPage {
                    _type
                    language
                    path
                }
                ... on SanityShopPage {
                    _type
                }

                ... on SanityClothingType {
                    _type
                    slug {
                        nb {
                            current
                        }
                        en {
                            current
                        }
                    }
                }
            }
            linkEn {
                __typename
                ... on SanityPage {
                    ...page
                }
                ... on SanityCategoryPage {
                    ...categoryPage
                }
                ... on SanityContentPage {
                    _type
                    slug {
                        nb {
                            current
                        }
                        en {
                            current
                        }
                    }
                    categoryPage {
                        en {
                            ...categoryPage
                        }
                        nb {
                            ...categoryPage
                        }
                    }
                }
                ... on SanityLandingPage {
                    _type
                    language
                    path
                }
                ... on SanityShopPage {
                    _type
                }

                ... on SanityClothingType {
                    _type
                    slug {
                        nb {
                            current
                        }
                        en {
                            current
                        }
                    }
                }
            }
            title {
                en
                nb
            }
            showTo
        }

        query FooterData {
            sanityFooter {
                storeAddress
                _id
                _type
                storeOpeningHours {
                    en
                    nb
                }
                contactEmail
                contactPhone
                extraMenuElements {
                    ...MenuItem
                }
            }

            sanityMenu(_id: { eq: "main-menu" }) {
                menuItem {
                    ...MenuItem
                }
            }
        }
    `);
}
