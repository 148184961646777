import { Component } from 'react';
import ReactDOM from 'react-dom';

// Code from https://www.gatsbyjs.com/plugins/gatsby-plugin-portal/ (even though we are not using
// gatsby-plugin-portal)

// Use a ternary operator to make sure that the document object is defined
const portalRoot = typeof document !== `undefined` ? document.getElementById('portal') : null;

export default class Portal extends Component {
    el: HTMLDivElement | null;

    constructor(props: Component['props']) {
        super(props);
        // Use a ternary operator to make sure that the document object is defined
        this.el = typeof document !== `undefined` ? document.createElement('div') : null;
    }

    componentDidMount = (): void => {
        if (this.el && portalRoot) {
            portalRoot.appendChild(this.el);
        }
    };

    componentWillUnmount = (): void => {
        if (this.el && portalRoot) {
            portalRoot.removeChild(this.el);
        }
    };

    render(): React.ReactPortal | null {
        const { children } = this.props as { children: React.ReactNode };

        // Check that this.el is not null before using ReactDOM.createPortal
        if (this.el) {
            return ReactDOM.createPortal(children, this.el);
        } else {
            return null;
        }
    }
}
