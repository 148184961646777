import { useQuery } from 'react-query';
import { npBackendApi } from '../npBackend';
import { RemoteData, remoteDataFromQueryResult } from '../remoteData';

type PreorderStockElement = {
    stock: number;
    nextDeliveryStock: number;
    productId?: number;
    deliveryDate?: string;
};

export type PreorderStock = Record<string, PreorderStockElement>;

async function getPreorderStock(): Promise<PreorderStock> {
    let res = await npBackendApi.get<PreorderStock>('/api/v1/preorder-stock');
    return res.data;
}

export function usePreorderStock(): RemoteData<PreorderStock> {
    return remoteDataFromQueryResult(
        useQuery('preorder_stock', getPreorderStock, {
            staleTime: 60000,
            retry: 2,
        })
    );
}

export function getPreorderStockForItem(
    item: string,
    preorderStock: PreorderStock
): PreorderStockElement {
    // item is something like 123-45, and the last part is the ID used in preorder-stock (and is
    // unique)
    let sizeId = item.split('-')[1];

    if (!sizeId) {
        throw new Error('item id is malformed. In usePreorderStockForCentraVariant');
    }

    return preorderStock[sizeId];
}

export function usePreorderStockForCentraVariant(item?: string): RemoteData<{
    stock: number;
    nextDeliveryStock: number;
    deliveryDate?: string;
}> {
    let res = usePreorderStock();
    if (!item) {
        return { isLoading: true, data: undefined, error: undefined };
    }

    if (res.isLoading) {
        return { isLoading: true, data: undefined, error: undefined };
    }
    if (res.error) {
        // If the backend is down, or something else failed, we just allow the user to purchase
        return { isLoading: false, data: { stock: 999, nextDeliveryStock: 999 }, error: undefined };
    }

    let itemStock = getPreorderStockForItem(item, res.data);

    if (!itemStock) {
        // If the object is not in the list it means that we don't have a supplier order for that product, i.e. we don't have a preorder stock
        return { isLoading: false, data: { stock: 0, nextDeliveryStock: 0 }, error: undefined };
    }

    return { isLoading: false, data: itemStock, error: undefined };
}

export function usePreorderStockForProductId(productId: string): RemoteData<{
    stock: number;
    deliveryDate?: string;
}> {
    let res = usePreorderStock();

    if (res.isLoading) {
        return { isLoading: true, data: undefined, error: undefined };
    }
    if (res.error) {
        // If the backend is down, or something else failed, we just allow the user to purchase
        return { isLoading: false, data: undefined, error: res.error };
    }

    let item = Object.values(res.data).find(el => el.productId?.toString() == productId);

    if (!item) {
        // If the object is not in the list it means that we don't have a supplier order for that product, i.e. we don't have a preorder stock
        return { isLoading: false, data: { stock: 0 }, error: undefined };
    }

    return { isLoading: false, data: item, error: undefined };
}
