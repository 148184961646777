import React, { useContext } from 'react';
import { IContext } from './interfaces';

const Context = React.createContext<IContext | null>(null);

export const useNPContext = (): IContext => {
    let context = useContext(Context);
    if (context === null) {
        throw new Error('useNPContext needs to be used in a component wrapped by <Provider />');
    }
    return context;
};

export const NpContextProvider = Context.Provider;
