import React, { useContext, FC, useCallback } from 'react';
import { format } from 'date-fns';
import { nb, enUS } from 'date-fns/locale';
import { Language } from './interfaces';
import translate from './translations';
import { LocaleString, Maybe } from '../graphql-types';

const LanguageContext = React.createContext<Language>('nb');

export const LanguageProvider = LanguageContext.Provider;

export const useLanguage = (): Language => useContext(LanguageContext);

export const I18n: FC<{ children: string; formatArgs?: string[]; allCapital?: boolean }> = ({
    children,
    formatArgs,
    allCapital,
}) => {
    let lang = useLanguage();
    let string = translate(lang, children);

    if (formatArgs) {
        formatArgs.forEach(el => {
            string = string.replace(/{}/, el);
        });
    }

    if (allCapital) {
        string = string.toUpperCase();
    }
    return <>{string}</>;
};

type I18nTools = {
    t: (key: string) => string;
    s: (value?: { en?: string | null; nb?: string | null } | null) => string;
};

export function useI18n(): I18nTools {
    const lang = useLanguage();

    return {
        t: useCallback(key => translate(lang, key), [lang]),
        s: useCallback(
            value => {
                if (!value) {
                    return '';
                }
                if (lang === 'en') {
                    return value.en ?? '';
                } else {
                    return value.nb ?? '';
                }
            },
            [lang]
        ),
    };
}

type Formatter = (date: Date) => string;

export const useDateFnsLocale = () => {
    return useLanguage().toLowerCase() === 'nb' ? nb : enUS;
};

const formatWithFallback = (...args: Parameters<typeof format>) => {
    try {
        return format(...args);
    } catch (err: any) {
        console.error('err');
        return `Invalid date`;
    }
};

const en_MonthFormatter: Formatter = date =>
    formatWithFallback(date, 'MMMM yyyy', { locale: enUS });
const no_MonthFormatter: Formatter = date => formatWithFallback(date, 'MMMM yyyy', { locale: nb });

const en_DateFormatter: Formatter = date =>
    formatWithFallback(date, 'MMMM dd, yyyy', { locale: enUS });
const no_DateFormatter: Formatter = date =>
    formatWithFallback(date, 'dd. MMMM yyyy', { locale: nb });

export const useMonthFormatter = (): Formatter => {
    return useLanguage().toLowerCase() === 'nb' ? no_MonthFormatter : en_MonthFormatter;
};

export const useDateFormatter = (): Formatter => {
    return useLanguage().toLowerCase() === 'nb' ? no_DateFormatter : en_DateFormatter;
};

export const useLocalizedString = (value?: Maybe<LocaleString> | string): string => {
    const lang = useLanguage();
    if (!value) {
        return '';
    }
    if (typeof value === 'string') {
        return value;
    }
    if (lang === 'en') {
        return value.en ?? '';
    } else {
        return value.nb ?? '';
    }
};

export const I18nStr: FC<{ children?: Maybe<LocaleString> | string }> = ({ children }) => {
    const localizedString = useLocalizedString(children);
    return <>{localizedString}</>;
};

export const I18nText: FC<{
    children?: Maybe<LocaleString> | string;
}> = ({ children }) => {
    const localizedString = useLocalizedString(children);

    return (
        <>
            {localizedString.split('\n').map(paragraph => (
                <p key={paragraph}>{paragraph}</p>
            ))}
        </>
    );
};
export const I18nTextBr: FC<{
    children?: Maybe<LocaleString> | string;
}> = ({ children }) => {
    const localizedString = useLocalizedString(children);

    return (
        <>
            {localizedString.split('\n').map(paragraph => (
                <React.Fragment key={paragraph}>
                    {paragraph}
                    <br />
                </React.Fragment>
            ))}
        </>
    );
};
