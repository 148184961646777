import React, { FC } from 'react';

export const ArrowNext: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="14"
            fill="none"
            viewBox="0 0 15 14"
        >
            <path
                fill="#000"
                d="M11.84 6.5L7.196 1.9l.988-1 6.321 6.4-6.42 6.3-.988-1L11.741 8H.184V6.6h11.655v-.1z"
            ></path>
        </svg>
    );
};
