import React from 'react';

export const PaperClipIcon: React.FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
        >
            <path
                stroke="#000"
                strokeWidth="1.5"
                d="M3.5 5.714v5.88a5.5 5.5 0 002.68 4.72l.062.038a5.379 5.379 0 005.516 0v0a5.379 5.379 0 002.617-4.829l-.288-7.3a3.219 3.219 0 00-2.198-2.927v0a3.219 3.219 0 00-1.798-.069l-.178.045A3.846 3.846 0 007 5.003v5.847c0 .379.1.751.291 1.078l.031.053a2.1 2.1 0 003.551.123v0a2.1 2.1 0 00.342-1.484l-.882-6.049"
            ></path>
        </svg>
    );
};
