import React from 'react';

export const Smiley: React.FC = () => {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.2998 15.8C12.4398 15.8 15.7998 12.44 15.7998 8.30005C15.7998 4.16005 12.4398 0.800049 8.2998 0.800049C4.1598 0.800049 0.799805 4.16005 0.799805 8.30005C0.799805 12.44 4.1598 15.8 8.2998 15.8Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.2998 11.2C6.9998 12.7466 9.5998 12.7466 11.2998 11.2"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx="5.5998" cy="6.4001" r="0.8" fill="black" />
            <circle cx="11.2004" cy="6.4001" r="0.8" fill="black" />
        </svg>
    );
};
