import { Link } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';
import { calculateOrderPrices, getDiscount, getShipping } from '../subtotal';
import constants from '../../../constants';
import { ICentraCheckoutSelection, IContext } from '../../../interfaces';
import { weight, fontSize, zIndex, space, buttonReset, linkReset } from '../../styles';
import CartItems from './cartItems';
import { useShoppingCart } from './ShoppingCartContext';
import { I18n } from '../../../i18n';
import { useLockBodyScroll } from '../../../lib/hooks';
import { LabeledProduct, useLabeledProducts } from './hooks';

const SidebarDiv = styled.div`
    box-sizing: border-box;

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    display: flex;
    background: white;
    margin: 0;
    white-space: nowrap;
    position: fixed;
    z-index: ${zIndex.cart};
    top: 0;
    bottom: 0;
    transition: right 0.4s;
    width: 500px;
    right: -500px;
    @media (max-width: ${constants.breakpoints.miniTablet}) {
        right: -100vw;
        width: 100%;
    }

    flex-direction: column;
    &.isOpen {
        right: 0;
    }
`;

const Black = styled.div`
    &.isOpen {
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: ${zIndex.cartOverlay};
        background-color: #00000036;
        position: fixed;
    }
`;

const LogoDiv = styled.div`
    margin-left: 20px;
`;

const ShoppingCart: React.FC<{
    isOpen: boolean;
    language: string;
    context: IContext;
}> = ({ isOpen, language, context }) => {
    useLockBodyScroll(isOpen);
    const toggleShoppingCart = useShoppingCart().toggleOpen;
    const selection: ICentraCheckoutSelection | undefined = context?.state?.centra?.selection;
    const labeledProducts = useLabeledProducts(selection?.items);

    const noSelection = !selection || !selection.items || selection.items.length == 0;

    return (
        <>
            <SidebarDiv className={isOpen ? 'isOpen' : 'isClosed'}>
                <div
                    css={`
                        overflow-y: auto;
                        height: calc(100%);
                    `}
                >
                    <TopBar />

                    {selection?.totals.shippingPriceAsNumber ? <ShippingBanner /> : null}

                    <h2
                        css={`
                            padding-left: ${space(3)};
                            ${fontSize.headinglarge};
                            ${weight.regular};
                            margin: 0 0 ${space(2)};
                        `}
                    >
                        {noSelection ? <I18n>cartIsEmpty</I18n> : <I18n>bag</I18n>}
                    </h2>

                    {noSelection && (
                        <div
                            css={`
                                padding: 0 ${space(3)};
                                white-space: normal;
                                color: #737373;
                                max-width: 400px;
                            `}
                        >
                            <I18n>emptyCart</I18n>
                        </div>
                    )}

                    <CartItems {...{ labeledProducts }} />
                </div>
                {!noSelection ? (
                    <div
                        css={`
                            padding: ${space(2)};
                            width: 100%;
                            align-self: flex-end;

                            border-top: 2px solid #f2f2f2;
                        `}
                    >
                        <Subtotal selection={selection} labeledProducts={labeledProducts} />
                        <Link
                            to={`/${language}/checkout`}
                            css={`
                                ${fontSize.regular};
                                ${weight.medium};
                                display: block;
                                text-align: center;
                                ${linkReset};
                                background: #2b2929;
                                border-radius: 5px;
                                width: 100%;
                                color: white;
                                margin-top: ${space(2)};
                                line-height: 48px;
                            `}
                        >
                            <I18n>toCheckout</I18n>
                        </Link>
                    </div>
                ) : (
                    <Link
                        to={`/${language}/shop`}
                        css={`
                            margin: ${space(2)};
                            ${fontSize.regular};
                            ${weight.medium};
                            display: block;
                            text-align: center;
                            ${linkReset};
                            background: #2b2929;
                            border-radius: 5px;
                            width: calc(100% - ${space(4)});
                            color: white;
                            margin-top: ${space(2)};
                            line-height: 48px;
                        `}
                    >
                        <I18n>allOurProducts</I18n>
                    </Link>
                )}
            </SidebarDiv>
            <Black
                className={isOpen ? 'isOpen' : 'isClosed'}
                onClick={() => toggleShoppingCart()}
            />
        </>
    );
};

const ShippingBanner: FC = () => {
    return (
        <div
            css={`
                background: #ffe5ec;
                color: ${constants.colors.pallet.error};
                line-height: 48px;
                padding: 0 ${space(2)};
                margin: ${space(2)} 0;
            `}
        >
            <I18n>freeShippingCartBanner</I18n>
        </div>
    );
};

const Subtotal: React.FC<{
    labeledProducts: LabeledProduct[];
    selection: ICentraCheckoutSelection | undefined;
}> = ({ selection, labeledProducts }) => {
    const prices = calculateOrderPrices(labeledProducts, selection?.totals);
    if (!prices || !selection) {
        return <div />;
    }
    const { crowdfundingProducts, payNow, total } = prices;
    const currency = selection?.currency || '';
    return (
        <div>
            {selection && selection.discounts.anyDiscount ? (
                <PriceDiv>
                    <span>
                        <I18n>discount</I18n>
                    </span>
                    <span>{selection && getDiscount(selection)}</span>
                </PriceDiv>
            ) : null}

            {selection && selection.totals.shippingPriceAsNumber ? (
                <PriceDiv>
                    <span>
                        <I18n>shipping</I18n>
                    </span>
                    <span>{getShipping(selection)}</span>
                </PriceDiv>
            ) : null}
            <PriceDiv>
                <span>
                    <span
                        css={`
                            ${crowdfundingProducts === 0 ? `color: black; ${weight.medium}` : ''}
                        `}
                    >
                        <I18n>total</I18n>
                    </span>{' '}
                    {!selection.totals.taxDeducted && (
                        <span>
                            <I18n>incMva</I18n>
                        </span>
                    )}
                </span>
                <span>
                    {total} {currency}
                </span>
            </PriceDiv>
            {crowdfundingProducts > 0 && (
                <PriceDiv
                    css={`
                        color: black;
                        ${weight.medium};
                    `}
                >
                    <span>
                        <I18n>chargedNow</I18n>
                    </span>
                    <span>
                        {payNow} {currency}
                    </span>
                </PriceDiv>
            )}
        </div>
    );
};

const PriceDiv = styled.div`
    display: flex;
    justify-content: space-between;
    color: grey;
`;

const TopBar: FC = () => {
    const toggleShoppingCart = useShoppingCart().toggleOpen;
    return (
        <div
            css={`
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                margin-top: ${space(2)};

                @media (min-width: ${constants.breakpoints.miniTablet}) {
                    justify-content: flex-end;
                }
            `}
        >
            <LogoDiv
                css={`
                    @media (min-width: ${constants.breakpoints.miniTablet}) {
                        display: none;
                    }
                `}
            >
                <img src="/img/rock.png" style={{ width: '32px' }} />
            </LogoDiv>
            <button
                onClick={toggleShoppingCart}
                css={`
                    ${buttonReset};
                    display: block;
                    line-height: ${space(4)};
                    background: #f2f2f2;
                    padding: 0 ${space(1)};
                    border-radius: 5px;
                    margin-right: ${space(3)};
                `}
            >
                <I18n>close</I18n>
            </button>
        </div>
    );
};

export default ShoppingCart;
