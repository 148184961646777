import { useStaticQuery, graphql } from 'gatsby';
import { CartHooksQuery } from '../../../../graphql-types';
import { CentraSelectionItem } from '../../../interfaces';
import { defaultDict } from '../../../lib/utils';
import { useVariantsForCentraItem } from '../../../api/centra';
import { usePreorderStock } from '../../../api/np-backend/preorderStock';

const useSanityQuery = () =>
    useStaticQuery<CartHooksQuery>(graphql`
        query CartHooks {
            crowdfunding: allProduct(filter: { productState: { eq: "crowdFunding" } }) {
                nodes {
                    centraId
                    productState
                    crowdfundingDate
                    crowdfundingGoal
                }
            }
        }
    `);

const useSanityCrowdfundingData = () => {
    const sanityCrowdfundingData: { [centraId: string]: SanityCrowdfundingData } = {};
    useSanityQuery().crowdfunding.nodes.forEach(
        entry =>
            (sanityCrowdfundingData[entry.centraId] = {
                date: entry.crowdfundingDate,
                goal: entry.crowdfundingGoal,
            })
    );

    return sanityCrowdfundingData;
};

export const useLabeledProducts = (items?: CentraSelectionItem[] | null): LabeledProduct[] => {
    const sanityCrowdfundingData = useSanityCrowdfundingData();
    const { data: variantsForCentraItem, error } = useVariantsForCentraItem();
    const preorderStock = usePreorderStock();
    if (error) {
        throw error;
    }

    if (preorderStock.isLoading) {
        return [];
    }

    if (!variantsForCentraItem) {
        return [];
    }

    if (!items) {
        return [];
    }

    const categorized: LabeledProduct[] = [];
    items.forEach(item => {
        const productId = item.product.centraProduct;
        let sizeId = item.item.split('-')[1];
        const itemPreorderStock = preorderStock.data?.[sizeId];

        const isCrowdfunding = productId in sanityCrowdfundingData;
        const isPreorder = variantsForCentraItem[item.item]?.stock === 'infinite';

        const crowdfundingGoal = sanityCrowdfundingData[productId]?.goal ?? null;

        const groupDate = isCrowdfunding
            ? sanityCrowdfundingData[productId].date
            : isPreorder && itemPreorderStock?.deliveryDate
            ? new Date(itemPreorderStock.deliveryDate)
            : null;

        const productType = isCrowdfunding ? 'crowdfunding' : isPreorder ? 'preorder' : 'normal';

        categorized.push({
            productType,
            date: groupDate ? new Date(groupDate) : null,
            crowdfundingGoal,
            product: item,
        });
    });

    return categorized;
};

export const getOrderedPreorderProducts = (labeledProducts: LabeledProduct[]): PreorderGroup[] => {
    const preorderProducts = labeledProducts.filter(product => product.productType === 'preorder');
    const preorderMapping = defaultDict<LabeledProduct[]>(Array);
    preorderProducts.forEach(product => preorderMapping[product.date!.getTime()!].push(product));

    const preorderGroups: PreorderGroup[] = [];
    Object.keys(preorderMapping).forEach(date => {
        preorderGroups.push({
            date: new Date(+date),
            products: preorderMapping[date],
        });
    });

    preorderGroups.sort(
        (order1: PreorderGroup, order2: PreorderGroup) =>
            order1.date.getTime() - order2.date.getTime()
    );

    return preorderGroups;
};

export const getOrderedCrowdfundingProducts = (
    labeledProducts: LabeledProduct[]
): LabeledProduct[] => {
    const crowdfundingProducts = labeledProducts.filter(
        product => product.productType === 'crowdfunding'
    );
    crowdfundingProducts.sort((product1: LabeledProduct, product2: LabeledProduct) => {
        // Missing dates as last products
        const time1 = product1.date?.getTime() ?? Infinity;
        const time2 = product2.date?.getTime() ?? Infinity;
        return time1 - time2;
    });

    return crowdfundingProducts;
};

export const getNormalAndPreorderProducts = (
    labeledProducts: LabeledProduct[]
): LabeledProduct[] => {
    return labeledProducts.filter(
        product => product.productType === 'normal' || product.productType === 'preorder'
    );
};

export const getNormalProducts = (labeledProducts: LabeledProduct[]): LabeledProduct[] => {
    return labeledProducts.filter(product => product.productType === 'normal');
};

type PreorderGroup = {
    date: Date;
    products: LabeledProduct[];
};

export type LabeledProduct = {
    productType: 'normal' | 'crowdfunding' | 'preorder';
    date: Date | null;
    crowdfundingGoal: number | null;
    product: CentraSelectionItem;
};

type SanityCrowdfundingData = {
    date?: string | null;
    goal?: number | null;
};

type ProductVariantItem = {
    item: string;
    stock: string | number;
};
