import axios, { AxiosInstance } from 'axios';
import { useEffect, useReducer, Reducer, useCallback } from 'react';
import { RemoteData } from './remoteData';

// https://www.robinwieruch.de/react-hooks-fetch-data/

// T is the type of a normal (successful) response
export const createUseDataApi = (axiosInstance: AxiosInstance) => <T extends object>(
    url: string | void
): [RemoteData<T>, () => void] => {
    const reducer: Reducer<RemoteData<T>, Action<T>> = dataFetchReducer;

    const [state, dispatch] = useReducer(reducer, {
        isLoading: true,
        error: undefined,
        data: undefined,
    });

    useEffect(() => {
        dispatch({ type: 'FETCH_INIT' });
    }, []);

    const fetchData = useCallback(() => {
        let didCancel = false;

        const fetchDataInner = async () => {
            try {
                // In case the url is undefined, we don't do any fetch.
                // As the fetch is automatically initiated, this makes it possible to delay
                // the fetch until the url/options object is known.
                const result = url ? await axiosInstance({ url }) : { data: null };

                if (!didCancel) {
                    dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
                }
            } catch (error: any) {
                if (!didCancel) {
                    console.error(error);
                    dispatch({ type: 'FETCH_FAILURE', error });
                }
            }
        };

        fetchDataInner().catch(err => {
            console.error(err);
        });

        return () => {
            didCancel = true;
        };
    }, [url, dispatch]);

    useEffect(() => {
        dispatch({ type: 'FETCH_INIT' });
        return fetchData();
    }, [fetchData]);

    return [state, fetchData];
};

export const useDataApi = createUseDataApi(axios);

export type Action<T> =
    | {
          type: 'FETCH_INIT';
      }
    | {
          type: 'FETCH_SUCCESS';
          payload: T;
      }
    | {
          type: 'FETCH_FAILURE';
          error: Error;
      };

const dataFetchReducer = <T,>(state: RemoteData<T>, action: Action<T>): RemoteData<T> => {
    switch (action.type) {
        case 'FETCH_INIT':
            return {
                isLoading: true,
                error: undefined,
                data: undefined,
            };
        case 'FETCH_SUCCESS':
            return {
                isLoading: false,
                error: undefined,
                data: action.payload,
            };
        case 'FETCH_FAILURE':
            return {
                isLoading: false,
                error: action.error,
                data: undefined,
            };
        default:
            throw new Error();
    }
};
