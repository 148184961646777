import { SanityImageSource } from '@sanity/image-url/lib/types/types';

export type Image =
    | { __typename: 'SanityEmbed' }
    | {
          __typename: 'SanityProductImage';
          image?: SanityImageSource;
          gender?: string | null;
      }
    | null
    | undefined;

export function findFirstImage(images: Image[] | null | undefined): SanityImageSource | null {
    if (!images) {
        return null;
    }

    for (const image of images) {
        if (image?.__typename === 'SanityProductImage' && image.image) {
            return image.image;
        }
    }

    return null;
}
