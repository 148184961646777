import { navigate } from 'gatsby';
import React, { FC } from 'react';
import { globalHistory } from '@reach/router';
import { useLanguage } from '../i18n';
import { BackArrowIcon } from '../svg/BackArrowIcon';
import { buttonReset, space } from './styles';

// This back-buttons works as a back-button as long as we have a
// "local" history, e.g. that back goes to a northern playground
// page.
//
// If we don't have a local history, back goes to the shop-page

export const BackOrShopPageButton: FC = () => {
    const lang = useLanguage();

    function onClick() {
        if (hasNavigated > 0) {
            // window.history.back will call the below listener with POP
            window.history.back();
        } else {
            // After having navigated to shop-page, we still
            // want this to be 0, so that the next page back
            // click don't go back to this page
            hasNavigated = -1;
            void navigate(`/${lang}/shop`);
        }
    }

    return (
        <button
            onClick={onClick}
            css={`
                ${buttonReset};
                background: #f2f2f2;
                border-radius: 3px;
                width: 32px;
                height: 32px;
                margin-right: ${space(1)};
            `}
        >
            <BackArrowIcon />
        </button>
    );
};

let hasNavigated = 0;

globalHistory.listen(({ action }) => {
    if (action === 'PUSH') {
        hasNavigated += 1;
    } else if (action === 'POP') {
        hasNavigated -= 1;
    }
});
