import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Cart } from '../../../svg/Cart';
import { CartFilledWithNumber } from '../../../svg/CartFilledWithNumber';
import { useShoppingCart } from '../cart/ShoppingCartContext';
import { useBasketCount } from './useBasketCount';

const ShoppingCart = styled.div`
    display: flex;
    align-items: center;

    color: #000;
`;

const ShoppingCartButton = styled(ShoppingCart)<{ productCount: number }>`
    cursor: pointer;
    transition: transform 200ms ease-in-out;
    padding: 2px 1px 5px 4px;

    ${props =>
        props.productCount &&
        `  padding: 8px 1px 5px 4px;
    `}

    border-radius: 3px;
    &: hover {
        ${props =>
            !props.productCount &&
            `
        color: #737373;
    `}
    }
`;

const MenuCart: React.FC<{ onClick?: () => void; className?: string }> = ({
    onClick,
    className,
}) => {
    const shoppingCart = useShoppingCart();
    const productCount = useBasketCount();
    const [isClient, setIsClient] = useState<number>(0);

    useEffect(() => {
        setIsClient(1);
    }, []);

    return (
        <ShoppingCartButton
            key={`${isClient}-${productCount}`}
            productCount={productCount}
            className={className}
            onClick={() => {
                if (onClick) {
                    onClick();
                }
                shoppingCart.toggleOpen();
            }}
            data-testid="menu-shopping-cart"
        >
            {productCount === 0 ? <Cart /> : <CartFilledWithNumber number={productCount} />}
        </ShoppingCartButton>
    );
};

export default MenuCart;
