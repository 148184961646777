import {
    SanityCategoryPage,
    SanityClothingType,
    SanityContentPage,
    SanityLandingPage,
    SanityPage,
    SanitySlug,
} from '../../graphql-types';
import { Language } from '../interfaces';

type Props = {
    page?:
        | SanityContentPage
        | SanityLandingPage
        | SanityPage
        | SanityCategoryPage
        | SanityClothingType;
    language?: Language;
};

export const getCategoryPath = (slug?: SanitySlug | null) => {
    return slug?.current ? `/${slug.current}` : '';
};

export const getPathFromInternalPage = ({ page, language }: Props) => {
    if (!page || !page._type) {
        return null;
    }

    const _type = page._type;

    // note:should populate with all types
    switch (_type) {
        case 'page': {
            const { language, slug, categoryPage } = page as SanityPage;
            const lang = language;

            const slugCurrent = slug?.current;

            if (!slugCurrent) {
                return null;
            }

            const categoryPath = getCategoryPath(categoryPage?.slug);

            switch (lang) {
                case 'en':
                    return `/${lang}${categoryPath}/${slugCurrent}`;
                case 'nb':
                    return `/${lang}${categoryPath}/${slugCurrent}`;
                default:
                    return null;
            }
        }
        case 'categoryPage': {
            const { language, slug } = page as SanityCategoryPage;
            const lang = language;
            const slugCurrent = slug?.current;
            if (!slugCurrent) {
                return null;
            }
            return `/${lang}/${slugCurrent}`;
        }

        case 'landingPage': {
            const { language, path } = page as SanityLandingPage;
            const lang = language;
            if (!path) {
                return null;
            }
            switch (lang) {
                case 'en':
                    return `/${lang}/${path}`;
                case 'nb':
                    return `/${lang}/${path}`;
                default:
                    return null;
            }
        }
        case 'contentPage': {
            const { slug, categoryPage } = page as SanityContentPage;

            const lang = language === 'en' ? 'en' : 'nb';

            const slugByLanguage = slug?.[lang]?.current;

            const categoryPath = getCategoryPath(categoryPage?.[lang]?.slug);

            if (!slugByLanguage) {
                return null;
            }

            switch (lang) {
                case 'en':
                    return `/${lang}${categoryPath}/${slugByLanguage}`;
                case 'nb':
                    return `/${lang}${categoryPath}/${slugByLanguage}`;
                default:
                    return null;
            }
        }
        case 'clothingType': {
            const { slug } = page as SanityClothingType;
            const lang = language === 'en' ? 'en' : 'nb';

            const slugByLanguage = slug?.[lang]?.current;

            if (!slugByLanguage) {
                return null;
            }

            switch (lang) {
                case 'en':
                    return `/${lang}/shop/${slugByLanguage}`;
                case 'nb':
                    return `/${lang}/shop/${slugByLanguage}`;
                default:
                    return null;
            }
        }
        case 'shopPage': {
            const lang = language === 'en' ? 'en' : 'nb';
            return `/${lang}/shop`;
        }

        default:
            return null;
    }
};
