import { Link } from 'gatsby';
import React, { FC } from 'react';
import { weight, space, linkReset } from './styles';
import { I18n, useLanguage } from '../i18n';
import { ProductCard, ProductCardData, ProductCardProduct } from './ProductCard';

const LinkOrDiv: FC<{ className?: string; link?: string; children: React.ReactNode }> = ({
    className,
    children,
    link,
}) => {
    let language = useLanguage();

    return link ? (
        <Link
            className={className}
            css={`
                ${linkReset}
            `}
            to={`/${language}/${link}`}
        >
            {children}
        </Link>
    ) : (
        <div className={className}>{children}</div>
    );
};

const ProductSelectionHeader: React.FC<{
    language: string;
    link?: string;
    title: string;
}> = ({ link, title }) => {
    return (
        <LinkOrDiv
            link={link}
            css={`
                display: flex;
                justify-content: space-between;
                color: #2b2929;
                padding: ${space(3)} 0;
            `}
        >
            <div
                css={`
                    ${weight.medium}
                `}
            >
                {title}
            </div>
            {link && (
                <div>
                    <I18n>seeAll</I18n>
                </div>
            )}
        </LinkOrDiv>
    );
};

const ProductSelection: React.FC<{
    products?: ProductCardProduct[];
    productCards?: ProductCardData[];
    className?: string;
    imgSizes: string;
}> = ({ products, productCards, className, imgSizes }) => {
    if (!products && !productCards) {
        return null;
    }

    return (
        <div
            css={`
                box-sizing: border-box;
                *,
                *:before,
                *:after {
                    box-sizing: inherit;
                }
            `}
            className={className}
        >
            <div
                css={`
                    overflow-x: auto;
                `}
            >
                <div
                    css={`
                        display: flex;

                        /* Calculated to make the amount visible perfect */
                        min-width: calc(257vw - 124px);

                        @media (min-width: 500px) {
                            min-width: 1000px;
                        }

                        & > * {
                            margin-right: ${space(2)};
                            @media (min-width: 1000px) {
                                margin-right: ${space(4)};
                            }
                            @media (min-width: 1300px) {
                                margin-right: ${space(8)};
                            }

                            &:last-of-type {
                                margin-right: 24px;
                            }
                            &:first-of-type {
                                margin-left: 24px;
                            }

                            flex-grow: 1;
                            flex-shrink: 0;
                            flex-basis: 1px;
                        }
                    `}
                >
                    {/* We are transition from using products to productCards. Since we have older content we need to support both */}
                    {productCards
                        ? productCards.map((productCard, idx) => (
                              <ProductCard
                                  key={idx}
                                  productCard={productCard}
                                  imgSizes={imgSizes}
                              />
                          ))
                        : products
                        ? products.map((product, idx) => (
                              <ProductCard
                                  key={idx}
                                  productCard={{ product }}
                                  imgSizes={imgSizes}
                              />
                          ))
                        : null}
                </div>
            </div>
        </div>
    );
};

export default ProductSelection;
