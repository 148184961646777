import React, { FC, useState } from 'react';
import { VisuallyHidden } from '@reach/visually-hidden';
import { Hamburger } from '../../svg/Hamburger';
import Portal from '../Portal';
import { buttonReset, font, linkReset, space, zIndex } from '../styles';
import { I18n, I18nStr, useLanguage } from '../../i18n';
import { CloseHamburger } from '../../svg/CloseHamburger';
import MenuCart from '../shared/navigation/menuCart';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { HamburgerDialogQuery } from '../../../graphql-types';
import { useLockBodyScroll } from '../../lib/hooks';
import { Language } from '../../interfaces';
import constants from '../../constants';
import { css } from 'styled-components';
import { useLoginState } from '../auth/LoginContext';
import { getCategoryPath } from '../../helpers/get-path';

export const HamburgerMenu: FC = () => {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <>
            <button
                onClick={() => setShowMenu(true)}
                css={`
                    ${buttonReset}
                    transition: transform 200ms ease-in-out;
                    border-radius: 3px;
                    padding: 5px 10px 5px 14px;
                    color: #000;

                    &:hover {
                        transition: transform 200ms ease-in-out;
                        color: #737373;
                    }
                `}
            >
                <Hamburger />
                <VisuallyHidden>
                    <I18n>menu</I18n>
                </VisuallyHidden>
            </button>
            {showMenu && <HamburgerDialog close={() => setShowMenu(false)} />}
        </>
    );
};

const linkCommonStyle = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Line under the active box */
    &:after {
        content: '';
        display: block;
        height: 2px;
        width: 16px;
        background-color: transparent;
    }

    &:hover {
        /* Line under the hover box */
        &:after {
            background-color: ${constants.colors.pallet.black};
        }
    }
`;

const HamburgerDialog: FC<{ close: () => void }> = ({ close }) => {
    const lang = useLanguage();
    useLockBodyScroll();
    const bigLinks = [
        { title: 'products', to: `/${lang}/shop` },
        { title: 'environment', to: `/${lang}/environment` },
    ];

    const data = useStaticQuery<HamburgerDialogQuery>(graphql`
        fragment categoryPage on SanityCategoryPage {
            _id
            _type
            slug {
                current
            }
            title
            language
        }

        query HamburgerDialog {
            sanityMenu(_id: { eq: "main-menu" }) {
                menuItem {
                    _key
                    linkNb {
                        __typename
                        ... on SanityContentPage {
                            id
                            slug {
                                en {
                                    current
                                }
                                nb {
                                    current
                                }
                            }
                            categoryPage {
                                en {
                                    ...categoryPage
                                }
                                nb {
                                    ...categoryPage
                                }
                            }
                        }
                        ... on SanityLandingPage {
                            id
                            path
                        }
                    }
                    linkEn {
                        __typename
                        ... on SanityContentPage {
                            id
                            slug {
                                en {
                                    current
                                }
                                nb {
                                    current
                                }
                            }
                            categoryPage {
                                en {
                                    ...categoryPage
                                }
                                nb {
                                    ...categoryPage
                                }
                            }
                        }
                        ... on SanityLandingPage {
                            id
                            path
                        }
                    }
                    title {
                        en
                        nb
                    }
                    showTo
                }
            }
        }
    `);

    const menuItems = data.sanityMenu?.menuItem || [];
    const { isLoggedIn } = useLoginState();

    return (
        <Portal>
            <div
                css={`
                    position: fixed;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background: #f2f2f2;
                    z-index: ${zIndex.modal};
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 300;
                `}
            >
                <div
                    css={`
                        position: absolute;
                        right: 31px;
                        top: 14px;
                        display: flex;
                        align-items: center;
                    `}
                >
                    <button
                        onClick={close}
                        css={`
                            ${buttonReset};
                            margin: 0;
                            display: block;
                            height: ${space(2)};
                            margin-right: ${space(2)};
                        `}
                    >
                        <CloseHamburger />
                        <VisuallyHidden>
                            <I18n>close</I18n>
                        </VisuallyHidden>
                    </button>
                    <MenuCart onClick={close} />
                </div>
                <div
                    css={`
                        ${font.serif};
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 70%;
                        max-height: ${menuItems.length * (72 + 36) + 2 * (92 + 36) + 17}px;

                        hr {
                            width: 100%;
                            margin: 8px 0;
                        }
                    `}
                >
                    {menuItems
                        .filter(item => {
                            if (isLoggedIn && item?.showTo == 'logged_out') {
                                return false;
                            }
                            if (!isLoggedIn && item?.showTo == 'logged_in') {
                                return false;
                            }
                            return true;
                        })
                        .map(item => (
                            <Link
                                to={getLink(item, lang)}
                                key={item?._key}
                                onClick={close}
                                css={`
                                    ${linkReset};
                                    font-size: 24px;
                                    line-height: 48px;
                                    @media (min-height: 750px) {
                                        font-size: 40px;

                                        line-height: 72px;
                                    }
                                    ${linkCommonStyle};
                                `}
                            >
                                <I18nStr>{item?.title}</I18nStr>
                            </Link>
                        ))}
                    <hr />
                    {bigLinks.map(item => (
                        <Link
                            onClick={close}
                            to={item.to}
                            key={item.title}
                            css={`
                                ${linkReset};
                                font-size: 40px;
                                line-height: 60px;
                                padding: 0 ${space(5)};
                                @media (min-height: 750px) {
                                    padding: 0 ${space(9)};
                                    font-size: 72px;
                                    line-height: 92px;
                                }
                                ${linkCommonStyle};
                            `}
                        >
                            <I18n>{item.title}</I18n>
                        </Link>
                    ))}
                </div>
            </div>
        </Portal>
    );
};

function getLink(item: MenuItem, lang: Language): string {
    if (!item) {
        return '';
    }

    let linkItem = lang === 'en' && item.linkEn ? item.linkEn : item.linkNb;

    if (!linkItem) {
        return '';
    }

    const categoryPage = 'categoryPage' in linkItem ? linkItem.categoryPage?.[lang] : null;

    const categoryPath = getCategoryPath(categoryPage?.slug);

    if (linkItem.__typename === 'SanityContentPage') {
        return `/${lang}${categoryPath}/${linkItem.slug?.[lang]?.current}`;
    }
    if (linkItem.__typename === 'SanityLandingPage') {
        return `/${lang}/${linkItem.path}`;
    }
    return '';
}

type MenuItem = NonNullable<NonNullable<HamburgerDialogQuery['sanityMenu']>['menuItem']>[0];
